// src/components/Shop.js

import React, { useState, useEffect } from 'react';
import TopNavBar from './TopNavBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Drawer,
  Container,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { useTheme, alpha } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import FilterListIcon from '@mui/icons-material/FilterList';
import axios from 'axios';
import FilterPanel from './FilterPanel';
import ProductCard from './ProductCard';
import AddToCartButton from './AddToCartButton';

function Shop() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Extract query parameters or set defaults
  const initialSearchTerm = searchParams.get('query') || '';
  const initialCategory = parseInt(searchParams.get('category'), 10) || 0;
  const initialSubcategory = parseInt(searchParams.get('subcategory'), 10) || 0;
  const initialVendor = searchParams.get('vendor')
    ? searchParams.get('vendor').split(',').map(Number)
    : [];
  const initialSort = searchParams.get('sort') || 'default';

  // Product States
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [loadingVendorsProducts, setLoadingVendorsProducts] = useState(false);

  // Filter States
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(
    initialSubcategory
  );
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(initialVendor);
  const [sortOption, setSortOption] = useState(initialSort);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'list'

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State for Drawer
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // State for Multiplier
  const [multiplier, setMultiplier] = useState(1);

  // State for Restaurant Vendors
  const [restaurantVendors, setRestaurantVendors] = useState([]);

  // State for Combined Products
  const [allProducts, setAllProducts] = useState([]);

  // New state for Display Products
  const [displayProducts, setDisplayProducts] = useState([]);

  // State for Unique Brands
  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 25;

  // Fetch the multiplier from localStorage when the component mounts
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
        setMultiplier(storedMultiplier);
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        setMultiplier(1);
      }
    } else {
      setMultiplier(1);
    }
  }, []);

  // Fetch Restaurant Vendors
  useEffect(() => {
    const fetchRestaurantVendors = async () => {
      try {
        const userJson = localStorage.getItem('user');
        if (!userJson) {
          throw new Error('User data not found in local storage.');
        }

        const user = JSON.parse(userJson);
        const restaurantId = user.restaurant_id;
        if (!restaurantId) {
          throw new Error('Restaurant ID not found in user data.');
        }

        const response = await axios.get(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors`,
          {
            params: { restaurant_id: restaurantId },
            headers: { 'Content-Type': 'application/json' },
          }
        );

        if (response.status !== 200) {
          throw new Error('Failed to fetch restaurant vendors');
        }
        const data = response.data;
        setRestaurantVendors(data);
      } catch (error) {
        console.error('Error fetching restaurant vendors:', error);
        setRestaurantVendors([]);
      }
    };

    fetchRestaurantVendors();
  }, []);

  // Function to toggle Drawer
  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  // Fetch Categories
  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/category',
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setCategories([]);
    }
  };

  // Fetch Subcategories based on selectedCategory
  const fetchSubcategories = async (categoryId) => {
    if (categoryId === 0) {
      setSubcategories([]);
      setSelectedSubcategory(0);
      return;
    }
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/subcategory',
        {
          headers: { 'Content-Type': 'application/json' },
          params: { filter: categoryId },
        }
      );
      setSubcategories(response.data);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
      setSubcategories([]);
    }
  };

  // Fetch Vendors
  const fetchVendors = async () => {
    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/vendor',
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      setVendors(response.data);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setVendors([]);
    }
  };

  // Fetch Products for Vendors and combine into a single list
  const fetchProductsForVendors = async () => {
    setLoadingVendorsProducts(true);
    try {
      let vendorsToFetch = [];

      if (selectedVendor.length > 0) {
        vendorsToFetch = restaurantVendors.filter((item) =>
          selectedVendor.includes(item.vendor_id)
        );
      } else {
        vendorsToFetch = restaurantVendors;
      }

      const allFetchedProducts = [];

      const productsPromises = vendorsToFetch.map(async (vendorItem) => {
        const vendorId = vendorItem.vendor_id;

        const params = {
          limit: 25, // Fetching 25 per vendor to avoid excessive data
          offset: 0,
          filter: 0,
          categoryidfilter: selectedCategory,
          subcategoryidfilter: selectedSubcategory,
          productnamefilter: searchTerm,
          vendoridfilter: vendorId,
          productnumberfilter: 0,
        };

        const authToken = localStorage.getItem('authToken');
        const headers = { Authorization: `Bearer ${authToken}` };

        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/product',
          { params, headers }
        );

        const fetchedProducts = Array.isArray(response.data.items)
          ? response.data.items
          : [];

        // Add vendor info to products
        fetchedProducts.forEach((product) => {
          product.vendor = vendorItem._vendor; // Assuming vendorItem._vendor has vendor details
        });

        allFetchedProducts.push(...fetchedProducts);
      });

      await Promise.all(productsPromises);

      // Limit to maximum 100 products to prevent excessive data
      const limitedProducts = allFetchedProducts.slice(0, 100);

      setAllProducts(limitedProducts);
      setCurrentPage(1); // Reset to first page on new fetch
    } catch (error) {
      console.error('Error fetching products for vendors:', error);
      setAllProducts([]);
    } finally {
      setLoadingVendorsProducts(false);
    }
  };

  // Extract Unique Brands
  useEffect(() => {
    const brandsSet = new Set();
    allProducts.forEach((product) => {
      if (product.brand) {
        brandsSet.add(product.brand);
      }
    });
    setUniqueBrands(Array.from(brandsSet));
  }, [allProducts]);

  // Sort Products Function
  const sortProducts = (products, sortOption, multiplier) => {
    const sorted = [...products];

    switch (sortOption) {
      case 'price_low_high':
        sorted.sort(
          (a, b) => a.purchase_price * multiplier - b.purchase_price * multiplier
        );
        break;
      case 'price_high_low':
        sorted.sort(
          (a, b) => b.purchase_price * multiplier - a.purchase_price * multiplier
        );
        break;
      case 'ppu_low_high':
        sorted.sort((a, b) => {
          const priceA =
            a.purchase_price && a.product_volume > 0
              ? (a.purchase_price * multiplier) / a.product_volume
              : Infinity;
          const priceB =
            b.purchase_price && b.product_volume > 0
              ? (b.purchase_price * multiplier) / b.product_volume
              : Infinity;
          return priceA - priceB;
        });
        break;
      case 'ppu_high_low':
        sorted.sort((a, b) => {
          const priceA =
            a.purchase_price && a.product_volume > 0
              ? (a.purchase_price * multiplier) / a.product_volume
              : 0;
          const priceB =
            b.purchase_price && b.product_volume > 0
              ? (b.purchase_price * multiplier) / b.product_volume
              : 0;
          return priceB - priceA;
        });
        break;
      default:
        // Default sorting by product_id or id
        sorted.sort((a, b) => a.id - b.id);
        break;
    }

    return sorted;
  };

  // Initial Fetches
  useEffect(() => {
    fetchCategories();
    fetchVendors();
    fetchSubcategories(initialCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplier]);

  // Fetch subcategories whenever selectedCategory changes
  useEffect(() => {
    fetchSubcategories(selectedCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  // Update URL when filters change
  useEffect(() => {
    const params = {};

    if (searchTerm) params.query = searchTerm;
    if (selectedCategory !== 0) params.category = selectedCategory;
    if (selectedSubcategory !== 0) params.subcategory = selectedSubcategory;
    if (selectedVendor.length > 0) params.vendor = selectedVendor.join(',');
    if (sortOption !== 'default') params.sort = sortOption;

    setSearchParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategory,
    selectedSubcategory,
    selectedVendor,
    sortOption,
    searchTerm,
  ]);

  // Clear All Filters
  const clearAllFilters = () => {
    setSelectedCategory(0);
    setSelectedSubcategory(0);
    setSelectedVendor([]);
    setSelectedBrands([]);
    setSortOption('default');

    // Keep the searchTerm in the URL parameters
    const params = {};
    if (searchTerm) params.query = searchTerm;
    setSearchParams(params);
  };

  // Fetch Products whenever filters or searchTerm change
  useEffect(() => {
    if (restaurantVendors.length > 0) {
      fetchProductsForVendors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCategory,
    selectedSubcategory,
    searchTerm,
    selectedVendor,
    restaurantVendors,
  ]);

  // Update Display Products whenever allProducts, sortOption, or multiplier changes
  useEffect(() => {
    if (allProducts.length > 0) {
      const sortedProducts = sortProducts(allProducts, sortOption, multiplier);
      setDisplayProducts(sortedProducts.slice(0, 100)); // Ensure maximum 100 products
    } else {
      setDisplayProducts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortOption, multiplier, allProducts]);

  // Reset Page when selectedBrands change
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedBrands]);

  // Enforce grid view on mobile devices
  useEffect(() => {
    if (isMobile && viewMode !== 'grid') {
      setViewMode('grid');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // Handle Page Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Handle View Mode Toggle
  const handleViewToggle = () => {
    setViewMode(viewMode === 'grid' ? 'list' : 'grid');
  };

  // Filter products based on selected brands
  const filteredProducts = displayProducts.filter((product) => {
    if (selectedBrands.length === 0) {
      return true;
    } else {
      return selectedBrands.includes(product.brand);
    }
  });

  // Get current page products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const totalResults = filteredProducts.length;
  const totalPages = Math.ceil(totalResults / productsPerPage);
  const currentProducts = filteredProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Function to calculate Price Per Unit
  const calculatePricePerUnit = (product) => {
    const { purchase_price, product_volume, priced_by } = product || {};
    const adjusted_purchase_price = purchase_price * multiplier;

    if (
      adjusted_purchase_price &&
      product_volume > 0 &&
      priced_by &&
      !isNaN(adjusted_purchase_price)
    ) {
      const pricePerUnitValue = adjusted_purchase_price / product_volume;
      return {
        pricePerUnit: `$${pricePerUnitValue.toFixed(2)}/${priced_by}`,
        unitPriceValue: pricePerUnitValue,
      };
    } else {
      return null;
    }
  };

  // Render Products
  const renderProducts = () => {
    if (loadingVendorsProducts) {
      return (
        <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
          <CircularProgress />
        </Box>
      );
    }

    if (filteredProducts.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
          <Typography variant="h6">No products found.</Typography>
        </Box>
      );
    }

    return viewMode === 'grid' ? (
      <Grid container spacing={2}>
        {currentProducts.map((product) => (
          <Grid
            item
            xs={6} // 2 columns on mobile
            sm={6}
            md={2} // 4 columns on desktop
            key={product.id}
          >
            <ProductCard product={product} multiplier={multiplier} />
          </Grid>
        ))}
      </Grid>
    ) : (
      // List View
      <Box>
        {/* Header Row */}
        <Box
          sx={{
            display: 'flex',
            padding: 1,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box sx={{ width: '10%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Image</Typography>
          </Box>
          <Box sx={{ width: '40%' }}>
            <Typography variant="subtitle2">Product Info</Typography>
          </Box>
          <Box sx={{ width: '15%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Add to Cart</Typography>
          </Box>
          <Box sx={{ width: '15%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Price per Case</Typography>
          </Box>
          <Box sx={{ width: '20%', textAlign: 'center' }}>
            <Typography variant="subtitle2">Price per Unit</Typography>
          </Box>
        </Box>

        {/* Product List Items */}
        {currentProducts.map((product) => {
          const priceData = calculatePricePerUnit(product);
          const purchasePrice = Number(product.purchase_price) * multiplier;
          const isPriceTooLow = priceData && priceData.unitPriceValue < 0.04;
          const hasPrice = purchasePrice > 0;

          return (
            <Box
              key={product.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 1,
                borderBottom: `1px solid ${theme.palette.divider}`,
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.light, 0.1),
                },
              }}
            >
              {/* Image */}
              <Box sx={{ width: '10%', textAlign: 'center' }}>
                <Box
                  component="img"
                  src={product.Image_URL}
                  alt={product.short_description}
                  sx={{
                    width: 60,
                    height: 60,
                    objectFit: 'cover',
                    borderRadius: 1,
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/path/to/default/image.png'; // Update with actual default image path
                  }}
                />
              </Box>

              {/* Product Info */}
              <Box
                sx={{ width: '40%', paddingLeft: theme.spacing(1) }}
                onClick={() => navigate(`/product/${product.id}`)}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'normal',
                    color: 'black',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {product.short_description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.brand} | {product.FF_Catalog_Number}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {product.size}
                </Typography>
                {/* Lead Time */}
                {product.Lead_Time > 0 && (
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.warning.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {`${product.Lead_Time} days Lead Time`}
                  </Typography>
                )}
                {/* Special Notes */}
                {product.special_notes &&
                  !/no/i.test(product.special_notes) && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: '0.8rem',
                      }}
                    >
                      {product.special_notes}
                    </Typography>
                  )}
              </Box>

              {/* Add to Cart Button */}
              <Box
                sx={{ width: '15%', textAlign: 'center' }}
                onClick={(e) => e.stopPropagation()} // Prevent row click
              >
                {hasPrice ? (
                  <AddToCartButton product={product} multiplier={multiplier} />
                ) : null}
              </Box>

              {/* Price per Case */}
              <Box sx={{ width: '15%', textAlign: 'center' }}>
                {hasPrice ? (
                  <Typography
                    variant="body1"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    ${purchasePrice.toFixed(2)}
                    {isPriceTooLow && (
                      <Tooltip
                        title="This price seems unusually low and might be per unit rather than per case. The final price will be confirmed before purchase."
                        placement="top"
                      >
                        <WarningAmberIcon
                          sx={{
                            color: theme.palette.warning.main,
                            marginLeft: theme.spacing(0.5),
                          }}
                        />
                      </Tooltip>
                    )}
                  </Typography>
                ) : (
                  <Typography variant="body1">Contact Us</Typography>
                )}
              </Box>

              {/* Price per Unit */}
              <Box sx={{ width: '20%', textAlign: 'center' }}>
                {priceData && hasPrice ? (
                  <Typography variant="body2">{priceData.pricePerUnit}</Typography>
                ) : (
                  <Typography variant="body2">N/A</Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  };

  // Render Content with Result Count, Sort By, and View Toggle
  const renderContent = () => {
    if (restaurantVendors.length === 0) {
      return null;
    }

    return (
      <>
        {/* Main Content for Desktop and Mobile */}
        {isMobile ? (
          <>
            {/* On Mobile */}
            {/* Filter and Sort By */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
              }}
            >
              {/* Filter Icon */}
              <IconButton
                onClick={toggleDrawer(true)}
                size="small"
                sx={{ marginRight: theme.spacing(1) }}
              >
                <FilterListIcon />
              </IconButton>

              {/* Sort By Dropdown */}
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel
                  id="sort-select-label"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  Sort By
                </InputLabel>
                <Select
                  labelId="sort-select-label"
                  id="sort-select"
                  value={sortOption}
                  label="Sort By"
                  onChange={(e) => setSortOption(e.target.value)}
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  <MenuItem value="default">Default</MenuItem>
                  <MenuItem value="price_low_high">Price: Low to High</MenuItem>
                  <MenuItem value="price_high_low">Price: High to Low</MenuItem>
                  <MenuItem value="ppu_low_high">
                    Price per Unit: Low to High
                  </MenuItem>
                  <MenuItem value="ppu_high_low">
                    Price per Unit: High to Low
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* Products */}
            {renderProducts()}
          </>
        ) : (
          <>
            {/* On Desktop */}
            {/* Result Count, Sort By, and View Toggle */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: theme.spacing(2),
                // Removed or adjusted marginTop to align with filter sidebar
                marginTop: theme.spacing(0), // Changed from theme.spacing(2) to 0
              }}
            >
              {/* Result Count */}
              <Typography variant="subtitle2" color="text.secondary">
                {`Showing ${indexOfFirstProduct + 1}-${
                  indexOfLastProduct > totalResults
                    ? totalResults
                    : indexOfLastProduct
                } out of ${totalResults} results`}
              </Typography>

              {/* Sort By and View Toggle */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: theme.spacing(2),
                }}
              >
                {/* Sort By Dropdown */}
                <FormControl sx={{ minWidth: 160 }}>
                  <InputLabel
                    id="sort-select-label"
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    Sort By
                  </InputLabel>
                  <Select
                    labelId="sort-select-label"
                    id="sort-select"
                    value={sortOption}
                    label="Sort By"
                    onChange={(e) => setSortOption(e.target.value)}
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                    }}
                  >
                    <MenuItem value="default">Default</MenuItem>
                    <MenuItem value="price_low_high">Price: Low to High</MenuItem>
                    <MenuItem value="price_high_low">Price: High to Low</MenuItem>
                    <MenuItem value="ppu_low_high">
                      Price per Unit: Low to High
                    </MenuItem>
                    <MenuItem value="ppu_high_low">
                      Price per Unit: High to Low
                    </MenuItem>
                  </Select>
                </FormControl>

                {/* View Toggle Button */}
                {!isMobile && (
                  <IconButton onClick={handleViewToggle}>
                    {viewMode === 'grid' ? <ViewListIcon /> : <ViewModuleIcon />}
                  </IconButton>
                )}
              </Box>
            </Box>

            {/* Products */}
            {renderProducts()}
          </>
        )}

        {/* Pagination */}
        {totalPages > 1 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: theme.spacing(4),
              marginBottom: isMobile ? theme.spacing(8) : 0, // Add bottom margin on mobile
            }}
          >
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <div>
      {/* Sticky TopNavBar */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar onSearch={setSearchTerm} initialSearchTerm={searchTerm} />
      </Box>

      {restaurantVendors.length === 0 ? (
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            paddingTop: theme.spacing(8), // Added paddingTop to avoid overlap
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              padding: theme.spacing(4),
              backgroundColor: alpha(theme.palette.primary.light, 0.1),
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <Typography variant="h6" gutterBottom>
              You're Almost Ready to Shop!
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Set up your supplier accounts to unlock product browsing and start saving.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/settings')}
              sx={{
                marginTop: theme.spacing(2),
                textTransform: 'none',
              }}
            >
              Set Up Suppliers
            </Button>
          </Box>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: theme.spacing(8), // Added paddingTop to avoid overlap
          }}
        >
          {/* Main Title */}
          <Typography
            variant="h5"
            component="h2"
            sx={{
              marginTop: isMobile ? theme.spacing(1) : theme.spacing(4),
              marginBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
            }}
          >
            {searchTerm ? `Results for "${searchTerm}"` : 'All Products'}
          </Typography>

          {/* On Mobile, move Result Count here */}
          {isMobile && (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ marginBottom: theme.spacing(1) }}
            >
              {`Showing ${indexOfFirstProduct + 1}-${
                indexOfLastProduct > totalResults ? totalResults : indexOfLastProduct
              } out of ${totalResults} results`}
            </Typography>
          )}

          <Grid container spacing={2} alignItems="flex-start">
            {/* Filter Sidebar (Desktop) */}
            <Grid
              item
              xs={12}
              sm={3}
              md={2}
              sx={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                display: { xs: 'none', sm: 'block' },
                height: 'calc(100vh - 64px)',
                position: 'sticky',
                top: '64px',
                overflowY: 'auto',
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <FilterPanel
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                subcategories={subcategories}
                selectedSubcategory={selectedSubcategory}
                selectedVendor={selectedVendor}
                setSelectedVendor={setSelectedVendor}
                uniqueBrands={uniqueBrands}
                selectedBrands={selectedBrands}
                setSelectedBrands={setSelectedBrands}
                clearAllFilters={clearAllFilters}
              />
            </Grid>

            {/* Products Grid/List */}
            <Grid
              item
              xs={12}
              sm={9}
              md={10}
              sx={{
                paddingTop: isMobile ? theme.spacing(1) : theme.spacing(0), // Changed from theme.spacing(2) to theme.spacing(0) for desktop
                paddingBottom: isMobile ? theme.spacing(1) : theme.spacing(2),
              }}
            >
              {renderContent()}
            </Grid>
          </Grid>
        </Container>
      )}

      {/* Drawer for Mobile Filters */}
      {restaurantVendors.length > 0 && (
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <Box
            sx={{
              width: 250,
              padding: theme.spacing(2),
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              backgroundColor: theme.palette.background.paper,
            }}
            role="presentation"
          >
            {/* Close Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton onClick={toggleDrawer(false)} aria-label="close filters">
                <CloseIcon />
              </IconButton>
            </Box>

            {/* FilterPanel */}
            <FilterPanel
              categories={categories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              subcategories={subcategories}
              selectedSubcategory={selectedSubcategory}
              selectedVendor={selectedVendor}
              setSelectedVendor={setSelectedVendor}
              uniqueBrands={uniqueBrands}
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
              clearAllFilters={clearAllFilters}
            />
          </Box>
        </Drawer>
      )}
    </div>
  );
}

export default Shop;
