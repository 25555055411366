// src/components/SalesNavBar.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoImage from './assets/Logo.avif'; // Ensure the path is correct

const SalesNavBar = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null); // For Resources menu
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // Toggle the drawer's open state
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // Handle Resources menu open
  const handleResourcesMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle Resources menu close
  const handleResourcesMenuClose = () => {
    setAnchorEl(null);
  };

  // Define the navigation links
  const navLinks = [
    { label: 'Savings Calculator', path: '/calculator' },
    { label: 'Demo', path: '/demo' }, // Added Demo link
    { label: 'Pricing', path: '/pricing' },
    { label: 'Resources', path: null }, // Resources will have a submenu
    { label: 'Contact Us', path: '/contactus' },
  ];

  // Define the Resources submenu links
  const resourcesLinks = [
    { label: 'Blog', path: '/blogs/food-for-thought' },
    { label: 'Press', path: '/press' },
  ];

  return (
    <>
      {/* AppBar */}
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        sx={{
          borderBottom: '1px solid #E0E3DF',
          backgroundColor: '#FFFFFF',
          height: '64px',
          justifyContent: 'center',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          {/* Logo */}
          <Box
            component={Link}
            to="/"
            sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            <Box
              component="img"
              src={LogoImage}
              alt="FareFood Logo"
              sx={{ height: '30px', cursor: 'pointer' }}
            />
          </Box>

          {/* Desktop Navigation Links */}
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              marginLeft: 4,
              justifyContent: 'flex-start',
              flexGrow: 1,
            }}
          >
            {navLinks.map((link) => {
              if (link.label === 'Resources') {
                return (
                  <Box key="Resources" sx={{ position: 'relative' }}>
                    <Button
                      onClick={handleResourcesMenuOpen}
                      sx={{
                        color: '#3A3F3F',
                        textTransform: 'none',
                        fontWeight: 500,
                        fontSize: '0.95rem',
                        letterSpacing: '0.5px',
                        marginRight: 3,
                        borderBottom:
                          resourcesLinks.some(
                            (resourceLink) => location.pathname === resourceLink.path
                          )
                            ? '2px solid #0E9C9B'
                            : 'none',
                        '&:hover': { backgroundColor: 'transparent', color: '#0E9C9B' },
                        borderRadius: 0,
                        paddingBottom: '8px',
                        transition: 'none',
                      }}
                      endIcon={<ExpandMoreIcon />}
                    >
                      Resources
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleResourcesMenuClose}
                      MenuListProps={{ onMouseLeave: handleResourcesMenuClose }}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      {resourcesLinks.map((resourceLink) => (
                        <MenuItem
                          key={resourceLink.label}
                          component={Link}
                          to={resourceLink.path}
                          onClick={handleResourcesMenuClose}
                        >
                          {resourceLink.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                );
              } else {
                return (
                  <Button
                    key={link.label}
                    component={Link}
                    to={link.path}
                    sx={{
                      color: '#3A3F3F',
                      textTransform: 'none',
                      fontWeight: 500,
                      fontSize: '0.95rem',
                      letterSpacing: '0.5px',
                      marginRight: 3,
                      borderBottom:
                        location.pathname === link.path ? '2px solid #0E9C9B' : 'none',
                      '&:hover': { backgroundColor: 'transparent', color: '#0E9C9B' },
                      borderRadius: 0,
                      paddingBottom: '8px',
                      transition: 'none',
                    }}
                  >
                    {link.label}
                  </Button>
                );
              }
            })}
          </Box>

          {/* Action Buttons */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            {/* Log In Button */}
            <Button
              variant="text"
              component={Link}
              to="/login"
              sx={{
                marginRight: 2,
                paddingX: 0,
                paddingY: 1,
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '0.95rem',
                letterSpacing: '0.5px',
                color: '#0E9C9B',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: '#0C8B8A',
                },
                transition: 'none',
              }}
            >
              Log In
            </Button>

            {/* Try for Free Button */}
            <Button
              variant="contained"
              component={Link}
              to="/signup"
              sx={{
                paddingX: 3,
                paddingY: 1,
                borderRadius: '8px',
                textTransform: 'none',
                fontWeight: 500,
                fontSize: '0.95rem',
                letterSpacing: '0.5px',
                backgroundColor: '#027F7C',
                color: '#FFFFFF',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#006D6D',
                  boxShadow: 'none',
                },
                transition: 'none',
              }}
            >
              Try for free
            </Button>
          </Box>

          {/* Mobile Navigation Icon */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: 'flex', md: 'none' }, color: '#3A3F3F' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: {
            width: '250px',
            backgroundColor: '#FFFFFF',
            color: '#3A3F3F',
          },
        }}
      >
        {/* Drawer Header with Close Icon */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">Menu</Typography>
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>

        <List>
          {/* Navigation Links in Drawer */}
          {navLinks.map((link) => {
            if (link.label === 'Resources') {
              return (
                <React.Fragment key="Resources">
                  <ListItem>
                    <ListItemText
                      primary="Resources"
                      primaryTypographyProps={{
                        fontWeight: 500,
                        textAlign: 'left',
                      }}
                    />
                  </ListItem>
                  {/* Submenu items */}
                  {resourcesLinks.map((resourceLink) => (
                    <ListItem
                      button
                      key={resourceLink.label}
                      component={Link}
                      to={resourceLink.path}
                      sx={{ pl: 4 }}
                      onClick={handleDrawerToggle}
                    >
                      <ListItemText
                        primary={resourceLink.label}
                        primaryTypographyProps={{
                          fontWeight: 400,
                          textAlign: 'left',
                        }}
                      />
                    </ListItem>
                  ))}
                </React.Fragment>
              );
            } else {
              return (
                <ListItem
                  button
                  key={link.label}
                  component={Link}
                  to={link.path}
                  onClick={handleDrawerToggle}
                >
                  <ListItemText
                    primary={link.label}
                    primaryTypographyProps={{
                      fontWeight: 500,
                      textAlign: 'left',
                    }}
                  />
                </ListItem>
              );
            }
          })}

          <Divider />

          {/* Action Buttons in Drawer */}
          <ListItem
            button
            component={Link}
            to="/login"
            onClick={handleDrawerToggle}
          >
            <ListItemText
              primary="Log In"
              primaryTypographyProps={{
                fontWeight: 500,
                textAlign: 'left',
              }}
            />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/signup"
            onClick={handleDrawerToggle}
          >
            <ListItemText
              primary="Try for free"
              primaryTypographyProps={{
                fontWeight: 500,
                color: '#0E9C9B',
                textAlign: 'left',
              }}
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

export default SalesNavBar;
