// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import './index.css'; // Default styles like resets
import './styles/fonts.css';  // Custom fonts
import './styles/global.css'; // Global styles for the application

import reportWebVitals from './reportWebVitals';

// Initialize the React app
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// Optional: measure performance
reportWebVitals();
