import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import axios from 'axios';

function AddInventoryItemModal({ open, handleClose, fetchInventory }) {
  const [itemDescription, setItemDescription] = useState('');
  const [onHand, setOnHand] = useState('');
  const [par, setPar] = useState('');
  const [loading, setLoading] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 400,
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        alert('You must be logged in to perform this action.');
        handleClose();
        return;
      }

      const restaurant_id = user.restaurant_id;
      const On_Hand = parseFloat(onHand);
      const PAR = parseFloat(par);
      const remaining = On_Hand - PAR;
      const reorder_now = On_Hand < PAR;

      const data = {
        restaurant_id,
        item_description: itemDescription,
        On_Hand,
        PAR,
        remaining,
        reorder_now,
        product_id: 0, // Since it's manually added
      };

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );

      console.log('Inventory item added:', response.data);

      // Refresh the inventory list
      fetchInventory();

      // Close the modal
      handleClose();

      // Reset form fields
      setItemDescription('');
      setOnHand('');
      setPar('');
    } catch (error) {
      console.error('Error adding inventory item:', error);
      alert('An error occurred while adding the inventory item.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-inventory-item-modal"
    >
      <Box sx={style}>
        <Typography id="add-inventory-item-modal" variant="h6" component="h2"
          sx={{
            fontFamily: 'Gotham Medium Bold',
            marginBottom: 2,
            color: '#0E9C9B', // Teal color for the title
          }}
        >
          Add Inventory Item
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Item Description"
            variant="outlined"
            fullWidth
            required
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            sx={{
              marginTop: 2,
              '& .MuiInputLabel-root': {
                fontFamily: 'Gotham Medium',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#0E9C9B', // Teal color for focused placeholder
              },
              '& .MuiOutlinedInput-root': {
                fontFamily: 'Gotham Medium Bold', // Bold font for user input text
                color: '#555555', // Gray color for user input text
                '&.Mui-focused fieldset': {
                  borderColor: '#0E9C9B', // Teal border when focused
                },
              },
            }}
          />
          <TextField
            label="On Hand Quantity"
            variant="outlined"
            fullWidth
            required
            type="number"
            inputProps={{ min: 0 }}
            value={onHand}
            onChange={(e) => setOnHand(e.target.value)}
            sx={{
              marginTop: 2,
              '& .MuiInputLabel-root': {
                fontFamily: 'Gotham Medium',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#0E9C9B', // Teal color for focused placeholder
              },
              '& .MuiOutlinedInput-root': {
                fontFamily: 'Gotham Medium Bold', // Bold font for user input text
                color: '#555555', // Gray color for user input text
                '&.Mui-focused fieldset': {
                  borderColor: '#0E9C9B',
                },
              },
            }}
          />
          <TextField
            label="PAR Quantity"
            variant="outlined"
            fullWidth
            required
            type="number"
            inputProps={{ min: 0 }}
            value={par}
            onChange={(e) => setPar(e.target.value)}
            sx={{
              marginTop: 2,
              '& .MuiInputLabel-root': {
                fontFamily: 'Gotham Medium',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#0E9C9B', // Teal color for focused placeholder
              },
              '& .MuiOutlinedInput-root': {
                fontFamily: 'Gotham Medium Bold', // Bold font for user input text
                color: '#555555', // Gray color for user input text
                '&.Mui-focused fieldset': {
                  borderColor: '#0E9C9B',
                },
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
            sx={{
              marginTop: 3,
              borderRadius: '20px',
              backgroundColor: '#0E9C9B',
              color: '#ffffff',
              fontFamily: 'Gotham Medium Bold',
              '&:hover': {
                backgroundColor: '#0D8A89',
              },
            }}
          >
            {loading ? 'Adding...' : 'Add Item'}
          </Button>
        </form>
      </Box>
    </Modal>
  );
}

export default AddInventoryItemModal;
