// src/Login.js

import React, { useState, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  CssBaseline,
  IconButton,
  InputAdornment,
  Alert,
  CircularProgress,
  Link,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet'; // Import Helmet
import Logo from './assets/small-logo.avif'; // Ensure this path is correct
import './styles/fonts.css'; // Ensure this path is correct
import { AuthContext } from './contexts/AuthContext'; // Ensure this path is correct

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login, error, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      {/* Helmet for Setting Page Title and Meta Tags */}
      <Helmet>
        <title>FareFood | Log In</title>
        <meta
          name="description"
          content="Log in to FareFood to streamline your restaurant's food ordering process. Access your account to save time, reduce costs, and enhance your operations with our powerful tools."
        />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="FareFood | Log In" />
        <meta
          property="og:description"
          content="Access your FareFood account to optimize your restaurant's food orders. Save time and money with our intuitive platform tailored for your business needs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/login" />
        <meta property="og:image" content="https://www.farefood.com/assets/login-og-image.jpg" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Log In" />
        <meta
          name="twitter:description"
          content="Log in to FareFood to streamline your restaurant's food ordering process. Save time and reduce costs with our comprehensive tools designed for your business."
        />
        <meta name="twitter:image" content="https://www.farefood.com/assets/login-twitter-image.jpg" />
      </Helmet>

      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: 'white',
        }}
      >
        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center', // Center vertically
            justifyContent: 'center', // Center horizontally
            padding: theme.spacing(4),
          }}
        >
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Logo */}
              <Box
                component="img"
                src={Logo}
                alt="FareFood Logo"
                sx={{ width: 45, marginBottom: theme.spacing(2) }}
              />

              {/* Welcome Text */}
              <Typography
                component="h1"
                variant="h4"
                sx={{
                  mb: theme.spacing(3),
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontFamily: theme.typography.h4.fontFamily,
                  fontWeight: '700',
                  fontSize: '1.5rem',
                }}
              >
                Welcome back
              </Typography>

              {/* Display Error Message */}
              {error && (
                <Alert severity="error" sx={{ width: '100%', mb: theme.spacing(2) }}>
                  {error}
                </Alert>
              )}

              {/* Login Form */}
              <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  sx={{
                    mt: theme.spacing(3),
                    mb: theme.spacing(2),
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': { backgroundColor: theme.palette.primary.dark },
                    padding: theme.spacing(1.5),
                    borderRadius: theme.shape.borderRadius,
                    transition: 'transform 0.2s ease-in-out',
                    fontFamily: theme.typography.button.fontFamily,
                  }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
                </Button>
              </Box>

              {/* Sign Up Prompt */}
              <Typography
                variant="body2"
                sx={{
                  mt: theme.spacing(2),
                  textAlign: 'center',
                  color: theme.palette.text.secondary,
                  fontFamily: theme.typography.fontFamily,
                }}
              >
                Don't have an account?{' '}
                <Link
                  component={RouterLink}
                  to="/signup" 
                  sx={{
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                >
                  Sign Up
                </Link>
              </Typography>
            </Box>
          </Container>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            paddingY: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.disabled,
            fontSize: theme.typography.body2.fontSize,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          © 2024 FareFood, Inc. All Rights Reserved.
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Login;
