// src/LandingPage.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Container,
  Grid,
  Button,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import '@fontsource/poppins/400.css'; // Regular weight
import '@fontsource/poppins/500.css'; // Medium weight
import '@fontsource/poppins/700.css'; // Bold weight
import { Helmet } from 'react-helmet';

// Import the useInView hook
import { useInView } from 'react-intersection-observer';

// Import assets
import ProductScreenshot from './assets/FareFood_Shop_Screenshot_101324.png';
import FareFoodFunnel from './assets/FF-Funnel-Infographic.png';
import PartnerLogo1 from './assets/PFGLogo.jpg';
import PartnerLogo2 from './assets/US_Foods_logo.png';
import PartnerLogo3 from './assets/Sysco_NB.png';
import PartnerLogo4 from './assets/Cheney-Brothers-Logo.png';
import PartnerLogo5 from './assets/FreshPoint_Logo_Main_LoRes.webp';
import PartnerLogo6 from './assets/GordonFoodService_Logo_4c.webp';
import PartnerLogo7 from './assets/bhm.jpeg';
import PartnerLogo8 from './assets/Samuels_Seafood_Logo.jpg';

// Import new benefit images
import BenefitImage1 from './assets/FareFood_AllSuppliers_Graphic.png';
import BenefitImage2 from './assets/FareFood_PriceLowtoHigh_Graphic.png';
import BenefitImage3 from './assets/FareFood_Minimums_Graphic.png';

import BocelliLogo from './assets/Bocelli_Logo.png';
import MichaelMychackPhoto from './assets/Michael_Mychack_Photo.jpeg';
import BocelliInterior from './assets/Bocelli_Interior.jpeg';
import OysterPubLogo from './assets/OystersPub-Clear-1500x1500.webp';
import PeterDrymonisPhoto from './assets/Peter_Drymonis_OysterPub.jpg';
import OysterPubInterior from './assets/OysterPub_Interior.png';
import Footer from './Footer';
import SalesNavBar from './SalesNavBar'; // Import the navbar

// Import carousel
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import Material-UI Icons
import InventoryIcon from '@mui/icons-material/Inventory';
import SavingsIcon from '@mui/icons-material/Savings';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReplayIcon from '@mui/icons-material/Replay';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote'; // Imported FormatQuoteIcon

// Import useTheme from '@mui/material/styles'
import { useTheme } from '@mui/material/styles';

// Styled components for Hero and other sections
const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  marginTop: '64px', // Ensure content doesn't hide under navbar
}));

const HeroBackground = styled(Box)(({ theme }) => ({
  backgroundColor: '#D6ECE9',
  transition: 'all 0.3s ease',
  borderRadius: '0px',
  margin: '0 auto',
  maxWidth: '100%',
}));

const HeroContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'left',
  flexWrap: 'nowrap',
  maxWidth: 'lg',
  paddingLeft: 0, // Removed extra padding to fix white lines
  paddingRight: 0, // Removed extra padding to fix white lines
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'flex-start', // Adjusted alignment on mobile
  },
}));

const HeroContent = styled(Box)(({ theme }) => ({
  flex: '1 1 0%',
  maxWidth: '55%',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

const HeroImageContainer = styled(Box)(({ theme }) => ({
  flex: '1 1 0%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: theme.spacing(4),
  overflow: 'hidden',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    marginTop: theme.spacing(4),
    width: '100%',
  },
}));


const HeroImageStyled = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: '8px',
  display: 'block',
  boxSizing: 'border-box',
}));


const ValueProposition = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '2.388rem',
  fontWeight: 500,
  color: '#006D6D',
  marginBottom: theme.spacing(3),
  whiteSpace: 'pre-line', // Added to handle line breaks
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const SubText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.248rem',
  color: theme.palette.text.secondary,
  maxWidth: '800px',
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    fontSize: '0.95rem', // Decreased by 5%
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '200px',
  minHeight: '60px',
  fontFamily: 'Roboto, sans-serif',
  backgroundColor: '#006D6D',
  color: '#FFFFFF',
  fontSize: '1.275rem',
  borderRadius: '8px',
  textTransform: 'none',
  '&:hover': { backgroundColor: '#004C4C' },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    flex: 1,
  },
}));

const OutlineButton = styled(Button)(({ theme }) => ({
  minWidth: '200px',
  minHeight: '60px',
  borderColor: '#006D6D',
  color: '#006D6D',
  backgroundColor: '#FFFFFF',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '1.275rem',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    borderColor: '#0E9C9B',
    backgroundColor: '#E0F7F5',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.2)',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 'auto',
    flex: 1,
  },
}));

// Supplier Partnerships Carousel Styles
const CarouselContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  paddingTop: theme.spacing(3.75),
  paddingBottom: theme.spacing(3.75),
}));

const CarouselTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  color: theme.palette.text.secondary,
  fontSize: '1.248rem',
  marginBottom: theme.spacing(2.25),
  textAlign: 'center',
}));

const PartnerLogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex !important',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '3.75px',
  minHeight: '75px',
  position: 'relative',
}));

const PartnerLogo = styled('img', {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'marginTop',
})(({ size, marginTop }) => ({
  maxHeight: `${size * 75}px`,
  width: 'auto',
  objectFit: 'contain',
  marginTop: marginTop || 0,
}));

// Benefits Section Styles
const BenefitsSection = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  backgroundColor: '#FFFFFF',
}));

const BenefitItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const BenefitHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.6rem',
  color: '#000000',
  fontWeight: 600,
  textTransform: 'none',
  marginBottom: theme.spacing(2),
}));

const BenefitImage = styled('img')(({ theme, inview }) => ({
  width: '80%',
  height: 'auto',
  borderRadius: '8px',
  opacity: inview ? 1 : 0,
  transform: inview ? 'translateY(0)' : 'translateY(50px)',
  transition: 'opacity 0.6s ease, transform 0.6s ease',
}));

// Features Section Styles
const FeaturesSection = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  backgroundColor: '#D6ECE9',
}));

const FeatureItem = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow: '0 12px 24px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  minHeight: '240px',
  transition: 'transform 0.1s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const AccentLine = styled(Box)(({ theme, color }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '6px',
  backgroundColor: color,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
}));

const FeatureIconWrapper = styled(Box)(({ theme }) => ({
  color: '#006D6D',
  fontSize: '48px',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  textShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FeatureHeading = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.15rem',
  color: '#626C6A',
  fontWeight: 600,
  marginBottom: theme.spacing(1),
}));

const FeatureText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '0.9rem',
  color: '#626C6A',
  marginBottom: theme.spacing(2),
}));

// Case Studies Section Styles
const CaseStudiesSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#006D6D',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

const CaseStudiesTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  color: '#FFFFFF',
  fontSize: '1.248rem',
  marginBottom: theme.spacing(2.25),
  textAlign: 'center',
}));

const CaseStudyCard = styled(Box)(({ theme }) => ({
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(4),
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
}));

const CaseStudyContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const CaseStudyImageWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  aspectRatio: '16 / 9', // Ensures consistent aspect ratio
}));

const CaseStudyImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
}));

const CaseStudyDetails = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: '#D6ECE9',
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    minHeight: '400px', // Ensure consistent height on mobile
  },
}));

const Group1 = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  paddingLeft: theme.spacing(2.25),
  paddingRight: theme.spacing(2.25),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(0),
  },
}));

const Group2 = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'left',
  marginBottom: theme.spacing(2), // Add marginBottom
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(3),
  },
}));

const Group3 = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0.75),
  paddingLeft: theme.spacing(2.25),
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: theme.spacing(2), // Add marginBottom
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
  },
}));

const RestaurantLogo = styled('img')(({ theme }) => ({
  width: '120px',
  height: '120px',
  borderRadius: '50%',
  objectFit: 'cover',
}));

const QuoteIcon = styled(FormatQuoteIcon)(({ theme }) => ({
  fontSize: '60px',
  color: '#006D6D',
  transform: 'scaleX(-1)',
}));

const QuoteText = styled(Typography)(({ theme }) => ({
  color: '#626C6A',
  fontWeight: 400,
  fontSize: '1.2rem',
  textAlign: 'left',
  lineHeight: 2,
}));

const PersonDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const PersonPhoto = styled('img')(({ theme }) => ({
  width: '90px',
  height: '90px',
  borderRadius: '50%',
  objectFit: 'cover',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const PersonName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '0.9rem',
  fontWeight: 600,
  color: '#006D6D',
  textAlign: 'left',
}));

const RestaurantInfo = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '0.8rem',
  color: '#626C6A',
  textAlign: 'left',
}));

// Custom Arrow Components for Case Studies Carousel

const CaseStudyPrevArrow = (props) => {
  const { className, style, onClick } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const leftPosition = isMobile ? '10px' : '-60px';
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block !important',
        left: leftPosition,
        color: '#D6ECE9',
        borderRadius: '0px',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

const CaseStudyNextArrow = (props) => {
  const { className, style, onClick } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const rightPosition = isMobile ? '10px' : '-60px';
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block !important',
        right: rightPosition,
        color: '#D6ECE9',
        borderRadius: '0px',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

// Custom Arrow Components for Supplier Carousel (Default Arrows)
const SupplierPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', left: '-25px', color: '#006D6D' }}
      onClick={onClick}
    />
  );
};

const SupplierNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '-25px', color: '#006D6D' }}
      onClick={onClick}
    />
  );
};

function LandingPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const initialPaddingTopBottom = isDesktop ? 122 : 24; // 1/4 inch for mobile
  const minPaddingTopBottom = isDesktop ? 24 : 24;

  const [paddingValues, setPaddingValues] = useState({
    topBottom: initialPaddingTopBottom,
    leftRight: isDesktop ? 122 : 16,
    maxWidth: '100%',
  });

  useEffect(() => {
    const handleScroll = () => {
      if (isDesktop) {
        const scrollY = window.scrollY;
        const shrinkRate = 0.8;

        const newPadding = Math.max(
          minPaddingTopBottom,
          initialPaddingTopBottom - scrollY * shrinkRate
        );

        setPaddingValues((prevValues) => ({
          ...prevValues,
          topBottom: newPadding,
        }));
      }
    };
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [initialPaddingTopBottom, minPaddingTopBottom, isDesktop]);

  // Supplier logos with size adjustments and vertical alignment
  const logos = [
    {
      src: PartnerLogo1,
      alt: 'PFG Logo',
      size: 1.17,
      marginTop: '6px',
    },
    {
      src: PartnerLogo2,
      alt: 'US Foods Logo',
      size: 0.5,
      marginTop: '12px',
    },
    {
      src: PartnerLogo3,
      alt: 'Sysco Logo',
      size: 0.3,
      marginTop: '24px',
    },
    {
      src: PartnerLogo4,
      alt: 'Cheney Brothers Logo',
      size: 1.25,
    },
    {
      src: PartnerLogo5,
      alt: 'FreshPoint Logo',
      size: 0.4,
      marginTop: '12px',
    },
    {
      src: PartnerLogo6,
      alt: 'Gordon Food Service Logo',
      size: 1.25,
    },
    {
      src: PartnerLogo7,
      alt: 'BHM Logo',
      size: 1,
      marginTop: '6px',
    },
    {
      src: PartnerLogo8,
      alt: 'Samuels Seafood Logo',
      size: 0.85,
      marginTop: '6px',
    },
  ];

  // Settings for the react-slick carousel (Supplier Partnerships)
  const supplierCarouselSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    prevArrow: <SupplierPrevArrow />,
    nextArrow: <SupplierNextArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          arrows: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          arrows: true,
        },
      },
    ],
  };

  // Settings for the react-slick carousel (Case Studies)
  const caseStudiesSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <CaseStudyPrevArrow />,
    nextArrow: <CaseStudyNextArrow />,
    adaptiveHeight: false,
  };

  // Features data with icons and colors
  const features = [
    {
      icon: <InventoryIcon fontSize="inherit" />,
      title: 'Track inventory',
      description: 'Log stock levels and quickly reorder to prevent shortages.',
      color: '#FF6F61',
    },
    {
      icon: <SavingsIcon fontSize="inherit" />,
      title: 'Compare prices',
      description: 'Get the best deals across our massive supplier network.',
      color: '#6B5B95',
    },
    {
      icon: <RestaurantMenuIcon fontSize="inherit" />,
      title: 'Sample products',
      description: 'Test new products to ensure quality before buying.',
      color: '#88B04B',
    },
    {
      icon: <SearchIcon fontSize="inherit" />,
      title: 'Find suppliers',
      description: 'Access the entire distributor network with one onboarding.',
      color: '#F7CAC9',
    },
    {
      icon: <ListIcon fontSize="inherit" />,
      title: 'Build lists',
      description: 'Create product lists to stay organized and order faster.',
      color: '#92A8D1',
    },
    {
      icon: <AssignmentTurnedInIcon fontSize="inherit" />,
      title: 'Monitor minimums',
      description: 'Let FareFood track supplier order minimums for you.',
      color: '#955251',
    },
    {
      icon: <ShoppingCartIcon fontSize="inherit" />,
      title: 'Place orders',
      description: 'Fill your cart and place one order for all suppliers.',
      color: '#B565A7',
    },
    {
      icon: <ReplayIcon fontSize="inherit" />,
      title: 'Submit returns',
      description: 'Get help and submit returns through one contact.',
      color: '#009B77',
    },
  ];

  // Benefit images in order
  const benefitImages = [BenefitImage1, BenefitImage2, BenefitImage3];

  // InView hooks for benefit images
  const [ref1, inView1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref2, inView2] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [ref3, inView3] = useInView({ triggerOnce: true, threshold: 0.1 });

  // Adjusted hero text with line break on mobile
  const heroText = isMobile
    ? 'Easy orders.\nBig savings.'
    : 'Easy orders. Big savings.';

  return (
    <Box sx={{ backgroundColor: '#F8F8F8', overflowX: 'hidden' }}>
      {/* Helmet for Setting Page Title */}
      <Helmet>
        <title>FareFood | Smarter Restaurant Food Orders</title>
        {/* Optional: Add meta descriptions or other head elements here */}
        <meta
          name="description"
          content="Manage all your restaurant's food distributors in one place. Compare prices, place one order, and get one invoice. Save hours ordering and cut food costs by up to 25%."
        />
      </Helmet>
      {/* Header */}
      <SalesNavBar />

      {/* Hero Section */}
      <HeroSection>
        <HeroBackground
          sx={{
            paddingTop: `${paddingValues.topBottom}px`,
            paddingBottom: `${paddingValues.topBottom}px`,
            paddingLeft: `${paddingValues.leftRight}px`,
            paddingRight: `${paddingValues.leftRight}px`,
            maxWidth: paddingValues.maxWidth,
          }}
        >
          <HeroContainer maxWidth="lg">
            <HeroContent>
              <ValueProposition>{heroText}</ValueProposition>
              <SubText>
                Manage all your restaurant's food distributors in one place.
                Compare prices, place one order, and get one invoice. Save hours
                ordering and cut food costs by up to 25%.
              </SubText>
              <Box
                display="flex"
                gap={2}
                flexWrap="wrap"
                justifyContent="flex-start"
                sx={{ marginTop: 2 }}
              >
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    navigate('/signup');
                    window.scrollTo(0, 0);
                  }}
                >
                  Try for free
                </StyledButton>
                <OutlineButton
                  variant="outlined"
                  onClick={() => {
                    navigate('/demo');
                    window.scrollTo(0, 0);
                  }}
                >
                  View demo
                </OutlineButton>
              </Box>
            </HeroContent>
            <HeroImageContainer>
              <HeroImageStyled src={FareFoodFunnel} alt="FareFood Supplier Funnel" />
            </HeroImageContainer>
          </HeroContainer>
        </HeroBackground>
      </HeroSection>

      {/* Supplier Partnerships Carousel */}
      <CarouselContainer>
        <Container maxWidth="lg">
          <CarouselTitle variant="h4">featured supplier partners</CarouselTitle>
          <Slider {...supplierCarouselSettings}>
            {logos.map((logo, index) => (
              <PartnerLogoContainer key={index}>
                <PartnerLogo
                  src={logo.src}
                  alt={logo.alt}
                  size={logo.size}
                  marginTop={logo.marginTop}
                />
              </PartnerLogoContainer>
            ))}
          </Slider>
        </Container>
      </CarouselContainer>

      {/* Key Benefits Section */}
      <BenefitsSection>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {/* First Benefit */}
            <Grid container item xs={12} spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <BenefitItem>
                  <BenefitHeading>One platform, all suppliers</BenefitHeading>
                  <Typography variant="body1">
                    Stop juggling multiple suppliers. Order everything you need in one place.
                  </Typography>
                </BenefitItem>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <BenefitImage
                  ref={ref1}
                  src={benefitImages[0]}
                  alt="Benefit 1"
                  inview={inView1 ? 1 : 0}
                />
              </Grid>
            </Grid>
            {/* Second Benefit */}
            <Grid container item xs={12} spacing={2} alignItems="center">
              <Grid
                item
                xs={12}
                sm={6}
                order={{ xs: 2, sm: 1 }}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <BenefitImage
                  ref={ref2}
                  src={benefitImages[1]}
                  alt="Benefit 2"
                  inview={inView2 ? 1 : 0}
                />
              </Grid>
              <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
                <BenefitItem sx={{ ml: { sm: 8 } }}>
                  <BenefitHeading>Big savings</BenefitHeading>
                  <Typography variant="body1">
                    Cut your food costs by up to 25% without sacrificing quality.
                  </Typography>
                </BenefitItem>
              </Grid>
            </Grid>
            {/* Third Benefit */}
            <Grid container item xs={12} spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <BenefitItem>
                  <BenefitHeading>Time back on your plate</BenefitHeading>
                  <Typography variant="body1">
                    Spend less time ordering and more time with your staff and guests.
                  </Typography>
                </BenefitItem>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <BenefitImage
                  ref={ref3}
                  src={benefitImages[2]}
                  alt="Benefit 3"
                  inview={inView3 ? 1 : 0}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </BenefitsSection>

      {/* Features Section */}
      <FeaturesSection>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={index}
                display="flex"
                justifyContent="center"
              >
                <FeatureItem>
                  <AccentLine color={feature.color} />
                  <FeatureIconWrapper>{feature.icon}</FeatureIconWrapper>
                  <FeatureHeading>{feature.title}</FeatureHeading>
                  <FeatureText>{feature.description}</FeatureText>
                </FeatureItem>
              </Grid>
            ))}
          </Grid>
        </Container>
      </FeaturesSection>

      {/* Case Studies Section */}
      <CaseStudiesSection>
        <Container maxWidth="lg">
          <CaseStudiesTitle>what restaurants are saying</CaseStudiesTitle>
          <Slider {...caseStudiesSettings}>
            {/* First Case Study */}
            <CaseStudyCard>
              <CaseStudyContent>
                <CaseStudyImageWrapper>
                  <CaseStudyImage src={BocelliInterior} alt="Bocelli Interior" />
                </CaseStudyImageWrapper>
                <CaseStudyDetails>
                  {/* Group 1: Quote Icon on the left, Restaurant Logo on the right */}
                  <Group1>
                    <QuoteIcon />
                    <RestaurantLogo src={BocelliLogo} alt="Bocelli Logo" />
                  </Group1>

                  {/* Group 2: Quote Text */}
                  <Group2>
                    <QuoteText>
                      FareFood completely transformed our business. They did some food costing
                      for us and changed our supplier mix, and we're on pace to save over $70k
                      on food costs this year.
                    </QuoteText>
                  </Group2>

                  {/* Group 3: Person Details */}
                  <Group3>
                    <PersonDetails>
                      <PersonPhoto src={MichaelMychackPhoto} alt="Michael Mychack" />
                      <Box ml={2}>
                        <PersonName>Michael Mychack, Owner & GM</PersonName>
                        <RestaurantInfo>Bocelli Modern Italian</RestaurantInfo>
                        <RestaurantInfo>Tampa, FL</RestaurantInfo>
                      </Box>
                    </PersonDetails>
                  </Group3>
                </CaseStudyDetails>
              </CaseStudyContent>
            </CaseStudyCard>

            {/* Second Case Study */}
            <CaseStudyCard>
              <CaseStudyContent>
                <CaseStudyImageWrapper>
                  <CaseStudyImage src={OysterPubInterior} alt="Oyster Pub Interior" />
                </CaseStudyImageWrapper>
                <CaseStudyDetails>
                  {/* Group 1: Quote Icon on the left, Restaurant Logo on the right */}
                  <Group1>
                    <QuoteIcon />
                    <RestaurantLogo src={OysterPubLogo} alt="Oyster Pub Logo" />
                  </Group1>

                  {/* Group 2: Quote Text */}
                  <Group2>
                    <Box mb={2}>
                      <QuoteText>
                        These guys are gamechangers. We do monthly food costing and price
                        compare our supplier orders, but they still cut our food costs another
                        14%.
                      </QuoteText>
                    </Box>
                  </Group2>

                  {/* Group 3: Person Details */}
                  <Group3>
                    <PersonDetails>
                      <PersonPhoto src={PeterDrymonisPhoto} alt="Peter Drymonis" />
                      <Box ml={2}>
                        <PersonName>Peter Drymonis, Owner</PersonName>
                        <RestaurantInfo>The Oyster Pub</RestaurantInfo>
                        <RestaurantInfo>Daytona, FL</RestaurantInfo>
                      </Box>
                    </PersonDetails>
                  </Group3>
                </CaseStudyDetails>
              </CaseStudyContent>
            </CaseStudyCard>
          </Slider>
        </Container>
      </CaseStudiesSection>

      {/* Call to Action Section */}
      <Box
        sx={{
          paddingTop: 8,
          paddingBottom: 8,
          backgroundColor: '#F8F8F8',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: '#626C6A',
            fontSize: '1.7rem',
            [theme.breakpoints.down('sm')]: {
              fontSize: '1.5rem',
              whiteSpace: 'pre-line',
            },
          }}
        >
          Order smarter with{'\n'}FareFood today.
        </Typography>
        <Box
          display="flex"
          gap={2}
          flexWrap="wrap"
          justifyContent="center"
          sx={{ marginTop: 4 }}
        >
          <StyledButton
            variant="contained"
            onClick={() => {
              navigate('/signup'); // Updated link
              window.scrollTo(0, 0);
            }}
          >
            Try for free
          </StyledButton>
          <OutlineButton
            variant="outlined"
            onClick={() => {
              navigate('/demo');
              window.scrollTo(0, 0);
            }}
          >
            View demo
          </OutlineButton>
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
}

export default LandingPage;
