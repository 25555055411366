// PurchaseHistory.js
import React, { useState, useEffect } from 'react';
import TopNavBar from './TopNavBar';
import SideNavBar from './SideNavBar';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  CircularProgress,
  Divider,
  TextField,
  InputAdornment,
} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function PurchaseHistory() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  // State variables
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [loading, setLoading] = useState(false);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  // Retrieve user_id from local storage
  const userString = localStorage.getItem('user'); // Replace 'user' with your actual key if different
  const user = userString ? JSON.parse(userString) : null;
  const userId = user ? Number(user.id) : null; // Ensure userId is a number

  console.log('Retrieved user object:', user);
  console.log('User ID to be passed:', userId);

  const apiEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/purchase_history_items_react';

  // Fetch purchase history items
  const fetchPurchaseHistory = async (reset = false) => {
    if (!userId) {
      setError('User not authenticated. Please log in to view your purchase history.');
      console.error('Error: User ID not found');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Prepare query parameters
      const params = {
        useridfilter: userId, // Ensure user_id is correctly passed
        limit: limit,
        offset: reset ? 0 : offset,
        // Conditionally include productnamefilter only if searchTerm is not empty
        ...(searchTerm.trim() !== '' && { productnamefilter: searchTerm.trim() }),
      };

      console.log('API Request Params:', params);

      const response = await axios.get(apiEndpoint, { params });

      console.log('API Response:', response.data);

      // Since response.data is an array, assign fetchedItems directly to response.data
      const fetchedItems = Array.isArray(response.data) ? response.data : response.data.items;

      console.log('Fetched Items:', fetchedItems);

      if (Array.isArray(fetchedItems) && fetchedItems.length > 0) {
        setPurchaseHistory((prev) => (reset ? fetchedItems : [...prev, ...fetchedItems]));
        setOffset((prev) => prev + limit);
        setHasMore(fetchedItems.length === limit);
      } else {
        setHasMore(false);
        if (reset) {
          setPurchaseHistory([]);
        }
      }
    } catch (err) {
      console.error('Error fetching purchase history:', err);
      setError('Failed to load purchase history. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPurchaseHistory(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    setOffset(0);
    setHasMore(true); // Reset hasMore when searching
    fetchPurchaseHistory(true);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleLoadMore = () => {
    fetchPurchaseHistory();
  };

  const handleCardClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  // Debugging: Log purchaseHistory whenever it updates
  useEffect(() => {
    console.log('Purchase History State Updated:', purchaseHistory);
  }, [purchaseHistory]);

  return (
    <div>
      {/* Top Navigation Bar */}
      <TopNavBar title="Purchase History" />

      {/* Side Navigation Bar */}
      <SideNavBar />

      {/* Main Content Wrapper */}
      <Box
        sx={{
          paddingTop: '0px',
          paddingLeft: isDesktop ? `calc(240px + ${theme.spacing(2)})` : '0', // 240px + 16px
          paddingRight: isDesktop ? theme.spacing(2) : '0', // 16px
          transition: 'padding-left 0.3s, padding-right 0.3s',
          paddingBottom: theme.spacing(2), // 16px
        }}
      >
        {/* Search Bar */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            padding: '0 16px',
            marginBottom: '16px',
          }}
        >
          <TextField
            label="Search Purchase History"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleSearchKeyDown}
            sx={{
              width: '70%',
              backgroundColor: '#ffffff',
              borderRadius: '25px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputLabel-root': {
                fontFamily: 'Gotham Medium',
                color: '#bbbbbb',
              },
              '& .MuiInputLabel-root.Mui-focused': { // Corrected selector
                color: '#0e9c9b',
              },
              '& .MuiOutlinedInput-root': { // Ensures the border color changes on focus
                '&.Mui-focused fieldset': {
                  borderColor: '#0e9c9b',
                },
              },
              '& .MuiInputBase-input': { // Optional: Customize input text color
                fontFamily: 'Gotham Medium',
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={handleSearch}
                    sx={{
                      color: '#0e9c9b',
                      fontFamily: 'Gotham Medium',
                      padding: '6px 16px',
                      borderRadius: '20px',
                      minWidth: '70px',
                      textTransform: 'none',
                    }}
                  >
                    Search
                  </Button>
                </InputAdornment>
              ),
              style: { fontFamily: 'Gotham Medium' },
            }}
          />
        </Box>

        {/* Error Message */}
        {error && (
          <Box sx={{ textAlign: 'center', marginBottom: '24px' }}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Gotham Medium',
                color: 'red',
              }}
            >
              {error}
            </Typography>
          </Box>
        )}

        {/* Purchase History Grid */}
        <Grid container spacing={2}>
          {purchaseHistory.map((item) => {
            const product = item._product;
            const vendor = product?._vendor;

            // Log each product to verify
            console.log('Rendering product:', product);

            if (!product) {
              // Skip rendering if _product is missing
              console.warn(`Item with id ${item.id} is missing _product.`);
              return null;
            }

            return (
              <Grid item xs={12} sm={6} md={3} key={item.id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                  onClick={() => handleCardClick(product.id)}
                >
                  {product.Image_URL ? (
                    <CardMedia
                      component="img"
                      height="200"
                      image={product.Image_URL}
                      alt={product.short_description}
                    />
                  ) : (
                    <Box
                      sx={{
                        height: '200px',
                        backgroundColor: '#f0f0f0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant="subtitle1">No Image Available</Typography>
                    </Box>
                  )}

                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginBottom: '8px',
                      }}
                    >
                      {product.brand && (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: '#0E9C9B',
                            fontWeight: 'bold',
                            fontFamily: 'Gotham Medium',
                          }}
                        >
                          {product.brand}
                        </Typography>
                      )}
                      {vendor && vendor.Logo_URL && (
                        <img
                          src={vendor.Logo_URL}
                          alt={vendor.Short_Name}
                          style={{ height: '40px' }}
                        />
                      )}
                    </Box>

                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Gotham Light',
                        marginBottom: '8px',
                      }}
                    >
                      {truncateText(product.short_description, 100)}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Gotham Medium',
                        fontWeight: 'bold',
                        color: '#555555',
                      }}
                    >
                      Quantity: {item.quantity}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Gotham Medium',
                        fontWeight: 'bold',
                        color: '#555555',
                      }}
                    >
                      Total Price: ${item.total_price.toFixed(2)}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Gotham Medium',
                        fontWeight: 'bold',
                        color: '#555555',
                      }}
                    >
                      Purchase Price: ${item.purchase_price_at_time_of_order.toFixed(2)}
                    </Typography>
                  </CardContent>

                  <Divider />
                </Card>
              </Grid>
            );
          })}
        </Grid>

        {loading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <CircularProgress />
          </Box>
        )}

        {!loading && hasMore && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
            <Button
              variant="contained"
              onClick={handleLoadMore}
              sx={{
                backgroundColor: '#0E9C9B',
                color: '#ffffff',
                fontFamily: 'Gotham Medium',
                '&:hover': {
                  backgroundColor: '#0C7A78',
                },
              }}
            >
              Load More
            </Button>
          </Box>
        )}

        {!loading && purchaseHistory.length === 0 && !error && (
          <Box sx={{ textAlign: 'center', marginTop: '24px' }}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Gotham Medium',
                color: '#555555',
              }}
            >
              No purchase history found.
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default PurchaseHistory;
