// src/PricingPage.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Box,
  Container,
  Grid,
  Divider,
  Button,
  ButtonGroup,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet'; // Import Helmet
import SalesNavBar from './SalesNavBar';
import Footer from './Footer';
import CheckIcon from '@mui/icons-material/Check';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ExtensionIcon from '@mui/icons-material/Extension';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import BookIcon from '@mui/icons-material/Book';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ScaleIcon from '@mui/icons-material/Scale';

// Import the Poppins font family
import '@fontsource/poppins/400.css'; // Regular weight
import '@fontsource/poppins/500.css'; // Medium weight
import '@fontsource/poppins/700.css'; // Bold weight

// Styled Components
const Root = styled(Box)({
  margin: 0,
  padding: 0,
  overflowX: 'hidden',
});

const ExtendedGreenSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#D6ECE9',
  height: '384px', // Approximately 4 inches (4 * 96px = 384px)
}));

const WaveBackground = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#D6ECE9',
  height: '400px',
  margin: 0,
  padding: 0,
}));

const WaveSvg = styled('svg')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  height: '100%',
}));

const HeroSectionWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: '#006D6D',
  position: 'relative',
  paddingBottom: theme.spacing(5),
}));

const HeroSection = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(12), // Added paddingTop to match demo page
  paddingBottom: theme.spacing(5),
  backgroundColor: '#FFFFFF',
  textAlign: 'center',
  position: 'relative',
  zIndex: 1,
  overflow: 'visible',
}));

const HeroTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '2.019rem', // Reduced by 15% from 2.375rem
  fontWeight: 500,
  color: '#131616', // Black color
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.615rem', // Reduced by 15% from 1.9rem
  },
}));

const HeroSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '1.2rem',
  color: '#555555',
  marginBottom: theme.spacing(4),
}));

const Section = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(10),
  marginTop: '-70px',
  position: 'relative',
  zIndex: 1,
}));

const PlanBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 8,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  border: '1px solid #E0E0E0',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    maxWidth: '90%',
    margin: '0 auto',
    marginTop: theme.spacing(2),
  },
}));

const PlanTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 500,
  color: '#006D6D', // Dark Green
  marginBottom: theme.spacing(1),
  textAlign: 'left',
  minHeight: '30px',
}));

const PlanSubtitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '0.9rem',
  color: '#555555',
  marginBottom: theme.spacing(2),
  textAlign: 'left',
  minHeight: '40px',
}));

const PlanPriceContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  textAlign: 'left',
  minHeight: '80px',
}));

const PlanPrice = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '2rem',
  fontWeight: 700,
  color: '#131616',
  position: 'relative',
}));

const OriginalPrice = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '2rem',
  fontWeight: 700,
  color: '#131616',
  position: 'relative',
  textDecoration: 'line-through',
  textDecorationColor: '#006D6D', // Dark Green
}));

const PriceDetails = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
}));

const PriceDetailText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '0.9rem',
  color: '#777777',
}));

const AddOnSection = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(5), // Reduced to cut space by half
  backgroundColor: '#FFFFFF',
  textAlign: 'left',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  marginBottom: theme.spacing(4),
  color: '#131616',
  fontWeight: 500,
}));

const AddOnCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  borderRadius: 8,
  backgroundColor: '#FFFFFF',
  border: '1px solid #E0E0E0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '240px',
  transition: 'transform 0.3s, box-shadow 0.3s',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
  },
}));

const AccentLine = styled(Box)(({ theme, color }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '6px',
  backgroundColor: color,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
}));

const AddOnIcon = styled(Box)(({ theme }) => ({
  width: '40px',
  height: '40px',
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#006D6D', // Dark Green
  borderRadius: '50%',
  color: '#FFFFFF',
  fontSize: '1.5rem',
}));

const ButtonGroupContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  marginBottom: theme.spacing(1.5), // Adjusted to 1/8 inch
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
}));

const ButtonGroupButton = styled(Button)(({ theme, active }) => ({
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: active ? '#006D6D' : '#FFFFFF',
  color: active ? '#FFFFFF' : '#006D6D',
  border: `1px solid #006D6D`,
  '&:hover': {
    backgroundColor: active ? '#006D6D' : 'transparent',
  },
}));

const PricingPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  // Accent colors
  const accentColor = '#006D6D'; // Dark Green
  const grayColor = '#607D8B'; // Gunmetal Gray

  // State for billing period
  const [billingPeriod, setBillingPeriod] = React.useState('annual');

  // State for active plan on mobile/tablet
  const [activePlan, setActivePlan] = React.useState(0);

  // Plan data
  const plans = [
    {
      title: 'Starter',
      subtitle:
        'Get best price recommendations but place orders directly with your suppliers.',
      originalPriceMonthly: '$99',
      priceMonthly: '$0',
      originalPriceAnnual: '$59',
      priceAnnual: '$0',
      priceDetails: ['per month', '30-day free trial'],
      features: [
        'Weekly best price order guide',
        'Price tracking lists',
        'Inventory management',
        'Full product catalog',
      ],
      buttonText: 'Start Trial',
      buttonAction: () => navigate('/signup'),
    },
    {
      title: 'Basic',
      subtitle:
        'Place one order, get one invoice, and make one payment for all your suppliers.',
      priceMonthly: '$299',
      priceAnnual: '$199',
      priceDetails: ['per month'],
      features: [
        'Everything in Starter, plus:',
        'One ordering platform across suppliers',
        'Supplier onboarding',
        'Returns management',
      ],
      buttonText: 'Choose Basic',
      buttonAction: () => navigate('/signup'),
    },
    {
      title: 'Pro',
      subtitle:
        'Add additional locations and custom services to boost sales and trim costs even more.',
      price: 'Contact Us',
      priceDetails: [],
      features: [
        'Everything in Basic, plus:',
        'Order optimization',
        'Food costing',
        'Menu engineering',
        'More below',
      ],
      buttonText: 'Contact Us',
      buttonAction: () => navigate('/contactus'),
    },
  ];

  // Add-ons data
  const addOns = [
    {
      title: 'Digital Recipe Books',
      description: 'Make your recipes easier to cost and to share.',
      icon: <BookIcon />,
      color: '#FF6F61',
    },
    {
      title: 'Food Costing',
      description: 'Discover which dishes are your winners and losers.',
      icon: <AttachMoneyIcon />,
      color: '#6B5B95',
    },
    {
      title: 'Menu Engineering',
      description: 'Boost margins by optimizing your existing menu.',
      icon: <EngineeringIcon />,
      color: '#88B04B',
    },
    {
      title: 'Menu Redesign',
      description: "Refresh your menu's look and encourage certain purchases.",
      icon: <MenuBookIcon />,
      color: '#F7CAC9',
    },
    {
      title: 'Integrations',
      description: 'Connect your accounting and POS systems to FareFood.',
      icon: <ExtensionIcon />,
      color: '#92A8D1',
    },
    {
      title: 'Daily Delivery',
      description: 'Keep your ingredients fresh and your inventory lean.',
      icon: <LocalShippingIcon />,
      color: '#955251',
    },
    {
      title: 'Minimum Tracking',
      description: 'Let FareFood make sure you meet supplier minimums.',
      icon: <ScaleIcon />,
      color: '#B565A7',
    },
    {
      title: 'No Order Minimums',
      description: 'Order exactly what you need without supplier minimums.',
      icon: <NoMealsIcon />,
      color: '#009B77',
    },
  ];

  return (
    <Box>
      {/* Helmet for Setting Page Title and Meta Tags */}
      <Helmet>
        <title>FareFood | Pricing</title>
        <meta
          name="description"
          content="Choose the right FareFood plan for your restaurant. Save time and money on food orders with our Starter, Basic, and Pro plans. Explore add-ons to optimize your operations even further."
        />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="FareFood | Pricing" />
        <meta
          property="og:description"
          content="Select the perfect FareFood plan to streamline your restaurant's food ordering process. Compare our Starter, Basic, and Pro plans to find the best fit for your needs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/pricing" />
        <meta property="og:image" content="https://www.farefood.com/assets/pricing-og-image.jpg" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Pricing" />
        <meta
          name="twitter:description"
          content="Discover FareFood's pricing plans tailored for restaurants. Save time and reduce costs with our Starter, Basic, and Pro packages. Learn more about our add-ons to enhance your operations."
        />
        <meta name="twitter:image" content="https://www.farefood.com/assets/pricing-twitter-image.jpg" />
      </Helmet>

      {/* Header */}
      <SalesNavBar />

      {/* Hero Section Wrapper with Dark Green Background */}
      <HeroSectionWrapper>
        {/* Hero Section */}
        <HeroSection>
          <Container maxWidth="md">
            <HeroTitle variant="h3">
              Stir up savings with the right plan for you.
            </HeroTitle>
            <HeroSubtitle>
              Save some serious time and money on food orders with FareFood.
            </HeroSubtitle>

            {/* Billing Period Toggle */}
            <Box sx={{ marginBottom: 4, display: 'flex', justifyContent: 'center' }}>
              <ButtonGroup>
                <Button
                  onClick={() => setBillingPeriod('annual')}
                  variant={billingPeriod === 'annual' ? 'contained' : 'outlined'}
                  sx={{
                    backgroundColor:
                      billingPeriod === 'annual' ? accentColor : 'transparent',
                    color: billingPeriod === 'annual' ? '#FFFFFF' : accentColor,
                    borderColor: accentColor,
                    textTransform: 'none',
                    fontFamily: 'Poppins, sans-serif',
                    '&:hover': {
                      backgroundColor:
                        billingPeriod === 'annual' ? accentColor : 'transparent',
                    },
                  }}
                >
                  Annual
                </Button>
                <Button
                  onClick={() => setBillingPeriod('monthly')}
                  variant={billingPeriod === 'monthly' ? 'contained' : 'outlined'}
                  sx={{
                    backgroundColor:
                      billingPeriod === 'monthly' ? accentColor : 'transparent',
                    color: billingPeriod === 'monthly' ? '#FFFFFF' : accentColor,
                    borderColor: accentColor,
                    textTransform: 'none',
                    fontFamily: 'Poppins, sans-serif',
                    '&:hover': {
                      backgroundColor:
                        billingPeriod === 'monthly' ? accentColor : 'transparent',
                    },
                  }}
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </Box>

            {/* Button Group for Mobile/Tablets */}
            <ButtonGroupContainer>
              {plans.map((plan, index) => (
                <ButtonGroupButton
                  key={index}
                  active={activePlan === index}
                  onClick={() => setActivePlan(index)}
                >
                  {plan.title}
                </ButtonGroupButton>
              ))}
            </ButtonGroupContainer>

            {/* Plan Cards */}
            {isDesktop ? (
              // Plan Cards for Desktop/Tablets
              <Grid
                container
                spacing={4}
                justifyContent="center"
                alignItems="stretch"
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {plans.map((plan, index) => {
                  let displayOriginalPrice = '';
                  let displayPrice = '';
                  if (plan.title !== 'Pro') {
                    if (billingPeriod === 'annual') {
                      displayOriginalPrice = plan.originalPriceAnnual;
                      displayPrice = plan.priceAnnual;
                    } else {
                      displayOriginalPrice = plan.originalPriceMonthly;
                      displayPrice = plan.priceMonthly;
                    }
                  } else {
                    displayPrice = plan.price;
                  }
                  return (
                    <Grid item xs={12} md={4} key={index}>
                      <PlanBox>
                        <PlanTitle variant="h5">{plan.title}</PlanTitle>
                        <PlanSubtitle>{plan.subtitle}</PlanSubtitle>
                        <PlanPriceContainer>
                          {displayOriginalPrice && (
                            <OriginalPrice>{displayOriginalPrice}</OriginalPrice>
                          )}
                          <PlanPrice
                            sx={{
                              marginLeft: displayOriginalPrice ? 1 : 0,
                              color: displayPrice === '$0' ? accentColor : '#131616',
                              fontSize: displayPrice === '$0' ? '1.5rem' : '2rem',
                            }}
                          >
                            {displayPrice}
                          </PlanPrice>
                          {plan.priceDetails.length > 0 && (
                            <PriceDetails>
                              {plan.priceDetails.map((detail, idx) => (
                                <PriceDetailText
                                  key={idx}
                                  sx={{
                                    color: detail.includes('30-day')
                                      ? accentColor
                                      : '#777777',
                                  }}
                                >
                                  {detail}
                                </PriceDetailText>
                              ))}
                            </PriceDetails>
                          )}
                        </PlanPriceContainer>
                        <Divider sx={{ marginY: 2, borderColor: '#E0E0E0' }} />
                        <Box flexGrow={1}>
                          {plan.features.map((feature, idx) => (
                            <Box
                              display="flex"
                              alignItems="center"
                              key={idx}
                              sx={{ marginBottom: 0.5 }}
                            >
                              {/* Handle "Everything in..." feature differently */}
                              {feature.startsWith('Everything in') ? (
                                <Typography
                                  variant="body2"
                                  sx={{
                                    textAlign: 'left',
                                    fontFamily: 'Poppins, sans-serif',
                                    fontWeight: 'bold',
                                    fontSize: '0.8rem',
                                  }}
                                >
                                  {feature}
                                </Typography>
                              ) : (
                                <>
                                  <CheckIcon
                                    sx={{
                                      marginRight: 1,
                                      color: accentColor,
                                      fontSize: '1rem',
                                    }}
                                  />
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      textAlign: 'left',
                                      fontFamily: 'Poppins, sans-serif',
                                      fontSize: '0.8rem',
                                    }}
                                  >
                                    {feature}
                                  </Typography>
                                </>
                              )}
                            </Box>
                          ))}
                        </Box>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={plan.buttonAction}
                          sx={{
                            marginTop: 1,
                            backgroundColor: accentColor,
                            borderRadius: '50px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            fontFamily: 'Poppins, sans-serif',
                            '&:hover': { backgroundColor: '#004C4C' },
                          }}
                        >
                          {plan.buttonText}
                        </Button>
                      </PlanBox>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              // Plan Cards for Mobile/Tablets
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  paddingX: theme.spacing(2),
                }}
              >
                {plans.map((plan, index) => {
                  if (index !== activePlan) return null;
                  let displayOriginalPrice = '';
                  let displayPrice = '';
                  if (plan.title !== 'Pro') {
                    if (billingPeriod === 'annual') {
                      displayOriginalPrice = plan.originalPriceAnnual;
                      displayPrice = plan.priceAnnual;
                    } else {
                      displayOriginalPrice = plan.originalPriceMonthly;
                      displayPrice = plan.priceMonthly;
                    }
                  } else {
                    displayPrice = plan.price;
                  }
                  return (
                    <PlanBox key={index}>
                      <PlanTitle variant="h5">{plan.title}</PlanTitle>
                      <PlanSubtitle>{plan.subtitle}</PlanSubtitle>
                      <PlanPriceContainer>
                        {displayOriginalPrice && (
                          <OriginalPrice>{displayOriginalPrice}</OriginalPrice>
                        )}
                        <PlanPrice
                          sx={{
                            marginLeft: displayOriginalPrice ? 1 : 0,
                            color: displayPrice === '$0' ? accentColor : '#131616',
                            fontSize: displayPrice === '$0' ? '1.5rem' : '2rem',
                          }}
                        >
                          {displayPrice}
                        </PlanPrice>
                        {plan.priceDetails.length > 0 && (
                          <PriceDetails>
                            {plan.priceDetails.map((detail, idx) => (
                              <PriceDetailText
                                key={idx}
                                sx={{
                                  color: detail.includes('30-day')
                                    ? accentColor
                                    : '#777777',
                                }}
                              >
                                {detail}
                              </PriceDetailText>
                            ))}
                          </PriceDetails>
                        )}
                      </PlanPriceContainer>
                      <Divider sx={{ marginY: 2, borderColor: '#E0E0E0' }} />
                      <Box flexGrow={1}>
                        {plan.features.map((feature, idx) => (
                          <Box
                            display="flex"
                            alignItems="center"
                            key={idx}
                            sx={{ marginBottom: 0.5 }}
                          >
                            {/* Handle "Everything in..." feature differently */}
                            {feature.startsWith('Everything in') ? (
                              <Typography
                                variant="body2"
                                sx={{
                                  textAlign: 'left',
                                  fontFamily: 'Poppins, sans-serif',
                                  fontWeight: 'bold',
                                  fontSize: '0.8rem',
                                }}
                              >
                                {feature}
                              </Typography>
                            ) : (
                              <>
                                <CheckIcon
                                  sx={{
                                    marginRight: 1,
                                    color: accentColor,
                                    fontSize: '1rem',
                                  }}
                                />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    textAlign: 'left',
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '0.8rem',
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </>
                            )}
                          </Box>
                        ))}
                      </Box>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={plan.buttonAction}
                        sx={{
                          marginTop: 1,
                          backgroundColor: accentColor,
                          borderRadius: '50px',
                          fontWeight: 'bold',
                          textTransform: 'none',
                          fontFamily: 'Poppins, sans-serif',
                          '&:hover': { backgroundColor: '#004C4C' },
                        }}
                      >
                        {plan.buttonText}
                      </Button>
                    </PlanBox>
                  );
                })}
              </Box>
            )}
          </Container>
        </HeroSection>
      </HeroSectionWrapper>

      {/* Explore Add-ons Section */}
      <AddOnSection>
        <Container maxWidth="lg">
          <SectionTitle variant="h4" align="left">
            Explore Add-Ons
          </SectionTitle>
          <Typography
            variant="body1"
            align="left"
            sx={{ marginBottom: 4, fontFamily: 'Poppins, sans-serif' }}
          >
            Take the next step and make your restaurant a profit-printing machine.
          </Typography>
          {/* Add-ons Grid */}
          <Grid container spacing={4}>
            {addOns.map((addon, idx) => (
              <Grid item xs={12} sm={6} md={3} key={idx}>
                <AddOnCard>
                  <AccentLine color={addon.color} />
                  <AddOnIcon>{addon.icon}</AddOnIcon>
                  <Typography
                    variant="h6"
                    sx={{
                      color: '#000000',
                      marginBottom: 1,
                      textAlign: 'center',
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  >
                    {addon.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#555555',
                      textAlign: 'center',
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  >
                    {addon.description}
                  </Typography>
                </AddOnCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </AddOnSection>

      {/* Contact Sales Section */}
      <Box
        sx={{
          paddingTop: 8,
          paddingBottom: 8,
          backgroundColor: '#FFFFFF',
          textAlign: 'center',
        }}
      >
        <Container maxWidth="sm">
          <Typography
            variant="h5"
            sx={{
              marginBottom: 4,
              fontFamily: 'Poppins, sans-serif',
              color: '#131616',
            }}
          >
            Have questions for our team?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/contactus')}
            sx={{
              paddingX: 4,
              paddingY: 1.5,
              backgroundColor: accentColor,
              borderRadius: '50px',
              fontWeight: 'bold',
              textTransform: 'none',
              fontFamily: 'Poppins, sans-serif',
              '&:hover': { backgroundColor: '#004C4C' },
            }}
          >
            Contact Us
          </Button>
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default PricingPage;
