// src/components/LowStockComponent.js

import React from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Tooltip,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import AddToCartButton from './AddToCartButton'; // Adjust the import path as needed

const LowStockComponent = ({
  lowStockItems,
  lowStockLoading,
  lowStockError,
  handleGoToInventory,
  onItemClick,
}) => {
  const theme = useTheme();

  // Function to format number as currency
  const formatCurrency = (amount) => {
    if (typeof amount !== 'number') return '-';
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0, // Remove decimal places
    });
  };

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: 0,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        minHeight: '300px',
        border: '1px solid #ccc', // Added border
      }}
    >
      {lowStockLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '250px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : lowStockError ? (
        <Alert severity="error">
          {`There was an error fetching your restock alerts: ${lowStockError}. Please try again later.`}
        </Alert>
      ) : lowStockItems.length === 0 ? (
        // Customized Empty State for Inventory
        <Box
          sx={{
            textAlign: 'center',
            padding: theme.spacing(4),
            backgroundColor: alpha(theme.palette.success.light, 0.1),
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Your Inventory is Fully Stocked
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Great job! All your inventory items are well-stocked. Keep up the good work or add new items to expand your offerings.
          </Typography>
          <Button
            variant="outlined" // Changed to outlined
            color="primary"
            onClick={handleGoToInventory}
            sx={{
              marginTop: theme.spacing(2),
              textTransform: 'none',
            }}
          >
            Manage Inventory
          </Button>
        </Box>
      ) : (
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: theme.spacing(2),
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontWeight: theme.typography.h6.fontWeight,
                textTransform: 'capitalize',
              }}
            >
              Low Stock Inventory
            </Typography>
          </Box>

          {/* Inventory Table */}
          <TableContainer component={Paper}>
            <Table
              sx={{
                tableLayout: 'fixed', // Keeps columns fixed
                width: '100%', // Ensures the table fits the container width
                '& .MuiTableCell-root': {
                  padding: theme.spacing(0.5, 0.2), // Adjusted padding
                  fontFamily: theme.typography.fontFamily,
                },
              }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  {/* Product Description Column */}
                  <TableCell
                    sx={{
                      width: '70%',
                      backgroundColor: theme.palette.primary.light, // Set background for header
                      color: 'white', // Force text color to white
                      paddingLeft: theme.spacing(1),
                      paddingRight: theme.spacing(0.2),
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: 'white' }}>
                      Product
                    </Typography>
                  </TableCell>
                  {/* Stock Column */}
                  <TableCell
                    align="center"
                    sx={{
                      width: '15%',
                      backgroundColor: theme.palette.primary.light, // Ensure consistent background
                      color: 'white', // Force text color to white
                      padding: theme.spacing(0),
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: 'white' }}>
                      Stock
                    </Typography>
                  </TableCell>
                  {/* PAR Column */}
                  <TableCell
                    align="center"
                    sx={{
                      width: '15%',
                      backgroundColor: theme.palette.primary.light, // Ensure consistent background
                      color: 'white', // Force text color to white
                      padding: theme.spacing(0),
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: 'white' }}>
                      PAR
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lowStockItems.slice(0, 10).map((item) => {
                  const productInfo = item._inventory_items_product_info || {};
                  const hasProductId = Boolean(item.product_id);

                  return (
                    <TableRow
                      key={item.id}
                      hover
                      sx={{
                        cursor: 'pointer',
                        backgroundColor:
                          item.On_Hand <= item.PAR
                            ? alpha(theme.palette.error.light, 0.2)
                            : 'transparent',
                        '&:hover': {
                          backgroundColor:
                            item.On_Hand <= item.PAR
                              ? alpha(theme.palette.error.light, 0.4)
                              : theme.palette.action.hover,
                        },
                        padding: theme.spacing(1, 0), // Add top and bottom padding to make rows easier to click
                      }}
                      onClick={() => onItemClick(item)}
                    >
                      {/* Product Description */}
                      <TableCell
                        sx={{
                          maxWidth: '300px', // Increased max-width for longer descriptions
                          paddingLeft: theme.spacing(1), // Added padding to move away from the left edge
                          paddingRight: theme.spacing(0.2),
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start', // Align items to the start (left)
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: 'normal',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3, // Clamp to 3 lines
                            WebkitBoxOrient: 'vertical',
                          }}
                        >
                          {item.item_description || 'N/A'}
                        </Typography>
                        {/* Order Button moved below the Product Description */}
                        {hasProductId && productInfo ? (
                          <Box
                            sx={{
                              marginTop: theme.spacing(0.5),
                              // No additional padding or alignment needed here
                            }}
                          >
                            <AddToCartButton
                              product={productInfo}
                              multiplier={1} // Replace with actual multiplier if needed
                              sx={{ padding: theme.spacing(0.25) }}
                            />
                          </Box>
                        ) : null}
                      </TableCell>

                      {/* Stock Column */}
                      <TableCell
                        align="center"
                        sx={{
                          padding: theme.spacing(0),
                          width: '40px',
                        }}
                      >
                        <Typography variant="body1">
                          {item.On_Hand !== undefined ? item.On_Hand : 'N/A'}
                        </Typography>
                      </TableCell>

                      {/* PAR Column */}
                      <TableCell
                        align="center"
                        sx={{
                          padding: theme.spacing(0),
                          width: '40px',
                        }}
                      >
                        <Typography variant="body1">
                          {item.PAR !== undefined ? item.PAR : 'N/A'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Button for More Out-of-Stock Items */}
          {lowStockItems.length > 10 && (
            <Box sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
              <Button
                variant="outlined" // Changed to outlined
                color="info" // Teal color
                onClick={handleGoToInventory}
                sx={{
                  textTransform: 'none',
                }}
              >
                {`And ${lowStockItems.length - 10} more out-of-stock items. View All in Inventory`}
              </Button>
            </Box>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default LowStockComponent;
