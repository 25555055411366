// src/Features.js

import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

// Define the bright orange accent color
const ACCENT_COLOR = '#0E9C9B';

// Styled components for consistent styling
const Section = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}));

const FeatureTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.8rem',
  color: ACCENT_COLOR,
  marginBottom: theme.spacing(2),
  fontWeight: theme.typography.fontWeightMedium,
}));

const FeatureDescription = styled(Typography)(({ theme }) => ({
  fontSize: '1.1rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(2),
}));

const CTAButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4), // Reduced from theme.spacing(6) to theme.spacing(4)
  padding: '12px 24px',
  borderRadius: '50px',
  textTransform: 'none',
  fontWeight: theme.typography.fontWeightBold,
  backgroundColor: ACCENT_COLOR,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.error.dark, // Darker shade on hover
  },
}));

const Features = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate(); // Initialize navigate

  // Feature Categories and Details
  const featureCategories = [
    {
      id: 'ordering-procurement',
      title: 'Ordering and Procurement',
      features: [
        {
          title: 'Unified Ordering Platform',
          description:
            'Streamline your procurement by ordering from all your suppliers in one intuitive platform. Compare products, prices, and MOQs effortlessly to make informed purchasing decisions.',
        },
        {
          title: 'Automatic Price Comparison',
          description:
            'Stay ahead with real-time price comparisons across multiple suppliers. Ensure you’re always getting the best deals, reducing your food costs significantly.',
        },
        {
          title: 'Order Guide',
          description:
            'Maintain consistency with customized order guides tailored to your restaurant’s specific needs. Simplify repeat orders and manage inventory more effectively.',
        },
        {
          title: 'Order Management',
          description:
            'Easily track past orders, monitor delivery statuses, handle returns, and reorder items based on the latest prices. Enhance your operational efficiency seamlessly.',
        },
      ],
    },
    {
      id: 'inventory-management',
      title: 'Inventory Management',
      features: [
        {
          title: 'Real-Time Inventory Tracking',
          description:
            'Monitor your ingredient levels in real-time to prevent overstocking or stockouts. Reduce waste and ensure your kitchen operates smoothly.',
        },
        {
          title: 'Automatic Reordering',
          description:
            'Set thresholds for automatic reordering based on inventory levels. Never run out of essential ingredients again, keeping your restaurant running without interruptions.',
        },
      ],
    },
    {
      id: 'supplier-integration',
      title: 'Supplier Integration',
      features: [
        {
          title: 'Multiple Supplier Access',
          description:
            'Connect with leading suppliers like PFG, Sysco, GFS, and US Foods. Access comprehensive product information, images, and manage your supplier relationships effortlessly.',
        },
        {
          title: 'Supplier Onboarding',
          description:
            'Easily onboard new suppliers to expand your procurement options. Negotiate better deals and diversify your supply chain without hassle.',
        },
      ],
    },
    {
      id: 'pricing-cost-management',
      title: 'Pricing and Cost Management',
      features: [
        {
          title: 'Price Tracking Lists',
          description:
            'Keep track of price fluctuations over time. Make data-driven decisions to optimize your purchasing strategy and maximize savings.',
        },
        {
          title: 'MOQ Management',
          description:
            'Efficiently manage Minimum Order Quantities (MOQs) from different suppliers. Optimize your orders to meet supplier requirements without overcommitting.',
        },
        {
          title: 'Cost Analysis',
          description:
            'Analyze your ingredient costs to identify areas for savings. Enhance your profitability by making informed financial decisions.',
        },
      ],
    },
    {
      id: 'user-management',
      title: 'User Management',
      features: [
        {
          title: 'Role-Based Access Control',
          description:
            'Assign specific roles and permissions to your team members. Ensure that employees and owners have appropriate access to manage orders and inventory.',
        },
        {
          title: 'Employee and Owner Permissions',
          description:
            'Empower your staff by allowing them to add items to the cart while granting owners the authority to approve and submit orders, maintaining control over procurement.',
        },
      ],
    },
    {
      id: 'analytics-insights',
      title: 'Analytics and Insights',
      features: [
        {
          title: 'Advanced Analytics',
          description:
            'Gain deep insights into your purchasing habits and supplier performance. Use analytics to drive strategic decisions and improve operational efficiency.',
        },
        {
          title: 'AI Integration',
          description:
            'Leverage Artificial Intelligence to automate repetitive tasks and optimize your procurement process. Enhance productivity and reduce manual workload.',
        },
      ],
    },
    {
      id: 'future-features',
      title: 'Future Features',
      features: [
        {
          title: 'POS System Integration',
          description:
            'Seamlessly integrate with your existing POS systems like Toast. Synchronize data in real-time for unified operations and better data management.',
        },
        {
          title: 'Automatic Supplier Ordering',
          description:
            'Allow Fare.food to handle your ordering needs automatically. Based on inventory levels, our platform can place orders with suppliers, ensuring you’re always stocked up.',
        },
      ],
    },
    {
      id: 'addons',
      title: 'Add-ons',
      features: [
        {
          title: 'Dish Recipe Builder',
          description:
            'Develop and manage dish recipes using the lowest-priced ingredients. Optimize your menu for profitability without compromising quality.',
        },
        {
          title: 'Costing Analysis',
          description:
            'Understand your ingredient costs in detail. Make informed decisions to enhance your restaurant’s profitability and efficiency.',
        },
        {
          title: 'MOQ Tracking & Reworking',
          description:
            'Optimize your orders to meet supplier minimums without overstocking. Streamline your procurement process for better inventory management.',
        },
        {
          title: 'Menu Engineering',
          description:
            'Design menus that highlight your most profitable items. Attract customers and maximize sales through strategic menu planning.',
        },
        {
          title: 'Menu Redesign',
          description:
            'Revamp your menu’s appearance to enhance brand appeal and improve customer experience. Stay current and engaging with modern menu designs.',
        },
        {
          title: 'Integrations',
          description:
            'Connect Fare.food with your accounting and POS systems for streamlined operations. Ensure all your business tools work together seamlessly.',
        },
        {
          title: 'Daily Delivery',
          description:
            'Benefit from daily deliveries to keep your ingredients fresh and your inventory lean. Maintain high standards in your kitchen with reliable supply chains.',
        },
        {
          title: 'No Order Minimums',
          description:
            'Order exactly what you need without worrying about supplier minimums. Flexibility in procurement to match your restaurant’s unique requirements.',
        },
        {
          title: 'Ala Carte Add-ons',
          description:
            'Customize your procurement with specific features tailored to your needs. Choose the add-ons that best support your restaurant’s operations.',
        },
      ],
    },
  ];

  return (
    <Box>
      {/* Main Content */}
      <Container maxWidth="lg" sx={{ display: 'flex', mt: 4 }}>
        {/* Left Navigation */}
        {!isSmallScreen && (
          <Box
            sx={{
              width: '250px',
              mr: 4,
              position: 'sticky',
              top: '100px',
              alignSelf: 'flex-start',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: theme.typography.fontWeightMedium,
                color: ACCENT_COLOR,
              }}
            >
              Features
            </Typography>
            <List component="nav">
              {featureCategories.map((category) => (
                <ListItem button key={category.id}>
                  <a
                    href={`#${category.id}`}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      width: '100%',
                      display: 'block',
                    }}
                  >
                    <ListItemText primary={category.title} />
                  </a>
                </ListItem>
              ))}
            </List>
          </Box>
        )}

        {/* Feature Sections */}
        <Box sx={{ flexGrow: 1 }}>
          {/* Hero Section */}
          <Box sx={{ mb: 6, textAlign: 'center' }}>
            <Typography
              variant="h3"
              component="h1"
              sx={{
                color: theme.palette.text.primary,
                mb: 2,
                fontWeight: theme.typography.fontWeightMedium,
              }}
            >
              Streamline Your Restaurant's Procurement
            </Typography>
            <Typography
              variant="h6"
              component="p"
              sx={{
                color: theme.palette.text.secondary,
                mb: 4,
              }}
            >
              Are high food costs and complicated supplier management affecting your restaurant's profitability? Fare.food is here to simplify your procurement process, helping you reduce costs and manage multiple suppliers effortlessly.
            </Typography>
            <CTAButton onClick={() => navigate('/get-started')}>
              See How It Works
            </CTAButton>
          </Box>

          {/* Feature Categories */}
          {featureCategories.map((category, index) => (
            <Box
              key={category.id}
              id={category.id}
              sx={{ mb: 6, scrollMarginTop: '100px' }} // Added scrollMarginTop for alignment
            >
              <Typography
                variant="h4"
                sx={{
                  color: ACCENT_COLOR,
                  mb: 2,
                  fontWeight: theme.typography.fontWeightMedium,
                }}
              >
                {category.title}
              </Typography>
              <Grid container spacing={4}>
                {category.features.map((feature, idx) => (
                  <Grid item xs={12} md={6} key={idx}>
                    <Box sx={{ mb: 4 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.text.primary,
                          mb: 1,
                          fontWeight: theme.typography.fontWeightMedium,
                        }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: theme.palette.text.secondary,
                        }}
                      >
                        {feature.description}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              {index < featureCategories.length - 1 && <Divider sx={{ my: 4 }} />}
            </Box>
          ))}
        </Box>
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Features;
