// src/components/ProductCard.js

import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import AddToCartButton from './AddToCartButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';

// Function to calculate Price Per Unit
const calculatePricePerUnit = (product, multiplier) => {
  const { purchase_price, product_volume, priced_by } = product || {};
  const adjusted_purchase_price = purchase_price * multiplier;

  if (
    adjusted_purchase_price &&
    product_volume > 0 &&
    priced_by &&
    !isNaN(adjusted_purchase_price)
  ) {
    const pricePerUnitValue = adjusted_purchase_price / product_volume;
    return {
      pricePerUnit: `$${pricePerUnitValue.toFixed(2)}/${priced_by}`,
      unitPriceValue: pricePerUnitValue,
    };
  } else {
    return null;
  }
};

// Function to render Purchase Price and Price Per Unit with Icons
const renderPurchasePrice = (product, multiplier, theme, isMobile) => {
  const purchasePrice = Number(product?.purchase_price);

  if (isNaN(purchasePrice)) {
    return null;
  }

  if (purchasePrice > 0) {
    const priceData = calculatePricePerUnit(product, multiplier);

    if (!priceData) return null;

    const { pricePerUnit, unitPriceValue } = priceData;

    // Check if the unit price is suspiciously low
    const isPriceTooLow = unitPriceValue < 0.01;

    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginTop: theme.spacing(0.2), // Further reduced margin-top
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '0.75rem', // Further reduced from '0.875rem'
            fontWeight: theme.typography.h6.fontWeight,
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {`$${(purchasePrice * multiplier).toFixed(2)}`}
          {pricePerUnit && (
            <Typography
              component="span"
              sx={{
                fontSize: '0.65rem', // Further reduced from '0.75rem'
                color: theme.palette.text.secondary,
                marginLeft: theme.spacing(0.2), // Further reduced margin
              }}
            >
              ({pricePerUnit})
            </Typography>
          )}
        </Typography>
        {/* Icons Section */}
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          {isMobile ? (
            // On Mobile: Show only the Warning Icon if applicable
            isPriceTooLow && (
              <Tooltip
                title="This price seems unusually low and might be per unit rather than per case. The final price will be confirmed before purchase."
                placement="top"
              >
                <WarningAmberIcon
                  sx={{
                    color: theme.palette.warning.main,
                    marginLeft: theme.spacing(0.2), // Further reduced margin
                    fontSize: '0.8rem', // Further reduced icon size
                  }}
                />
              </Tooltip>
            )
          ) : (
            // On Desktop: Show all applicable icons
            <>
              {/* Price Warning Icon */}
              {isPriceTooLow && (
                <Tooltip
                  title="This price seems unusually low and might be per unit rather than per case. The final price will be confirmed before purchase."
                  placement="top"
                >
                  <WarningAmberIcon
                    sx={{
                      color: theme.palette.warning.main,
                      marginLeft: theme.spacing(0.2), // Further reduced margin
                      fontSize: '0.8rem', // Further reduced icon size
                    }}
                  />
                </Tooltip>
              )}
              {/* Lead Time Icon */}
              {product.Lead_Time > 0 && (
                <Tooltip title={`${product.Lead_Time} days Lead Time`} placement="top">
                  <AccessTimeIcon
                    sx={{
                      color: theme.palette.info.main,
                      marginLeft: theme.spacing(0.2), // Further reduced margin
                      fontSize: '0.8rem', // Further reduced icon size
                    }}
                  />
                </Tooltip>
              )}
              {/* Special Notes Icon */}
              {product.special_notes && !/no/i.test(product.special_notes) && (
                <Tooltip title={product.special_notes} placement="top">
                  <DescriptionIcon
                    sx={{
                      color: theme.palette.text.secondary,
                      marginLeft: theme.spacing(0.2), // Further reduced margin
                      fontSize: '0.8rem', // Further reduced icon size
                    }}
                  />
                </Tooltip>
              )}
            </>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Typography
        variant="h6"
        sx={{
          fontSize: '0.75rem', // Further reduced from '0.875rem'
          fontWeight: theme.typography.h6.fontWeight,
          fontFamily: theme.typography.fontFamily,
        }}
      >
        Market
      </Typography>
    );
  }
};

function ProductCard({
  product,
  item,
  multiplier,
  showDelete = false,
  onDelete,
  listItemId,
  onClick,
  showDetails = false,
  hasProductId = true,
  isInventory = false,
}) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Function to truncate text
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  // Handle initial delete icon click
  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent card's onClick
    setConfirmDelete(true);
  };

  // Handle confirm delete
  const handleConfirmDelete = (e) => {
    e.stopPropagation(); // Prevent card's onClick
    if (onDelete && listItemId) {
      onDelete(listItemId);
    }
    setConfirmDelete(false);
  };

  // Handle cancel delete
  const handleCancelDelete = (e) => {
    e.stopPropagation(); // Prevent card's onClick
    setConfirmDelete(false);
  };

  // Default onClick behavior if onClick prop is not provided
  const handleCardClick = (e) => {
    if (onClick) {
      onClick(e);
    } else {
      // Navigate to product details page
      if (hasProductId && product?.id) {
        navigate(`/product/${product.id}`);
      } else if (item && item.id) {
        // Handle navigation for items without product_id if necessary
      }
    }
  };

  // Destructure In Stock and PAR values
  const inStock = item?.On_Hand;
  const par = item?.PAR;

  return (
    <Card
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        position: 'relative',
        transition: 'box-shadow 0.2s',
        backgroundColor:
          isInventory && inStock <= par
            ? alpha(theme.palette.error.main, 0.2)
            : 'transparent',
        '&:hover': {
          boxShadow: theme.shadows[4],
          backgroundColor:
            isInventory && inStock <= par
              ? alpha(theme.palette.error.main, 0.2)
              : alpha(theme.palette.background.paper, 0.1),
        },
        height: '250px', // Adjusted fixed card height
      }}
      onClick={handleCardClick}
    >
      <Box sx={{ position: 'relative' }}>
        {/* Conditionally render image if product_id exists */}
        {hasProductId && product?.Image_URL && (
          <CardMedia
            component="img"
            height="80" // Further reduced image height from 100
            image={product.Image_URL}
            alt={product.short_description}
            loading="lazy"
            sx={{
              objectFit: 'contain',
            }}
          />
        )}

        {/* FF_Catalog_Number at the bottom left corner of the image */}
        {product?.FF_Catalog_Number && (
          <Box
            sx={{
              position: 'absolute',
              bottom: theme.spacing(0.5), // Further reduced bottom spacing
              left: theme.spacing(0.5), // Further reduced left spacing
              backgroundColor: 'white',
              padding: theme.spacing(0.125, 0.25), // Further reduced padding
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <Typography
              variant="caption"
              sx={{ color: theme.palette.grey[600], fontSize: '0.6rem' }} // Further reduced font size
            >
              {product.FF_Catalog_Number}
            </Typography>
          </Box>
        )}

        {/* Vendor Logo Positioned at Upper Right of Image */}
        {hasProductId && product?._vendor?.Logo_URL && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(0.5), // Further reduced top spacing
              right: theme.spacing(0.5), // Further reduced right spacing
              width: 20, // Further reduced logo size from 25 to 20
              height: 20,
              borderRadius: '50%',
              backgroundColor: theme.palette.background.paper,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: theme.shadows[1],
            }}
          >
            <img
              src={product._vendor.Logo_URL}
              alt={product._vendor.Short_Name}
              style={{
                width: '70%', // Further reduced logo image size
                height: '70%',
                objectFit: 'contain',
                borderRadius: '50%',
              }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/path/to/default/logo.png';
              }}
            />
          </Box>
        )}

        {/* Conditionally Render Delete Icon or Confirmation Buttons */}
        {showDelete && onDelete && (
          <Box
            sx={{
              position: 'absolute',
              top: theme.spacing(0.5), // Further reduced top spacing
              left: theme.spacing(0.5), // Further reduced left spacing
            }}
          >
            {confirmDelete ? (
              <Box sx={{ display: 'flex', gap: theme.spacing(0.25) }}> {/* Further reduced gap */}
                <IconButton
                  onClick={handleConfirmDelete}
                  sx={{ color: theme.palette.success.main, padding: '2px' }} // Further reduced padding
                  aria-label="confirm delete"
                  size="small"
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={handleCancelDelete}
                  sx={{ color: theme.palette.grey[500], padding: '2px' }} // Further reduced padding
                  aria-label="cancel delete"
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : (
              <IconButton
                onClick={handleDeleteClick}
                sx={{ color: theme.palette.grey[500], padding: '2px' }} // Further reduced padding
                aria-label="delete product"
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        )}
      </Box>

      {/* Card Content Rendering */}
      {((hasProductId && product) || (!hasProductId && item)) && (
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            padding: theme.spacing(0.25), // Further reduced padding from 0.5 to 0.25
          }}
        >
          {hasProductId && product && (
            <>
              {/* Brand and Product Description */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                {product.brand && (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: theme.palette.text.secondary,
                      fontWeight: theme.typography.subtitle1.fontWeight,
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '0.6rem', // Further reduced from '0.65rem'
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      marginBottom: theme.spacing(0.1), // Further reduced margin
                    }}
                  >
                    {product.brand}
                  </Typography>
                )}

                {/* Truncated Product Description */}
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 'normal',
                    color: 'black',
                    fontSize: '0.7rem', // Further reduced from '0.8rem'
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    marginBottom: 0, // Removed margin
                  }}
                >
                  {product.short_description || ''}
                </Typography>

                {/* In Stock and PAR Values Positioned Below Description */}
                {isInventory && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: theme.spacing(0.1), // Further reduced margin
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        color: theme.palette.text.secondary,
                        fontSize: '0.6rem', // Further reduced from '0.65rem'
                      }}
                    >
                      In Stock: {inStock !== undefined ? inStock : 'N/A'}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        color: theme.palette.text.secondary,
                        fontSize: '0.6rem', // Further reduced from '0.65rem'
                      }}
                    >
                      PAR: {par !== undefined ? par : 'N/A'}
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Price and Icons */}
              {renderPurchasePrice(product, multiplier, theme, isMobile)}

              {/* Add to Cart Button */}
              {hasProductId && product?.purchase_price > 0 && (
                <Box
                  sx={{ marginTop: theme.spacing(0.1) }} // Further reduced margin from 0.25
                  onClick={(e) => e.stopPropagation()} // Prevent card's onClick
                >
                  <AddToCartButton product={product} multiplier={multiplier} />
                </Box>
              )}
            </>
          )}

          {!hasProductId && item && (
            <>
              {/* Truncated Item Description */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '0.7rem', // Further reduced from '0.8rem'
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    marginBottom: 0, // Removed margin
                  }}
                >
                  {item.item_description || 'No Description'}
                </Typography>
              </Box>

              {/* In Stock and PAR Values */}
              {isInventory && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: theme.spacing(0.1), // Further reduced margin
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: theme.palette.text.secondary,
                      fontSize: '0.6rem', // Further reduced from '0.65rem'
                    }}
                  >
                    In Stock: {inStock !== undefined ? inStock : 'N/A'}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: theme.palette.text.secondary,
                      fontSize: '0.6rem', // Further reduced from '0.65rem'
                    }}
                  >
                    PAR: {par !== undefined ? par : 'N/A'}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </CardContent>
      )}
    </Card>
  );
}

export default ProductCard;
