// src/components/Footer.js

import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import FFLogo from './assets/FF-Logo-Icon-NT-White-NB.webp'; // Ensure the path is correct
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material'; // Social media icons

// Styled Components
const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#3A3F3F',
  color: '#E9EDEC',
  paddingTop: theme.spacing(9),
  paddingBottom: theme.spacing(6),
  paddingLeft: theme.spacing(12),
  paddingRight: theme.spacing(12),
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}));

const FooterTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: 400,
  marginBottom: theme.spacing(1),
  color: '#0E9C9B',
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: 'none',
  ...theme.typography.body1,
  display: 'block',
  marginBottom: theme.spacing(1),
  '&:hover': {
    color: '#0E9C9B',
    textDecoration: 'none',
  },
}));

function Footer() {
  const theme = useTheme();

  return (
    <FooterContainer>
      <Grid container spacing={4} justifyContent="center">
        {/* Logo and Social Icons */}
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          container
          direction="column"
          alignItems="center"
        >
          <Box
            component="img"
            src={FFLogo}
            alt="FareFood Logo"
            sx={{ maxWidth: '200px', marginBottom: theme.spacing(2) }}
          />
          {/* Social Icons Section */}
          <Box mt={1} display="flex" justifyContent="center" gap={2}>
            <FooterLink href="https://www.facebook.com/farefoodinc" target="_blank" rel="noopener noreferrer">
              <Facebook />
            </FooterLink>
            <FooterLink href="https://instagram.com/farefoodinc" target="_blank" rel="noopener noreferrer">
              <Instagram />
            </FooterLink>
            <FooterLink href="https://www.linkedin.com/company/farefood" target="_blank" rel="noopener noreferrer">
              <LinkedIn />
            </FooterLink>
          </Box>
        </Grid>

        {/* Quick Links Section with Two Columns */}
        <Grid item xs={12} sm={6} md={4}>
          <Grid container spacing={2}>
            {/* Restaurants Column */}
            <Grid item xs={12} sm={6}>
              <FooterTitle>Restaurants</FooterTitle>
              <FooterLink href="/login">Log In</FooterLink>
            </Grid>

            {/* Resources Column */}
            <Grid item xs={12} sm={6}>
              <FooterTitle>Resources</FooterTitle>
              <FooterLink href="/calculator">Savings Calculator</FooterLink>
              <FooterLink href="/demo">Demo</FooterLink>
              <FooterLink href="/pricing">Pricing</FooterLink>
              <FooterLink href="/blogs/food-for-thought">Blog</FooterLink>
              <FooterLink href="/press">Press</FooterLink>
              <FooterLink href="/contactus">Contact Us</FooterLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Bottom Footer with Privacy Policy and Terms & Conditions */}
      <Box mt={4} textAlign="center">
        <Typography variant="body2" color="#E9EDEC">
          &copy; 2024 FareFood. All Rights Reserved. |{' '}
          <Link href="/terms-and-conditions" color="inherit" underline="hover" sx={{ fontFamily: 'Poppins, sans-serif' }}>
            Terms & Conditions
          </Link>{' '}
          |{' '}
          <Link href="/privacy-policy" color="inherit" underline="hover" sx={{ fontFamily: 'Poppins, sans-serif' }}>
            Privacy Policy
          </Link>
        </Typography>
      </Box>
    </FooterContainer>
  );
}

export default Footer;
