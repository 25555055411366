// src/components/Calculator.js

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Slider,
  TextField,
  Button,
  useMediaQuery,
  Container,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SalesNavBar from './SalesNavBar';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';

// Import the Poppins font family
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';

// Custom NumericFormat component
function NumberFormatCustom(props) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumericFormat
      {...other}
      name={name}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value, // Numeric value
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

const Calculator = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [monthlySpend, setMonthlySpend] = useState('');
  const [supplierCount, setSupplierCount] = useState(1);
  const [weeklyOrders, setWeeklyOrders] = useState(1);
  const [savings, setSavings] = useState(null);

  const supplierMarks = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6+' },
  ];

  const orderMarks = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
  ];

  const handleCalculate = () => {
    // Parse monthlySpend to a number
    const spend = parseFloat(monthlySpend.replace(/[^0-9.-]+/g, ''));
    if (isNaN(spend)) {
      alert('Please enter a valid monthly food spend.');
      return;
    }

    // Labor savings calculation
    const supplierNum = supplierCount >= 6 ? 6 : supplierCount;
    const laborSavings = (supplierNum - 1) * weeklyOrders * 40 * 52;

    // Food savings calculation
    let annualSpend = spend * 12;
    let savingsPercentage = 0;

    if (annualSpend < 10000 * 12) savingsPercentage = 0.05;
    else if (annualSpend < 20000 * 12) savingsPercentage = 0.065;
    else if (annualSpend < 30000 * 12) savingsPercentage = 0.08;
    else if (annualSpend < 40000 * 12) savingsPercentage = 0.1;
    else if (annualSpend < 50000 * 12) savingsPercentage = 0.125;
    else if (annualSpend < 75000 * 12) savingsPercentage = 0.15;
    else if (annualSpend < 100000 * 12) savingsPercentage = 0.175;
    else savingsPercentage = 0.2;

    let supplierMultiplier = 1;
    switch (supplierNum) {
      case 1:
        supplierMultiplier = 1.094;
        break;
      case 2:
        supplierMultiplier = 1.0;
        break;
      case 3:
        supplierMultiplier = 0.922;
        break;
      case 4:
        supplierMultiplier = 0.844;
        break;
      case 5:
        supplierMultiplier = 0.797;
        break;
      case 6:
        supplierMultiplier = 0.781;
        break;
      default:
        supplierMultiplier = 1.0;
        break;
    }

    let orderMultiplier = 1;
    if (weeklyOrders >= 1 && weeklyOrders <= 3) orderMultiplier = 1.0;
    else if (weeklyOrders === 4) orderMultiplier = 0.8;
    else if (weeklyOrders === 5) orderMultiplier = 0.6;
    else if (weeklyOrders === 6) orderMultiplier = 0.4;

    const foodSavings =
      annualSpend *
      savingsPercentage *
      supplierMultiplier *
      orderMultiplier;

    // Total savings
    let totalSavings = laborSavings + foodSavings;

    // Subtract $3,289 from the total savings
    totalSavings -= 3289;

    // Ensure total savings is not negative
    if (totalSavings < 0) totalSavings = 0;

    setSavings(totalSavings);
  };

  return (
    <Box>
      {/* Helmet for SEO */}
      <Helmet>
        <title>FareFood | Savings Calculator</title>
        <meta
          name="description"
          content="Estimate how much your restaurant can save with FareFood's Savings Calculator."
        />
        {/* ... other meta tags ... */}
      </Helmet>

      {/* Header */}
      <SalesNavBar />

      {/* Main Content */}
      <Box sx={{ backgroundColor: '#FFFFFF' }}>
        <Container maxWidth="md" sx={{ paddingTop: 12, paddingBottom: 8 }}>
          {/* Header */}
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '2.019rem',
              fontWeight: 500,
              color: '#131616',
              marginBottom: 2,
            }}
          >
            Savings Calculator
          </Typography>
          <Typography
            align="center"
            gutterBottom
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: '1.2rem',
              color: '#555555',
              marginBottom: 4,
              fontWeight: 400,
            }}
          >
            Estimate how much you can save with FareFood.
          </Typography>

          {/* Calculator Form */}
          <Box
            sx={{
              maxWidth: '600px',
              margin: '0 auto',
              mt: 4,
              p: 2,
              backgroundColor: '#FFFFFF',
              borderRadius: '8px',
            }}
          >
            {/* Monthly Food Spend */}
            <Typography gutterBottom sx={{ fontSize: '1rem', fontWeight: 500 }}>
              Monthly Food Spend
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={monthlySpend}
              onChange={(e) => setMonthlySpend(e.target.value)}
              name="monthlySpend"
              sx={{ mb: 4 }}
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />

            {/* Current Supplier Count */}
            <Typography gutterBottom sx={{ fontSize: '1rem', fontWeight: 500 }}>
              Current Supplier Count
            </Typography>
            <Slider
              value={supplierCount}
              onChange={(e, val) => setSupplierCount(val)}
              step={1}
              min={1}
              max={6}
              marks={supplierMarks}
              valueLabelDisplay="off"
              sx={{ mb: 4 }}
            />

            {/* Weekly Orders */}
            <Typography gutterBottom sx={{ fontSize: '1rem', fontWeight: 500 }}>
              Weekly Orders
            </Typography>
            <Slider
              value={weeklyOrders}
              onChange={(e, val) => setWeeklyOrders(val)}
              step={1}
              min={1}
              max={6}
              marks={orderMarks}
              valueLabelDisplay="off"
              sx={{ mb: 4 }}
            />

            {/* Calculate Button */}
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCalculate}
              sx={{
                backgroundColor: '#0E9C9B',
                '&:hover': { backgroundColor: '#0C8B8A' },
                mt: 3,
                fontSize: '1.125rem',
                fontWeight: 'normal',
              }}
            >
              Calculate Savings
            </Button>

            {/* Savings Result */}
            {savings !== null && (
              <Box
                sx={{
                  mt: 4,
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'left',
                    color: '#131616',
                    flexGrow: 1,
                  }}
                >
                  Estimated Annual Savings:{' '}
                  <span style={{ color: '#555555' }}>
                    $
                    {savings.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    *
                  </span>
                </Typography>
                {/* Try for Free Button */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/signup')}
                  sx={{
                    backgroundColor: '#0E9C9B',
                    '&:hover': { backgroundColor: '#0C8B8A' },
                    marginLeft: 2,
                    fontSize: '1rem',
                    padding: '6px 20px',
                    whiteSpace: 'nowrap',
                    mt: isMobile ? 2 : 0,
                  }}
                >
                  Try for free
                </Button>

                {/* Asterisk Note */}
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: 2,
                    color: '#555555',
                  }}
                >
                  *Based on current FareFood customers and their monthly spend,
                  supplier count, and weekly orders before joining.
                </Typography>
              </Box>
            )}
          </Box>
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Calculator;
