// src/components/StyledButton.js
import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none', // Proper case
  borderRadius: '50px', // Fully rounded pills
  fontFamily: 'Gotham Medium, Arial, sans-serif', // Use custom font
  fontWeight: 500,
  // Additional common styles can be added here
}));

export default StyledButton;
