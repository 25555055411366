// src/Lists.js

import React, { useState, useEffect, useMemo } from 'react';
import TopNavBar from './TopNavBar';
import {
  Box,
  Typography,
  List as MUIList,
  CircularProgress,
  Alert,
  Button,
  Container,
  Grid,
  Divider,
  ListItemButton,
  Collapse,
  IconButton,
  Dialog,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  ListItemText,
  Drawer,
  Card,
  CardContent,
  CardMedia,
  InputBase,
} from '@mui/material';
import { useMediaQuery, useTheme, styled, alpha } from '@mui/material';
import {
  Search as SearchIcon,
  ExpandLess,
  ExpandMore,
  Add as AddIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddToCartButton from './AddToCartButton'; // Ensure this component handles event.stopPropagation()
import ProductCard from './ProductCard'; // Import the ProductCard component

// Styled Components for Search Bar (Same as in Cart.js)
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5, // Adjusted border radius
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 500,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none', // Prevents the icon from intercepting clicks
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function Lists() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  // State variables
  const [fareFoodLists, setFareFoodLists] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [listItems, setListItems] = useState([]);
  const [loadingListItems, setLoadingListItems] = useState(false);
  const [errorListItems, setErrorListItems] = useState(null);

  const [purchaseHistoryItems, setPurchaseHistoryItems] = useState([]);
  const [loadingPurchaseHistory, setLoadingPurchaseHistory] = useState(false);
  const [errorPurchaseHistory, setErrorPurchaseHistory] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');

  const [loadingLists, setLoadingLists] = useState(true);
  const [errorLists, setErrorLists] = useState(null);

  // Pagination state for List Items
  const [listItemsOffset, setListItemsOffset] = useState(0);
  const listItemsLimit = 20; // Number of items to fetch per request
  const [listItemsHasMore, setListItemsHasMore] = useState(true);

  // Pagination state for Purchase History
  const [purchaseHistoryOffset, setPurchaseHistoryOffset] = useState(0);
  const purchaseHistoryLimit = 20; // Number of items to fetch per request
  const [purchaseHistoryHasMore, setPurchaseHistoryHasMore] = useState(true);

  // Initialize activeSection to 'purchaseHistory' to show it by default
  const [activeSection, setActiveSection] = useState('purchaseHistory'); // 'purchaseHistory' or listId

  // Initialize dropdowns to be collapsed by default
  const [fareFoodListsOpen, setFareFoodListsOpen] = useState(false);
  const [userListsOpen, setUserListsOpen] = useState(false);

  // Modal state for Create a List
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [newListName, setNewListName] = useState('');
  const [creatingList, setCreatingList] = useState(false);
  const [createListError, setCreateListError] = useState(null);

  // Snackbar state for toast messages
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error' or 'info'

  // State for delete confirmation of list items
  const [deletingItemId, setDeletingItemId] = useState(null);
  const [deleteItemLoading, setDeleteItemLoading] = useState(false);

  // State for delete confirmation of entire lists
  const [deletingListId, setDeletingListId] = useState(null);
  const [deleteListLoading, setDeleteListLoading] = useState(false);

  // State for multiplier
  const [multiplier, setMultiplier] = useState(1);

  // State for userRestaurantId and userId
  const [userRestaurantId, setUserRestaurantId] = useState(null);
  const [userId, setUserId] = useState(null);

  // State for Filter Drawer
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  // **State for View Mode**
  const [viewMode, setViewMode] = useState('card'); // 'card' or 'list'

  // Toggle function for Filter Drawer
  const toggleFilterDrawer = (open) => () => {
    setIsFilterDrawerOpen(open);
  };

  // Fetch user data and multiplier on component mount
  useEffect(() => {
    const fetchUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          setUserRestaurantId(parsedUser.restaurant_id);
          setUserId(parsedUser.id);

          // Retrieve the multiplier from parsedUser
          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);
          console.log('Multiplier retrieved from localStorage:', storedMultiplier);
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setErrorLists('Invalid user data. Please log in again.');
          setLoadingLists(false);
        }
      } else {
        setErrorLists('User not found. Please log in.');
        setLoadingLists(false);
      }
    };

    fetchUserData();
  }, []);

  // Fetch lists when userRestaurantId is available
  useEffect(() => {
    if (!userRestaurantId) return;

    const fetchLists = async () => {
      try {
        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
          {
            params: {
              restaurantidfilter: userRestaurantId,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        let allLists = [];
        if (Array.isArray(response.data)) {
          allLists = response.data;
        } else if (response.data && Array.isArray(response.data.lists)) {
          allLists = response.data.lists;
        } else {
          console.warn('Unexpected API response structure:', response.data);
          setErrorLists('Unexpected response structure from the server.');
          allLists = [];
        }

        // Separate FareFood-generated lists and user-generated lists
        const fareFood = [];
        const userGenerated = [];
        allLists.forEach((list) => {
          if (
            list.list_name.toLowerCase().includes('order guide') ||
            list.list_name.toLowerCase().includes('deals')
          ) {
            fareFood.push(list);
          } else {
            userGenerated.push(list);
          }
        });

        setFareFoodLists(fareFood);
        setUserLists(userGenerated);

        setLoadingLists(false);
      } catch (err) {
        console.error('Error fetching lists:', err);
        if (err.response && err.response.data && err.response.data.error) {
          setErrorLists(err.response.data.error);
        } else if (err.message) {
          setErrorLists(err.message);
        } else {
          setErrorLists('Failed to fetch lists. Please try again later.');
        }
        setLoadingLists(false);
      }
    };

    fetchLists();
  }, [userRestaurantId]);

  // Debounce the search term to prevent excessive API calls
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500); // 500ms debounce delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Fetch list items when a list is selected or when debounced search term changes
  useEffect(() => {
    if (activeSection && activeSection !== 'purchaseHistory') {
      // Reset list items state when a new list is selected
      setListItems([]);
      setListItemsOffset(0);
      setListItemsHasMore(true);
      fetchListItems(activeSection, 0, debouncedSearchTerm, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection, debouncedSearchTerm]);

  const fetchListItems = async (listId, offset, search, reset = false) => {
    if (!listId) return;

    setLoadingListItems(true);
    setErrorListItems(null);

    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_of_items_react',
        {
          params: {
            lists_id: listId,
            productnamefilter: search,
            limit: listItemsLimit,
            offset: offset,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = response.data;

      if (data && Array.isArray(data.items)) {
        setListItems((prevItems) => (reset ? data.items : [...prevItems, ...data.items]));
        setListItemsOffset(offset + data.items.length);
        setListItemsHasMore(data.items.length === listItemsLimit);
      } else {
        console.warn(`Unexpected response structure for list ${listId}:`, data);
        setErrorListItems('Unexpected response structure from the server.');
        setListItemsHasMore(false);
      }
    } catch (err) {
      console.error(`Error fetching items for list ${activeSection}:`, err);
      if (err.response && err.response.data && err.response.data.error) {
        setErrorListItems(err.response.data.error);
      } else if (err.message) {
        setErrorListItems(err.message);
      } else {
        setErrorListItems('Failed to fetch items. Please try again later.');
      }
      setListItemsHasMore(false);
    } finally {
      setLoadingListItems(false);
    }
  };

  // Fetch purchase history when 'Purchase History' is selected or when debounced search term changes
  useEffect(() => {
    if (activeSection === 'purchaseHistory') {
      // Reset purchase history state when selected
      setPurchaseHistoryItems([]);
      setPurchaseHistoryOffset(0);
      setPurchaseHistoryHasMore(true);
      fetchPurchaseHistory(userId, 0, debouncedSearchTerm, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection, debouncedSearchTerm, userId]);

  const fetchPurchaseHistory = async (userid, offset, search, reset = false) => {
    if (!userid) {
      setErrorPurchaseHistory('User not authenticated. Please log in to view your purchase history.');
      console.error('Error: User ID not found');
      return;
    }

    setLoadingPurchaseHistory(true);
    setErrorPurchaseHistory(null);

    try {
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/purchase_history_items_react',
        {
          params: {
            useridfilter: userid,
            limit: purchaseHistoryLimit,
            offset: offset,
            ...(search.trim() !== '' && { productnamefilter: search.trim() }),
          },
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = response.data;

      let fetchedItems = [];
      if (Array.isArray(data.items)) {
        fetchedItems = data.items;
      } else if (data.items && Array.isArray(data.items)) {
        fetchedItems = data.items;
      } else {
        console.warn('Unexpected API response structure for purchase history:', data);
        setErrorPurchaseHistory('Unexpected response structure from the server.');
        fetchedItems = [];
      }

      if (Array.isArray(fetchedItems) && fetchedItems.length > 0) {
        setPurchaseHistoryItems((prevItems) => (reset ? fetchedItems : [...prevItems, ...fetchedItems]));
        setPurchaseHistoryOffset(offset + fetchedItems.length);
        setPurchaseHistoryHasMore(fetchedItems.length === purchaseHistoryLimit);
      } else {
        setPurchaseHistoryHasMore(false);
      }
    } catch (err) {
      console.error('Error fetching purchase history:', err);
      setErrorPurchaseHistory('Failed to load purchase history. Please try again later.');
      setPurchaseHistoryHasMore(false);
    } finally {
      setLoadingPurchaseHistory(false);
    }
  };

  const handleListClick = (listId) => {
    setActiveSection(listId);
  };

  const handlePurchaseHistoryClick = () => {
    setActiveSection('purchaseHistory');
  };

  const handleSearch = () => {
    // The debouncedSearchTerm state will trigger useEffect hooks to refetch data
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  // Toggle functions for dropdowns
  const handleFareFoodListsToggle = () => {
    setFareFoodListsOpen(!fareFoodListsOpen);
  };

  const handleUserListsToggle = () => {
    setUserListsOpen(!userListsOpen);
  };

  // **Handle View Mode Change**
  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  // Load more handlers
  const loadMoreListItems = () => {
    if (activeSection && activeSection !== 'purchaseHistory') {
      fetchListItems(activeSection, listItemsOffset, debouncedSearchTerm);
    }
  };

  const loadMorePurchaseHistory = () => {
    if (activeSection === 'purchaseHistory') {
      fetchPurchaseHistory(userId, purchaseHistoryOffset, debouncedSearchTerm);
    }
  };

  // Determine if Guides or Lists headers should be highlighted
  const isGuidesActive = fareFoodLists.some((list) => list.id === activeSection);
  const isUserListsActive = userLists.some((list) => list.id === activeSection);

  // Retrieve the active list's name
  const activeList = [...fareFoodLists, ...userLists].find((list) => list.id === activeSection);
  const activeListName =
    activeSection === 'purchaseHistory'
      ? 'Purchase History'
      : activeList
      ? activeList.list_name
      : '';

  // Handle Create a List Modal
  const openCreateModal = () => {
    setNewListName('');
    setCreateListError(null);
    setCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };

  const handleCreateList = async () => {
    if (!newListName.trim()) {
      setCreateListError('List name cannot be empty.');
      return;
    }

    setCreatingList(true);
    setCreateListError(null);

    try {
      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          list_name: newListName.trim(),
          restaurant_id: userRestaurantId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Assuming the API returns the created list
      const createdList = response.data;

      // Update the lists state
      if (createdList) {
        // Determine if it's a FareFood list or user-generated
        const isFareFood =
          createdList.list_name.toLowerCase().includes('order guide') ||
          createdList.list_name.toLowerCase().includes('deals');

        if (isFareFood) {
          setFareFoodLists((prev) => [...prev, createdList]);
        } else {
          setUserLists((prev) => [...prev, createdList]);
        }

        // Optionally, set the newly created list as active
        setActiveSection(createdList.id);
      }

      setCreatingList(false);
      setCreateModalOpen(false);
      setSnackbarMessage('List created successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error creating list:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setCreateListError(err.response.data.error);
      } else if (err.message) {
        setCreateListError(err.message);
      } else {
        setCreateListError('Failed to create list. Please try again later.');
      }
      setCreatingList(false);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Handle Add Button Click
  const handleAddButtonClick = () => {
    // Add a Snackbar before navigating
    setSnackbarMessage('Add product to list');
    setSnackbarSeverity('info');
    setSnackbarOpen(true);
    // Navigate to /shop after a short delay to allow the toast to be visible
    setTimeout(() => {
      navigate('/shop');
    }, 2000); // 2 seconds delay
  };

  // Handle Delete Icon Click for List Items
  const handleDeleteItemClick = (itemId) => {
    setDeletingItemId(itemId);
  };

  // Handle Confirm Delete for List Items
  const handleConfirmDeleteItem = async (itemId) => {
    setDeleteItemLoading(true);
    try {
      await axios.delete(`https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item/${itemId}`, {
        data: { list_item_id: itemId },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Remove the deleted item from listItems state
      setListItems((prevItems) => prevItems.filter((item) => item.id !== itemId));

      setSnackbarMessage('Item deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting item:', err);
      let errorMsg = 'Failed to delete item. Please try again later.';
      if (err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error;
      } else if (err.message) {
        errorMsg = err.message;
      }
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeletingItemId(null);
      setDeleteItemLoading(false);
    }
  };

  // Handle Cancel Delete for List Items
  const handleCancelDeleteItem = () => {
    setDeletingItemId(null);
  };

  // Handle Delete Icon Click for Entire Lists
  const handleDeleteListClick = (listId) => {
    setDeletingListId(listId);
  };

  // Handle Confirm Delete for Entire Lists
  const handleConfirmDeleteList = async (listId) => {
    setDeleteListLoading(true);
    try {
      await axios.delete(`https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists/${listId}`, {
        data: { lists_id: listId },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      // Remove the deleted list from fareFoodLists or userLists
      const isFareFood = fareFoodLists.some((list) => list.id === listId);
      if (isFareFood) {
        setFareFoodLists((prev) => prev.filter((list) => list.id !== listId));
      } else {
        setUserLists((prev) => prev.filter((list) => list.id !== listId));
      }

      // If the deleted list was active, reset activeSection
      if (activeSection === listId) {
        setActiveSection('purchaseHistory');
      }

      setSnackbarMessage('List deleted successfully.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting list:', err);
      let errorMsg = 'Failed to delete list. Please try again later.';
      if (err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error;
      } else if (err.message) {
        errorMsg = err.message;
      }
      setSnackbarMessage(errorMsg);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setDeletingListId(null);
      setDeleteListLoading(false);
    }
  };

  // Handle Cancel Delete for Entire Lists
  const handleCancelDeleteList = () => {
    setDeletingListId(null);
  };

  // Filter Out Duplicate product_id in Purchase History
  const uniquePurchaseHistoryItems = useMemo(() => {
    const unique = [];
    const seenProductIds = new Set();
    // Iterate from the end to keep the latest purchase if duplicates exist
    for (let i = purchaseHistoryItems.length - 1; i >= 0; i--) {
      const item = purchaseHistoryItems[i];
      if (!seenProductIds.has(item.product_id)) {
        unique.push(item);
        seenProductIds.add(item.product_id);
      }
    }
    // Reverse to maintain original order
    return unique.reverse();
  }, [purchaseHistoryItems]);

  // Delete Handler for Guides and List Items
  const handleDeleteProduct = (listItemId) => {
    // Determine the source of deletion based on activeSection
    if (activeSection === 'purchaseHistory') {
      // Handle deletion from purchase history
      handleConfirmDeleteItem(listItemId);
    } else {
      // Handle deletion from lists (guides or user lists)
      handleConfirmDeleteItem(listItemId);
    }
  };

  return (
    <div>
      {/* Sticky TopNavBar */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar title="Lists" />
      </Box>

      {/* Main Content */}
      {loadingLists ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(8) }}>
          <CircularProgress />
        </Box>
      ) : errorLists ? (
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            paddingTop: theme.spacing(8), // Added paddingTop to avoid overlap
          }}
        >
          <Alert severity="error">{errorLists}</Alert>
        </Container>
      ) : (
        <Container
          maxWidth="lg"
          sx={{
            paddingTop: theme.spacing(8), // Added paddingTop to avoid overlap
          }}
        >
          {/* Main Grid */}
          <Grid container>
            {/* Left Panel for Desktop */}
            {isDesktop && (
              <Grid
                item
                sm={3}
                md={2}
                sx={{
                  borderRight: `1px solid ${theme.palette.divider}`,
                  minHeight: 'calc(100vh - 64px)', // Adjusted to account for TopNavBar height
                  padding: theme.spacing(2),
                  backgroundColor: theme.palette.background.paper,
                  position: 'sticky',
                  top: theme.spacing(8), // Align with paddingTop of Container
                  overflowY: 'auto',
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    marginBottom: theme.spacing(2),
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    fontFamily: theme.typography.fontFamily,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                  onClick={openCreateModal} // Open modal
                >
                  Create a List
                </Button>

                {/* Wrap Purchase History in ListItemButton */}
                <ListItemButton
                  selected={activeSection === 'purchaseHistory'}
                  onClick={handlePurchaseHistoryClick}
                  sx={{
                    borderRadius: theme.shape.borderRadius,
                    marginBottom: theme.spacing(1),
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: theme.typography.fontFamily,
                          color: activeSection === 'purchaseHistory' ? theme.palette.primary.main : theme.palette.text.primary,
                        }}
                      >
                        Purchase History
                      </Typography>
                    }
                  />
                </ListItemButton>

                <Divider sx={{ marginBottom: theme.spacing(1) }} />

                {/* Rename "FareFood Lists" to "Guides" */}
                <ListItemButton onClick={handleFareFoodListsToggle}>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: theme.typography.fontFamily,
                          color: isGuidesActive ? theme.palette.primary.main : theme.palette.text.primary,
                        }}
                      >
                        Guides
                      </Typography>
                    }
                  />
                  {fareFoodListsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={fareFoodListsOpen} timeout="auto" unmountOnExit>
                  <MUIList component="div" disablePadding>
                    {fareFoodLists.map((list) => (
                      <ListItemButton
                        key={list.id}
                        selected={activeSection === list.id}
                        sx={{
                          pl: 4,
                          borderRadius: theme.shape.borderRadius,
                          marginBottom: theme.spacing(0.5),
                        }}
                        onClick={() => handleListClick(list.id)}
                      >
                        <Grid container alignItems="center" justifyContent="space-between">
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: theme.typography.fontFamily,
                                  color: activeSection === list.id ? theme.palette.primary.main : theme.palette.text.primary,
                                }}
                              >
                                {list.list_name || 'Unnamed List'}
                              </Typography>
                            }
                          />
                          {/* Delete Button for Entire List */}
                          <Box>
                            {deletingListId === list.id ? (
                              <Collapse in={deletingListId === list.id}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent triggering the navigate
                                      handleConfirmDeleteList(list.id);
                                    }}
                                    disabled={deleteListLoading}
                                    sx={{ color: theme.palette.success.main }}
                                    aria-label="confirm delete list"
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent triggering the navigate
                                      handleCancelDeleteList();
                                    }}
                                    disabled={deleteListLoading}
                                    sx={{ color: theme.palette.text.secondary }}
                                    aria-label="cancel delete list"
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              </Collapse>
                            ) : (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering the navigate
                                  handleDeleteListClick(list.id);
                                }}
                                sx={{ color: theme.palette.text.secondary }}
                                aria-label="delete list"
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      </ListItemButton>
                    ))}
                  </MUIList>
                </Collapse>

                {/* Rename "Your Lists" to "Lists" */}
                <ListItemButton onClick={handleUserListsToggle}>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontFamily: theme.typography.fontFamily,
                          color: isUserListsActive ? theme.palette.primary.main : theme.palette.text.primary,
                        }}
                      >
                        Lists
                      </Typography>
                    }
                  />
                  {userListsOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={userListsOpen} timeout="auto" unmountOnExit>
                  <MUIList component="div" disablePadding>
                    {userLists.map((list) => (
                      <ListItemButton
                        key={list.id}
                        selected={activeSection === list.id}
                        sx={{
                          pl: 4,
                          borderRadius: theme.shape.borderRadius,
                          marginBottom: theme.spacing(0.5),
                        }}
                        onClick={() => handleListClick(list.id)}
                      >
                        <Grid container alignItems="center" justifyContent="space-between">
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: theme.typography.fontFamily,
                                  color: activeSection === list.id ? theme.palette.primary.main : theme.palette.text.primary,
                                }}
                              >
                                {list.list_name || 'Unnamed List'}
                              </Typography>
                            }
                          />
                          {/* Delete Button for Entire List */}
                          <Box>
                            {deletingListId === list.id ? (
                              <Collapse in={deletingListId === list.id}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent triggering the navigate
                                      handleConfirmDeleteList(list.id);
                                    }}
                                    disabled={deleteListLoading}
                                    sx={{ color: theme.palette.success.main }}
                                    aria-label="confirm delete list"
                                  >
                                    <CheckIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent triggering the navigate
                                      handleCancelDeleteList();
                                    }}
                                    disabled={deleteListLoading}
                                    sx={{ color: theme.palette.text.secondary }}
                                    aria-label="cancel delete list"
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Box>
                              </Collapse>
                            ) : (
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering the navigate
                                  handleDeleteListClick(list.id);
                                }}
                                sx={{ color: theme.palette.text.secondary }}
                                aria-label="delete list"
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Grid>
                      </ListItemButton>
                    ))}
                  </MUIList>
                </Collapse>
              </Grid>
            )}

            {/* Main Viewing Area */}
            <Grid
              item
              xs={12}
              sm={isDesktop ? 9 : 12}
              md={isDesktop ? 10 : 12}
              sx={{
                padding: theme.spacing(2),
                minHeight: 'calc(100vh - 64px)', // Adjusted to account for TopNavBar height
              }}
            >
              {/* New Header Section with Title and Controls */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  justifyContent: 'space-between',
                  paddingBottom: theme.spacing(2),
                  gap: theme.spacing(2),
                }}
              >
                {/* Title and Controls Row */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: isMobile ? 'space-between' : 'flex-start',
                    flexWrap: 'wrap',
                    gap: theme.spacing(1),
                  }}
                >
                  {/* Title */}
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      flexGrow: isMobile ? 1 : 'unset',
                    }}
                  >
                    {activeListName || 'Select a list or Purchase History to view items.'}
                  </Typography>

                  {/* Controls: Add Button, View Toggle */}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                    }}
                  >
                    {/* Add Button */}
                    {isDesktop ? (
                      <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={handleAddButtonClick}
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                          },
                          fontFamily: theme.typography.fontFamily,
                          textTransform: 'capitalize',
                          borderRadius: '50px',
                        }}
                        aria-label="add product to list"
                      >
                        Add
                      </Button>
                    ) : (
                      <IconButton
                        onClick={handleAddButtonClick}
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.primary.contrastText,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                          },
                        }}
                        aria-label="add product to list"
                      >
                        <AddIcon />
                      </IconButton>
                    )}

                    {/* View Toggle Button */}
                    <ToggleButtonGroup
                      value={viewMode}
                      exclusive
                      onChange={handleViewModeChange}
                      aria-label="view mode"
                      size="small"
                    >
                      <ToggleButton value="list" aria-label="list view">
                        <ViewListIcon />
                      </ToggleButton>
                      <ToggleButton value="card" aria-label="card view">
                        <ViewModuleIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>

                    {/* Filter Icon for Mobile */}
                    {!isDesktop && (
                      <IconButton
                        aria-label="open filter"
                        onClick={toggleFilterDrawer(true)}
                        sx={{
                          color: theme.palette.primary.main,
                          backgroundColor: theme.palette.background.paper,
                          border: `1px solid ${theme.palette.primary.main}`,
                          '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                          },
                        }}
                      >
                        <FilterListIcon />
                      </IconButton>
                    )}
                  </Box>
                </Box>

                {/* Search Bar Positioned Below Controls on Mobile */}
                <Box
                  sx={{
                    width: '100%',
                    display: isMobile ? 'block' : 'flex',
                    justifyContent: isMobile ? 'center' : 'flex-end',
                  }}
                >
                  <Search sx={{ width: isMobile ? '100%' : 'auto' }}>
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Items…"
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyDown={handleSearchKeyDown}
                    />
                  </Search>
                </Box>
              </Box>

              {/* Display Content Based on Active Section */}
              {activeSection === 'purchaseHistory' ? (
                // Display Purchase History Items
                <>
                  {loadingPurchaseHistory && purchaseHistoryOffset === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5) }}>
                      <CircularProgress />
                    </Box>
                  ) : errorPurchaseHistory ? (
                    <Box sx={{ marginTop: theme.spacing(2.5) }}>
                      <Alert severity="error">{errorPurchaseHistory}</Alert>
                    </Box>
                  ) : uniquePurchaseHistoryItems.length > 0 ? (
                    <>
                      {viewMode === 'card' ? (
                        <Grid container spacing={2}>
                          {uniquePurchaseHistoryItems.map((item) => {
                            const product = item._product;

                            if (!product) {
                              console.warn(`Item with id ${item.id} is missing _product.`);
                              return null;
                            }

                            return (
                              <Grid item xs={6} sm={4} md={2} key={item.id}>
                                <ProductCard product={product} multiplier={multiplier} />
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        // List View
                        <Box>
                          {uniquePurchaseHistoryItems.map((item) => {
                            const product = item._product;

                            if (!product) {
                              console.warn(`Item with id ${item.id} is missing _product.`);
                              return null;
                            }

                            return (
                              <Card
                                key={item.id}
                                sx={{
                                  display: 'flex',
                                  marginBottom: theme.spacing(2),
                                  cursor: 'pointer',
                                  position: 'relative',
                                  '&:hover': {
                                    boxShadow: theme.shadows[4],
                                  },
                                }}
                                onClick={() => {
                                  navigate(`/product/${product.id}`);
                                }}
                              >
                                {product.Image_URL ? (
                                  <CardMedia
                                    component="img"
                                    sx={{ width: 120, height: 120, objectFit: 'contain' }}
                                    image={product.Image_URL}
                                    alt={product.short_description}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      width: 120,
                                      height: 120,
                                      backgroundColor: '#f0f0f0',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Typography variant="subtitle1">No Image Available</Typography>
                                  </Box>
                                )}

                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                  <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        color: theme.palette.text.secondary,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {product.brand}
                                    </Typography>

                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        marginBottom: '8px',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {product.short_description}
                                    </Typography>

                                    {/* Price and AddToCartButton */}
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 'auto',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: '1rem',
                                          fontWeight: theme.typography.h6.fontWeight,
                                          fontFamily: theme.typography.fontFamily,
                                          marginRight: theme.spacing(1),
                                        }}
                                      >
                                        {product.purchase_price > 0
                                          ? `$${(product.purchase_price * multiplier).toFixed(2)}`
                                          : 'Contact us'}
                                      </Typography>

                                      {product.purchase_price > 0 && (
                                        <Box
                                          sx={{
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                          onClick={(e) => e.stopPropagation()} // Prevent card's onClick
                                        >
                                          <AddToCartButton product={product} multiplier={multiplier} />
                                        </Box>
                                      )}
                                    </Box>
                                  </CardContent>
                                </Box>

                                {/* Vendor Logo Positioned at Upper Right of Image */}
                                {product._vendor?.Logo_URL && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: theme.spacing(2),
                                      right: theme.spacing(2),
                                      width: 40,
                                      height: 40,
                                      borderRadius: '50%',
                                      backgroundColor: theme.palette.background.paper,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      boxShadow: theme.shadows[1],
                                    }}
                                  >
                                    <img
                                      src={product._vendor.Logo_URL}
                                      alt={product._vendor.Short_Name}
                                      style={{
                                        width: '80%',
                                        height: '80%',
                                        objectFit: 'contain',
                                        borderRadius: '50%',
                                      }}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/path/to/default/logo.png'; // Update with actual default logo path
                                      }}
                                    />
                                  </Box>
                                )}
                              </Card>
                            );
                          })}
                        </Box>
                      )}

                      {/* Load More Button for Purchase History */}
                      {purchaseHistoryHasMore && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5) }}>
                          {loadingPurchaseHistory ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="contained"
                              onClick={loadMorePurchaseHistory}
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                  backgroundColor: theme.palette.primary.dark,
                                },
                              }}
                            >
                              Load More
                            </Button>
                          )}
                        </Box>
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: 'center',
                        marginTop: theme.spacing(2.5),
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '1rem',
                      }}
                    >
                      No purchase history found.
                    </Typography>
                  )}
                </>
              ) : activeSection ? (
                // Display List Items (Guides and Lists)
                <>
                  {loadingListItems && listItemsOffset === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5) }}>
                      <CircularProgress />
                    </Box>
                  ) : errorListItems ? (
                    <Box sx={{ marginTop: theme.spacing(2.5) }}>
                      <Alert severity="error">{errorListItems}</Alert>
                    </Box>
                  ) : listItems.length > 0 ? (
                    <>
                      {viewMode === 'card' ? (
                        <Grid container spacing={2}>
                          {listItems.map((item) => {
                            const product = item._product;

                            if (!product) {
                              console.warn(`Item with id ${item.id} is missing _product.`);
                              return null;
                            }

                            return (
                              <Grid item xs={6} sm={4} md={2} key={item.id}>
                                <ProductCard
                                  product={product}
                                  multiplier={multiplier}
                                  showDelete={true}
                                  onDelete={handleDeleteProduct} // Pass the delete handler
                                  listItemId={item.id} // Pass the list_item_id
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        // List View
                        <Box>
                          {listItems.map((item) => {
                            const product = item._product;

                            if (!product) {
                              console.warn(`Item with id ${item.id} is missing _product.`);
                              return null;
                            }

                            return (
                              <Card
                                key={item.id}
                                sx={{
                                  display: 'flex',
                                  marginBottom: theme.spacing(2),
                                  cursor: 'pointer',
                                  position: 'relative',
                                  '&:hover': {
                                    boxShadow: theme.shadows[4],
                                  },
                                }}
                                onClick={() => {
                                  navigate(`/product/${product.id}`);
                                }}
                              >
                                {product.Image_URL ? (
                                  <CardMedia
                                    component="img"
                                    sx={{ width: 120, height: 120, objectFit: 'contain' }}
                                    image={product.Image_URL}
                                    alt={product.short_description}
                                  />
                                ) : (
                                  <Box
                                    sx={{
                                      width: 120,
                                      height: 120,
                                      backgroundColor: '#f0f0f0',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Typography variant="subtitle1">No Image Available</Typography>
                                  </Box>
                                )}

                                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                  <CardContent sx={{ flex: '1 0 auto' }}>
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        color: theme.palette.text.secondary,
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {product.brand}
                                    </Typography>

                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontFamily: theme.typography.fontFamily,
                                        marginBottom: '8px',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {product.short_description}
                                    </Typography>

                                    {/* Price and AddToCartButton */}
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginTop: 'auto',
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          fontSize: '1rem',
                                          fontWeight: theme.typography.h6.fontWeight,
                                          fontFamily: theme.typography.fontFamily,
                                          marginRight: theme.spacing(1),
                                        }}
                                      >
                                        {product.purchase_price > 0
                                          ? `$${(product.purchase_price * multiplier).toFixed(2)}`
                                          : 'Contact us'}
                                      </Typography>

                                      {product.purchase_price > 0 && (
                                        <Box
                                          sx={{
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                          onClick={(e) => e.stopPropagation()} // Prevent card's onClick
                                        >
                                          <AddToCartButton product={product} multiplier={multiplier} />
                                        </Box>
                                      )}
                                    </Box>
                                  </CardContent>
                                </Box>

                                {/* Vendor Logo Positioned at Upper Right of Image */}
                                {product._vendor?.Logo_URL && (
                                  <Box
                                    sx={{
                                      position: 'absolute',
                                      top: theme.spacing(2),
                                      right: theme.spacing(2),
                                      width: 40,
                                      height: 40,
                                      borderRadius: '50%',
                                      backgroundColor: theme.palette.background.paper,
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      boxShadow: theme.shadows[1],
                                    }}
                                  >
                                    <img
                                      src={product._vendor.Logo_URL}
                                      alt={product._vendor.Short_Name}
                                      style={{
                                        width: '80%',
                                        height: '80%',
                                        objectFit: 'contain',
                                        borderRadius: '50%',
                                      }}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/path/to/default/logo.png'; // Update with actual default logo path
                                      }}
                                    />
                                  </Box>
                                )}

                                {/* Delete Button */}
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteProduct(item.id);
                                  }}
                                  sx={{ position: 'absolute', top: theme.spacing(1), left: theme.spacing(1) }}
                                  aria-label="delete item"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Card>
                            );
                          })}
                        </Box>
                      )}

                      {/* Load More Button for List Items */}
                      {listItemsHasMore && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2.5) }}>
                          {loadingListItems ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              variant="contained"
                              onClick={loadMoreListItems}
                              sx={{
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                  backgroundColor: theme.palette.primary.dark,
                                },
                              }}
                            >
                              Load More
                            </Button>
                          )}
                        </Box>
                      )}
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: 'center',
                        marginTop: theme.spacing(2.5),
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '1rem',
                      }}
                    >
                      No items found in this list.
                    </Typography>
                  )}
                </>
              ) : (
                // Default Content when no section is active
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    marginTop: theme.spacing(2.5),
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '1rem',
                  }}
                >
                  Select a list or Purchase History to view items.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
      )}

      {/* Filter Drawer for Mobile */}
      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={toggleFilterDrawer(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Box
          sx={{
            width: 250,
            padding: theme.spacing(2),
          }}
          role="presentation"
        >
          {/* Close Button */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={toggleFilterDrawer(false)} aria-label="close filter">
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Filter Options */}
          <Typography variant="h5" sx={{ fontFamily: theme.typography.fontFamily, marginBottom: theme.spacing(1) }}>
            Filter Lists
          </Typography>
          <Divider sx={{ marginBottom: theme.spacing(1) }} />
          <MUIList component="nav">
            <ListItemButton
              selected={activeSection === 'purchaseHistory'}
              onClick={() => {
                handlePurchaseHistoryClick();
                toggleFilterDrawer(false)();
              }}
              sx={{
                borderRadius: theme.shape.borderRadius,
                marginBottom: theme.spacing(0.5),
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: activeSection === 'purchaseHistory' ? theme.palette.primary.main : theme.palette.text.primary,
                    }}
                  >
                    Purchase History
                  </Typography>
                }
              />
            </ListItemButton>

            <ListItemButton
              onClick={handleFareFoodListsToggle}
              sx={{ borderRadius: theme.shape.borderRadius, marginBottom: theme.spacing(0.5) }}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: isGuidesActive ? theme.palette.primary.main : theme.palette.text.primary,
                    }}
                  >
                    Guides
                  </Typography>
                }
              />
              {fareFoodListsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={fareFoodListsOpen} timeout="auto" unmountOnExit>
              <MUIList component="div" disablePadding>
                {fareFoodLists.map((list) => (
                  <ListItemButton
                    key={list.id}
                    selected={activeSection === list.id}
                    sx={{
                      pl: 4,
                      borderRadius: theme.shape.borderRadius,
                      marginBottom: theme.spacing(0.5),
                    }}
                    onClick={() => {
                      handleListClick(list.id);
                      toggleFilterDrawer(false)();
                    }}
                  >
                    <Grid container alignItems="center" justifyContent="space-between">
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: activeSection === list.id ? theme.palette.primary.main : theme.palette.text.primary,
                            }}
                          >
                            {list.list_name || 'Unnamed List'}
                          </Typography>
                        }
                      />
                      {/* Delete Button for Entire List */}
                      <Box>
                        {deletingListId === list.id ? (
                          <Collapse in={deletingListId === list.id}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering the navigate
                                  handleConfirmDeleteList(list.id);
                                }}
                                disabled={deleteListLoading}
                                sx={{ color: theme.palette.success.main }}
                                aria-label="confirm delete list"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering the navigate
                                  handleCancelDeleteList();
                                }}
                                disabled={deleteListLoading}
                                sx={{ color: theme.palette.text.secondary }}
                                aria-label="cancel delete list"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          </Collapse>
                        ) : (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the navigate
                              handleDeleteListClick(list.id);
                            }}
                            sx={{ color: theme.palette.text.secondary }}
                            aria-label="delete list"
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </ListItemButton>
                ))}
              </MUIList>
            </Collapse>

            <ListItemButton
              onClick={handleUserListsToggle}
              sx={{ borderRadius: theme.shape.borderRadius, marginBottom: theme.spacing(0.5) }}
            >
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: isUserListsActive ? theme.palette.primary.main : theme.palette.text.primary,
                    }}
                  >
                    Lists
                  </Typography>
                }
              />
              {userListsOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={userListsOpen} timeout="auto" unmountOnExit>
              <MUIList component="div" disablePadding>
                {userLists.map((list) => (
                  <ListItemButton
                    key={list.id}
                    selected={activeSection === list.id}
                    sx={{
                      pl: 4,
                      borderRadius: theme.shape.borderRadius,
                      marginBottom: theme.spacing(0.5),
                    }}
                    onClick={() => {
                      handleListClick(list.id);
                      toggleFilterDrawer(false)();
                    }}
                  >
                    <Grid container alignItems="center" justifyContent="space-between">
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: activeSection === list.id ? theme.palette.primary.main : theme.palette.text.primary,
                            }}
                          >
                            {list.list_name || 'Unnamed List'}
                          </Typography>
                        }
                      />
                      {/* Delete Button for Entire List */}
                      <Box>
                        {deletingListId === list.id ? (
                          <Collapse in={deletingListId === list.id}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering the navigate
                                  handleConfirmDeleteList(list.id);
                                }}
                                disabled={deleteListLoading}
                                sx={{ color: theme.palette.success.main }}
                                aria-label="confirm delete list"
                              >
                                <CheckIcon />
                              </IconButton>
                              <IconButton
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent triggering the navigate
                                  handleCancelDeleteList();
                                }}
                                disabled={deleteListLoading}
                                sx={{ color: theme.palette.text.secondary }}
                                aria-label="cancel delete list"
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          </Collapse>
                        ) : (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the navigate
                              handleDeleteListClick(list.id);
                            }}
                            sx={{ color: theme.palette.text.secondary }}
                            aria-label="delete list"
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </ListItemButton>
                ))}
              </MUIList>
            </Collapse>
          </MUIList>
        </Box>
      </Drawer>

      {/* Create a List Modal */}
      <Dialog open={createModalOpen} onClose={closeCreateModal} fullWidth maxWidth="sm">
        <DialogTitle>Create a New List</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="List Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            disabled={creatingList}
          />
          {createListError && (
            <Alert severity="error" sx={{ marginTop: theme.spacing(1) }}>
              {createListError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCreateModal} disabled={creatingList}>
            Cancel
          </Button>
          <Button
            onClick={handleCreateList}
            variant="contained"
            disabled={creatingList}
            sx={{
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {creatingList ? <CircularProgress size={24} /> : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Toast Messages */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            backgroundColor:
              snackbarSeverity === 'success'
                ? theme.palette.success.main
                : snackbarSeverity === 'error'
                ? theme.palette.error.main
                : theme.palette.info.main,
            color: theme.palette.common.white,
          },
        }}
      />
    </div>
  );
}

export default Lists;
