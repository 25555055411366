import React, { useState, useEffect, useMemo } from 'react';
import TopNavBar from './TopNavBar';
import SideNavBar from './SideNavBar';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CircularProgress,
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';

function AlternateProducts() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  // Extract aiKeywords from location.state
  const location = useLocation();
  const aiKeywords = location.state?.aiKeywords || ''; // AI-generated keywords passed from Inventory page

  // State for all products and displayed products
  const [allProducts, setAllProducts] = useState([]); // All fetched products
  const [displayedProducts, setDisplayedProducts] = useState([]); // Products after filtering

  // States for pagination and loading
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // States for vendor filtering
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(''); // Single select state

  const apiEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/product';

  // Function to fetch products
  const fetchProducts = async (reset = false) => {
    setLoading(true);
    try {
      const params = {
        limit: 25,
        offset: reset ? 0 : offset,
        productnamefilter: aiKeywords,
        // Removed vendoridfilter to handle filtering client-side
      };

      const authToken = localStorage.getItem('authToken');
      const headers = { Authorization: `Bearer ${authToken}` };

      const response = await axios.get(apiEndpoint, { params, headers });

      console.log('API Response:', response.data);

      const fetchedProducts = response.data.items;

      if (Array.isArray(fetchedProducts)) {
        // Extract unique vendors from fetched products
        const uniqueVendors = [
          ...new Set(fetchedProducts.map((product) => product._vendor?.Short_Name)),
        ].filter((vendor) => vendor); // Filter out undefined/null values

        if (reset) {
          setAllProducts(fetchedProducts);
          setOffset(25);
          setVendors(uniqueVendors);
        } else {
          setAllProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
          setOffset((prevOffset) => prevOffset + 25);
        }

        setHasMore(response.data.nextPage !== null);
      } else {
        console.error('Unexpected API response format:', response.data);
        if (reset) {
          setAllProducts([]);
          setVendors([]);
        }
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      if (reset) {
        setAllProducts([]);
        setVendors([]);
      }
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch products on component mount and when aiKeywords change
  useEffect(() => {
    fetchProducts(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiKeywords]);

  // Update displayedProducts whenever allProducts or selectedVendor changes
  useEffect(() => {
    if (selectedVendor === '') {
      setDisplayedProducts(allProducts);
    } else {
      setDisplayedProducts(
        allProducts.filter((product) => product._vendor?.Short_Name === selectedVendor)
      );
    }
  }, [allProducts, selectedVendor]);

  const handleVendorChange = (event) => {
    setSelectedVendor(event.target.value);
    // No need to reset offset or fetch products again
  };

  const loadMoreProducts = () => {
    fetchProducts();
  };

  const navigateToProduct = (productId) => {
    navigate(`/product/${productId}`);
  };

  // Memoize the vendor list to prevent unnecessary recalculations
  const memoizedVendors = useMemo(() => vendors, [vendors]);

  return (
    <div>
      <TopNavBar title="Alternate Products" />
      {isDesktop && <SideNavBar />}
      <Box
        sx={{
          paddingTop: '0px',
          paddingLeft: isDesktop ? '240px' : '0', // Ensure this matches the SideNavBar width
          transition: 'padding-left 0.3s',
        }}
      >
        {/* Vendor Filter Dropdown */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '16px',
            paddingRight: '16px',
          }}
        >
          <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
            <InputLabel
              sx={{
                fontFamily: 'Gotham Medium',
                color: selectedVendor ? '#0E9C9B' : '#000000', // Teal when selected/focused, black otherwise
                '&.Mui-focused': {
                  color: '#0E9C9B', // Teal color when focused
                },
              }}
            >
              Filter by Vendor
            </InputLabel>
            <Select
              value={selectedVendor}
              onChange={handleVendorChange}
              label="Filter by Vendor"
              sx={{
                fontFamily: 'Gotham Medium',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#e0e0e0',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0E9C9B',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#0E9C9B',
                },
                '& .MuiSelect-icon': {
                  color: '#0E9C9B', // Teal color for the dropdown icon
                },
                // Ensure the selected value uses Gotham Medium
                '& .MuiSelect-select': {
                  fontFamily: 'Gotham Medium',
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                    width: 250, // Increased width for longer text
                  },
                },
              }}
            >
              <MenuItem value="">
                <em>All Vendors</em>
              </MenuItem>
              {memoizedVendors.map((vendor, index) => (
                <MenuItem key={index} value={vendor} sx={{ fontFamily: 'Gotham Medium' }}>
                  {vendor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {/* Product Grid */}
        <Box
          sx={{
            padding: '16px',
            maxWidth: isDesktop ? '1200px' : '100%',
            margin: isDesktop ? '0 auto' : '0',
            transition: 'padding-left 0.3s',
          }}
        >
          <Grid container spacing={2}>
            {displayedProducts.map((product) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                key={product.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '100%',
                }}
              >
                <Card
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    cursor: 'pointer',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
                    },
                    [theme.breakpoints.down('sm')]: {
                      maxWidth: 300,
                      margin: '0 auto',
                    },
                  }}
                  onClick={() => navigateToProduct(product.id)}
                >
                  {product.Image_URL && (
                    <CardMedia
                      component="img"
                      height="200"
                      image={product.Image_URL}
                      alt={product.short_description}
                      loading="lazy" // Optional: Lazy loading for performance
                    />
                  )}
                  <CardContent
                    sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      {product.brand && (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: '#0e9c9b',
                            fontWeight: 'bold',
                            fontFamily: 'Gotham Medium Bold',
                          }}
                        >
                          {product.brand}
                        </Typography>
                      )}
                      <Typography
                        variant="body1"
                        sx={{ marginBottom: '8px', fontFamily: 'Gotham Medium' }}
                      >
                        {product.short_description.length > 50
                          ? `${product.short_description.substring(0, 50)}...`
                          : product.short_description}
                      </Typography>
                      {product.purchase_price !== undefined && (
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            fontFamily: 'Gotham Medium Bold',
                          }}
                        >
                          {product.purchase_price > 0
                            ? `$${product.purchase_price.toFixed(2)}`
                            : 'Contact us for more information'}
                        </Typography>
                      )}
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: '1.1rem',
                          color: 'text.secondary',
                          fontFamily: 'Gotham Book',
                        }}
                      >
                        {product.product_volume && product.purchase_price
                          ? `$${(product.purchase_price / product.product_volume).toFixed(2)} per ${product.priced_by}`
                          : 'Price per unit not available'}
                      </Typography>
                    </Box>
                    {product._vendor && product._vendor.Logo_URL && (
                      <Box sx={{ textAlign: 'right' }}>
                        <img
                          src={product._vendor.Logo_URL}
                          alt={product._vendor.Short_Name}
                          style={{ height: '40px', marginTop: '8px' }}
                          loading="lazy" // Optional: Lazy loading for performance
                        />
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Loading Indicator */}
        {loading && (
          <Box sx={{ textAlign: 'center', margin: '16px' }}>
            <CircularProgress />
          </Box>
        )}

        {/* Load More Button */}
        {hasMore && !loading && (
          <Box
            sx={{
              textAlign: 'center',
              margin: '16px',
              transition: 'padding-left 0.3s',
            }}
          >
            <Button
              variant="contained"
              onClick={loadMoreProducts}
              sx={{
                backgroundColor: '#0e9c9b',
                color: '#ffffff',
                fontFamily: 'Gotham Medium',
                mx: 'auto',
                '&:hover': {
                  backgroundColor: '#0D8A89',
                },
              }}
            >
              Load More
            </Button>
          </Box>
        )}

        {/* No Products Found */}
        {!loading && displayedProducts.length === 0 && (
          <Box sx={{ textAlign: 'center', margin: '16px' }}>
            <Typography variant="body1" sx={{ fontFamily: 'Gotham Medium' }}>
              No products found.
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}

export default AlternateProducts;
