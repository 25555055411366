// src/ItemDetails.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Alert,
  Tooltip,
  Divider,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import TopNavBar from './TopNavBar';
import {
  List as ListIcon,
  Inventory as InventoryIcon,
  ArrowBackIos,
  ArrowForwardIos,
  Info as InfoIcon,
} from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AddToCartButton from './AddToCartButton';
import { Helmet } from 'react-helmet'; // Added for setting page title

function ItemDetails() {
  const [product, setProduct] = useState(null);
  const [associatedImages, setAssociatedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [imageIndex, setImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);

  // States for Add to List
  const [openAddInventoryModal, setOpenAddInventoryModal] = useState(false);
  const [lists, setLists] = useState([]);
  const [listsLoading, setListsLoading] = useState(false);
  const [addingToList, setAddingToList] = useState(false);
  const [addListSuccess, setAddListSuccess] = useState('');
  const [addListError, setAddListError] = useState('');
  const [addListButtonText, setAddListButtonText] = useState('Add to List');

  // States for Add to Inventory
  const [addInventorySuccess, setAddInventorySuccess] = useState('');
  const [addInventoryError, setAddInventoryError] = useState('');
  const [addInventoryButtonText, setAddInventoryButtonText] = useState('Add to Inventory');

  // **New State Variables for Sample Request**
  const [sampleRequestLoading, setSampleRequestLoading] = useState(false);
  const [sampleRequestSuccess, setSampleRequestSuccess] = useState('');
  const [sampleRequestError, setSampleRequestError] = useState('');

  const { productId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  // Determine navigation context
  const navigationState = location.state || {};
  const { source, filters } = navigationState;

  // Multiplier State
  const [multiplier, setMultiplier] = useState(1);

  // Fetch the multiplier from localStorage when the component mounts
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
        setMultiplier(storedMultiplier);
        console.log('Multiplier retrieved from localStorage:', storedMultiplier);
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        setMultiplier(1);
      }
    } else {
      console.warn('No user data found in localStorage. Using default multiplier of 1.');
      setMultiplier(1);
    }
  }, []);

  // Fetch product details and associated images
  const fetchProductDetail = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');

      if (!authToken) {
        console.error('Authentication token not found.');
        navigate('/');
        return;
      }

      const headers = { Authorization: `Bearer ${authToken}` };

      // Fetch product details
      const response = await axios.get(
        `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/product/${productId}`,
        { headers }
      );

      setProduct(response.data);

      // Fetch associated images
      const imagesResponse = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/images',
        {
          headers,
          params: {
            productidfilter: productId,
          },
        }
      );

      setAssociatedImages(imagesResponse.data);
      if (imagesResponse.data.length > 0) {
        setSelectedImage(imagesResponse.data[0].URL); // Set the first image as the selected image
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const handleImageNavigation = (direction) => {
    if (direction === 'left' && imageIndex > 0) {
      setImageIndex(imageIndex - 1);
    } else if (direction === 'right' && imageIndex < associatedImages.length - 5) {
      setImageIndex(imageIndex + 1);
    }
  };

  const calculatePricePerUnit = (product) => {
    const { purchase_price, product_volume, priced_by } = product;
    const adjusted_purchase_price = purchase_price * multiplier;

    if (adjusted_purchase_price && product_volume > 0 && priced_by) {
      const pricePerUnit = adjusted_purchase_price / product_volume;
      return `$${pricePerUnit.toFixed(2)}/${priced_by}`;
    } else {
      return null;
    }
  };

  // Truncate Text
  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  // Functions for Add to List
  const fetchLists = async () => {
    setListsLoading(true);
    setAddListError('');
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        setAddListError('Unable to fetch lists. Please try again.');
        return;
      }

      const headers = { Authorization: `Bearer ${authToken}` };
      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/lists',
        {
          headers,
          params: {
            restaurantidfilter: user.restaurant_id,
          },
        }
      );

      setLists(response.data);
    } catch (error) {
      console.error('Error fetching lists:', error);
      setAddListError('Failed to load lists. Please try again.');
    } finally {
      setListsLoading(false);
    }
  };

  const handleOpenAddInventoryModal = () => {
    setOpenAddInventoryModal(true);
    fetchLists();
  };

  const handleCloseAddInventoryModal = () => {
    setOpenAddInventoryModal(false);
    setAddListSuccess('');
    setAddListError('');
  };

  const handleAddToList = async (listId) => {
    setAddingToList(true);
    setAddListSuccess('');
    setAddListError('');
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.id) {
        console.error('Authentication token or user data not found.');
        setAddListError('Authentication error. Please log in again.');
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        list_item_name: '',
        product_id: product.id,
        lists_id: listId,
      };

      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/list_item',
        data,
        { headers }
      );

      setAddListSuccess('Item successfully added to the list.');
      setAddListButtonText('Added to List!');
      // Optionally, you can close the modal after a short delay
      setTimeout(() => {
        handleCloseAddInventoryModal();
      }, 1500);
    } catch (error) {
      console.error('Error adding to list:', error);
      setAddListError('Failed to add item to the list. Please try again.');
    } finally {
      setAddingToList(false);
    }
  };

  const handleAddToInventory = async () => {
    setAddInventoryButtonText('Adding...');
    setAddInventorySuccess('');
    setAddInventoryError('');
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        setAddInventoryError('Authentication error. Please log in again.');
        setAddInventoryButtonText('Add to Inventory');
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        restaurant_id: user.restaurant_id,
        item_description: product.short_description || 'No description provided',
        On_Hand: 1,
        PAR: 1,
        remaining: 0,
        reorder_now: false,
        product_id: product.id,
      };

      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventory',
        data,
        { headers }
      );

      setAddInventorySuccess('Item successfully added to inventory.');
      setAddInventoryButtonText('Added to Inventory!');
    } catch (error) {
      console.error('Error adding to inventory:', error);
      setAddInventoryError('Failed to add item to inventory. Please try again.');
      setAddInventoryButtonText('Add to Inventory');
    }
  };

  // **New Handler Function for Sample Request**
  const handleRequestSample = async () => {
    setSampleRequestLoading(true);
    setSampleRequestSuccess('');
    setSampleRequestError('');

    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.id) {
        console.error('Authentication token or user data not found.');
        setSampleRequestError('Authentication error. Please log in again.');
        setSampleRequestLoading(false);
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        subject: `Sample Request for ${product._vendor?.Short_Name || 'Vendor'} for ${
          product.short_description || 'Product'
        }`, // Changed "from" to "for"
        description: `Product catalog #: ${product.FF_Catalog_Number || 'N/A'}`,
        user_id: user.id,
        ticket_type_id: 7, // Hardcoded as per instruction
        ticket_status_id: 1, // Always 1
        admin_notes: 'We have received your sample request and will coordinate the delivery with the supplier.',
        last_updated: new Date().toISOString(), // Assuming current timestamp
      };

      await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets',
        data,
        { headers }
      );

      setSampleRequestSuccess('Sample request successfully submitted.');
    } catch (error) {
      console.error('Error submitting sample request:', error);
      setSampleRequestError('Failed to submit sample request. Please try again.');
    } finally {
      setSampleRequestLoading(false);
    }
  };

  // Tooltip content for In Stock Info
  const inStockTooltip = (
    <Typography variant="body2" sx={{ fontFamily: theme.typography.fontFamily }}>
      Inventory is confirmed at the time of purchase. Some items may occasionally be out of stock,
      but we'll notify you immediately and work to find a suitable replacement.
    </Typography>
  );

  // Custom Styled Tooltip for better readability
  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
    [`& .MuiTooltip-arrow`]: {
      color: theme.palette.common.white,
    },
  }));

  return (
    <div>
      <TopNavBar
        title="Product Details"
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          zIndex: theme.zIndex.appBar,
          backgroundColor: theme.palette.background.paper,
        }}
      />
      <Box
        sx={{
          paddingTop: theme.spacing(10), // Increased top padding to account for TopNavBar
          paddingBottom: theme.spacing(10), // Added bottom padding
          paddingX: theme.spacing(2),
          textAlign: 'center',
        }}
      >
        {product && (
          <Helmet>
            <title>FareFood | {truncateText(product.short_description || '', 25)}</title>
          </Helmet>
        )}
        {loading ? (
          <Box sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
            <CircularProgress size={24} />
          </Box>
        ) : product ? (
          <Grid container spacing={2} justifyContent="center">
            {/* Images Section */}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: 'center', position: 'relative' }}
            >
              <Box
                sx={{
                  position: 'relative',
                  marginBottom: theme.spacing(2),
                  marginTop: theme.spacing(0.5),
                }}
              >
                <Box
                  component="img"
                  src={selectedImage || product.Image_URL || ''}
                  alt={product.short_description || 'Product Image'}
                  sx={{
                    width: '75%',
                    maxWidth: 300,
                    height: 'auto',
                    maxHeight: 300,
                    objectFit: 'cover',
                    borderRadius: theme.shape.borderRadius,
                  }}
                />

                {/* Vendor Logo Positioned at Bottom Right of Image */}
                {product?._vendor?.Logo_URL && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: theme.spacing(2),
                      right: theme.spacing(2),
                      width: 50,
                      height: 50,
                      borderRadius: '50%',
                      backgroundColor: theme.palette.common.white,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: theme.shadows[1],
                      zIndex: 2,
                    }}
                  >
                    <Box
                      component="img"
                      src={product._vendor.Logo_URL}
                      alt={product._vendor.Short_Name}
                      sx={{
                        width: '80%',
                        height: '80%',
                        objectFit: 'contain',
                        borderRadius: '50%',
                      }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/path/to/default/logo.png';
                      }}
                    />
                  </Box>
                )}

                {/* Add to Inventory and Add to List Buttons */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: theme.spacing(2),
                    right: theme.spacing(2),
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 2,
                  }}
                >
                  <Tooltip title="Add to Inventory" arrow>
                    <Button
                      variant="outlined"
                      onClick={handleAddToInventory}
                      disabled={addInventoryButtonText === 'Added to Inventory!'}
                      sx={{
                        color: theme.palette.primary.main,
                        textTransform: 'none',
                        borderColor: theme.palette.primary.main,
                        marginRight: theme.spacing(1),
                        backgroundColor: 'white',
                      }}
                      startIcon={<InventoryIcon />}
                    >
                      Add to Inventory
                    </Button>
                  </Tooltip>
                  <Tooltip title="Add to List" arrow>
                    <Button
                      variant="outlined"
                      onClick={handleOpenAddInventoryModal}
                      disabled={addListButtonText === 'Added to List!'}
                      sx={{
                        color: theme.palette.primary.main,
                        textTransform: 'none',
                        borderColor: theme.palette.primary.main,
                        backgroundColor: 'white',
                      }}
                      startIcon={<ListIcon />}
                    >
                      Add to List
                    </Button>
                  </Tooltip>
                </Box>
              </Box>

              {/* Associated Images with Arrows */}
              {associatedImages.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: theme.spacing(2),
                    maxWidth: 300,
                    margin: '0 auto',
                  }}
                >
                  <IconButton
                    onClick={() => handleImageNavigation('left')}
                    disabled={imageIndex === 0}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <Box sx={{ display: 'flex', overflowX: 'auto' }}>
                    {associatedImages
                      .slice(imageIndex, imageIndex + 5)
                      .map((img) => (
                        <Box
                          component="img"
                          key={img.id}
                          src={img.URL}
                          alt={`Product Image ${img.id}`}
                          sx={{
                            width: 60,
                            height: 60,
                            objectFit: 'cover',
                            marginRight: theme.spacing(1),
                            cursor: 'pointer',
                            borderRadius: theme.shape.borderRadius,
                            border:
                              selectedImage === img.URL
                                ? `2px solid ${theme.palette.primary.main}`
                                : '2px solid transparent',
                          }}
                          onClick={() => handleImageClick(img.URL)}
                        />
                      ))}
                  </Box>
                  <IconButton
                    onClick={() => handleImageNavigation('right')}
                    disabled={imageIndex >= associatedImages.length - 5}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Box>
              )}
            </Grid>

            {/* Product Details Section */}
            <Grid item xs={12} md={6} sx={{ textAlign: 'left' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '0.9rem',
                  color: theme.palette.text.secondary,
                  paddingRight: isDesktop ? theme.spacing(30) : theme.spacing(2),
                }}
              >
                {product.brand}
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '1.5rem',
                  marginTop: theme.spacing(1),
                  paddingRight: isDesktop ? theme.spacing(30) : theme.spacing(2),
                }}
              >
                {product.short_description}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  color: theme.palette.text.secondary,
                  marginTop: theme.spacing(0.5),
                }}
              >
                {product.FF_Catalog_Number}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: '0.9rem',
                  marginTop: theme.spacing(0.5),
                }}
              >
              </Typography>

              {/* Price, Price Per Unit, Add to Cart Button, and In Stock Info */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  marginTop: theme.spacing(1),
                }}
              >
                {/* Price and Price Per Unit */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontSize: '1.25rem',
                      fontWeight: theme.typography.fontWeightBold,
                      fontFamily: theme.typography.fontFamily,
                      marginRight: theme.spacing(1),
                    }}
                  >
                    ${(product.purchase_price * multiplier).toFixed(2)}
                  </Typography>
                  {calculatePricePerUnit(product) && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '0.875rem',
                        color: theme.palette.text.secondary,
                        fontFamily: theme.typography.fontFamily,
                      }}
                    >
                      {calculatePricePerUnit(product)}
                    </Typography>
                  )}
                </Box>

                {/* Add to Cart Button */}
                <Box sx={{ marginTop: theme.spacing(1) }}>
                  <AddToCartButton product={product} multiplier={multiplier} />
                </Box>

                {/* Divider Line */}
                <Divider sx={{ width: '73%', marginY: theme.spacing(2) }} />

                {/* In Stock Container */}
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.grey[300]}`,
                    padding: theme.spacing(1),
                    borderRadius: theme.shape.borderRadius,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: theme.typography.fontFamily,
                      color: theme.palette.primary.main,
                    }}
                  >
                    In Stock&nbsp;
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    near you
                  </Typography>
                  <StyledTooltip title={inStockTooltip} arrow>
                    <InfoIcon
                      sx={{
                        marginLeft: theme.spacing(1),
                        color: theme.palette.text.secondary,
                        cursor: 'pointer',
                      }}
                    />
                  </StyledTooltip>
                </Box>

                {/* **Request a Sample Button** */}
                <Box sx={{ marginTop: theme.spacing(2), width: '30%' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleRequestSample}
                    disabled={sampleRequestLoading || sampleRequestSuccess}
                    fullWidth
                  >
                    {sampleRequestLoading
                      ? 'Submitting...'
                      : sampleRequestSuccess
                      ? 'Sample Requested'
                      : 'Request a Sample'}
                  </Button>
                </Box>
              </Box>

              {/* Feedback Messages for Add to Inventory */}
              {addInventorySuccess && (
                <Alert
                  severity="success"
                  sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                >
                  {addInventorySuccess}
                </Alert>
              )}
              {addInventoryError && (
                <Alert
                  severity="error"
                  sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                >
                  {addInventoryError}
                </Alert>
              )}

              {/* Feedback Messages for Add to List */}
              {addListSuccess && (
                <Alert
                  severity="success"
                  sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                >
                  {addListSuccess}
                </Alert>
              )}
              {addListError && (
                <Alert
                  severity="error"
                  sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                >
                  {addListError}
                </Alert>
              )}

              {/* **Feedback Messages for Sample Request** */}
              {sampleRequestSuccess && (
                <Alert
                  severity="success"
                  sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                >
                  {sampleRequestSuccess}
                </Alert>
              )}
              {sampleRequestError && (
                <Alert
                  severity="error"
                  sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                >
                  {sampleRequestError}
                </Alert>
              )}
            </Grid>

            {/* Description and Additional Info */}
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: 'left',
                  paddingLeft: isDesktop ? theme.spacing(30) : theme.spacing(2),
                  paddingRight: isDesktop ? theme.spacing(30) : theme.spacing(2),
                  marginTop: theme.spacing(4),
                }}
              >
                {/* Description Title */}
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: 'bold',
                    marginBottom: theme.spacing(2),
                    color: 'black',
                  }}
                >
                  Description
                </Typography>

                <Box
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '14px',
                    color: 'black',
                  }}
                >
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      h1: ({ node, ...props }) => (
                        <Typography
                          variant="h6"
                          sx={{ fontSize: '16px', color: 'black', fontWeight: 'normal' }}
                          {...props}
                        />
                      ),
                      h2: ({ node, ...props }) => (
                        <Typography
                          variant="subtitle1"
                          sx={{ fontSize: '14px', color: 'black', fontWeight: 'normal' }}
                          {...props}
                        />
                      ),
                      h3: ({ node, ...props }) => (
                        <Typography
                          variant="body1"
                          sx={{ fontSize: '14px', color: 'black', fontWeight: 'normal' }}
                          {...props}
                        />
                      ),
                      h4: ({ node, ...props }) => (
                        <Typography
                          variant="body2"
                          sx={{ fontSize: '12px', color: 'black', fontWeight: 'normal' }}
                          {...props}
                        />
                      ),
                      p: ({ node, ...props }) => (
                        <Typography
                          variant="body2"
                          sx={{ fontSize: '12px', color: 'black', fontWeight: 'normal' }}
                          {...props}
                        />
                      ),
                      strong: ({ node, ...props }) => (
                        <Typography component="span" sx={{ fontWeight: 'normal' }} {...props} />
                      ),
                    }}
                  >
                    {showFullDescription
                      ? product.markdown_description || ''
                      : truncateText(product.markdown_description || '', 200)}
                  </ReactMarkdown>
                </Box>

                {showFullDescription && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: theme.spacing(1),
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '14px',
                      }}
                    >
                      <strong>Pack Size:</strong> {product.pack_size || 'N/A'}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: theme.spacing(1),
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '14px',
                      }}
                    >
                      <strong>Case Dimensions:</strong>{' '}
                      {product.case_dimensions || 'N/A'}
                    </Typography>
                  </>
                )}

                <Button
                  onClick={() => setShowFullDescription(!showFullDescription)}
                  sx={{
                    marginTop: theme.spacing(1),
                    fontFamily: theme.typography.fontFamily,
                    color: theme.palette.primary.main,
                    textTransform: 'none',
                  }}
                >
                  {showFullDescription ? 'Read Less' : 'Read More'}
                </Button>

                {/* Disclaimer */}
                <Box sx={{ marginTop: theme.spacing(4) }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      color: theme.palette.text.secondary,
                      fontSize: '12px',
                    }}
                  >
                    Disclaimer: FareFood shares product detail provided by the supplier. If you believe any of the information is incorrect, please contact us at restaurants@fare.food.
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {/* Add List Modal */}
            <Dialog
              open={openAddInventoryModal}
              onClose={handleCloseAddInventoryModal}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle sx={{ fontFamily: theme.typography.fontFamily }}>
                Select a List to Add
              </DialogTitle>
              <DialogContent sx={{ fontFamily: theme.typography.fontFamily }}>
                {listsLoading ? (
                  <Box sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
                    <CircularProgress size={24} />
                  </Box>
                ) : addListError ? (
                  <Alert severity="error">{addListError}</Alert>
                ) : (
                  <List>
                    {lists.map((list) => (
                      <ListItem key={list.id} disablePadding>
                        <ListItemButton onClick={() => handleAddToList(list.id)}>
                          <ListItemText
                            primary={list.list_name}
                            primaryTypographyProps={{ fontFamily: theme.typography.fontFamily }}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                    {lists.length === 0 && (
                      <Typography
                        variant="body2"
                        align="center"
                        sx={{ fontFamily: theme.typography.fontFamily }}
                      >
                        No lists available.
                      </Typography>
                    )}
                  </List>
                )}
                {addListSuccess && (
                  <Alert
                    severity="success"
                    sx={{ mt: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
                  >
                    {addListSuccess}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAddInventoryModal}
                  disabled={addingToList}
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        ) : (
          <Typography variant="body1" align="center">
            Unable to load product details.
          </Typography>
        )}
      </Box>
    </div>
  );
}

export default ItemDetails;
