import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import SalesNavBar from './SalesNavBar';
import StyledButton from './StyledButton';
import Footer from './Footer';

// About Us Page Component
function AboutUsPage() {
  const navigate = useNavigate();

  // Styled components for consistent styling
  const Section = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: '#FFFFFF', // Set the background to white
  }));

  const SectionTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.h4.fontWeight,
    fontFamily: theme.typography.fontFamily,
  }));

  const StoryText = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(6),
    fontWeight: theme.typography.body1.fontWeight,
  }));

  const ValueBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: 4,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFFFFF', // Ensure the value boxes also have a white background
    textAlign: 'center',
  }));

  const ValueTitle = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.h5.fontWeight,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  }));

  const ValueDescription = styled(Typography)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
  }));

  return (
    <Box sx={{ backgroundColor: '#FFFFFF' }}> {/* Set the overall page background to white */}
      {/* Header */}
      <SalesNavBar />

      {/* Our Story Section */}
      <Section>
        <Container maxWidth="md">
          <SectionTitle variant="h4" align="center">
            A Passion for Food, A Mission to Save It
          </SectionTitle>
          <StoryText variant="body1" align="center">
            We’re just like you—food lovers who have watched some of our favorite local spots disappear. With years of tech experience as Ex-YC Alumni and a deep passion for the restaurant industry, we couldn't stand by as rising costs and inefficient systems put more pressure on independent restaurants. 
            <br /><br />
            FareFood was created to fight back. We’ve combined our expertise with our love of food to build a platform that saves restaurants time, lowers costs, and helps them thrive. Whether it’s a hole-in-the-wall or a beloved local café, we’re here to help restaurants stay in business and flourish.
          </StoryText>
        </Container>
      </Section>

      {/* Our Values Section */}
      <Section>
        <Container maxWidth="lg">
          <SectionTitle variant="h4" align="center">
            Our Values
          </SectionTitle>
          <Grid container spacing={4} justifyContent="center">
            {/* Simplicity Value */}
            <Grid item xs={12} md={4}>
              <ValueBox>
                <ValueTitle variant="h5">Simplicity</ValueTitle>
                <ValueDescription>
                  We streamline the complex world of food supply, making it easy for any restaurant to get the best deal with minimal effort.
                </ValueDescription>
              </ValueBox>
            </Grid>

            {/* Savings Value */}
            <Grid item xs={12} md={4}>
              <ValueBox>
                <ValueTitle variant="h5">Savings</ValueTitle>
                <ValueDescription>
                  We’re dedicated to helping restaurants save money where it matters most—in their food costs—so they can keep their doors open.
                </ValueDescription>
              </ValueBox>
            </Grid>

            {/* Support Value */}
            <Grid item xs={12} md={4}>
              <ValueBox>
                <ValueTitle variant="h5">Support</ValueTitle>
                <ValueDescription>
                  We're not just a platform, we're partners. We’re always here to provide real-time support when you need us.
                </ValueDescription>
              </ValueBox>
            </Grid>
          </Grid>
        </Container>
      </Section>

      {/* Call to Action Section */}
      <Section>
        <Container maxWidth="md">
          <SectionTitle variant="h4" align="center">
            Ready to Get Started?
          </SectionTitle>
          <Typography variant="body1" align="center" sx={{ fontFamily: 'Roboto, Arial, sans-serif', color: '#555555', marginBottom: 6 }}>
            Talk to our team today and learn how FareFood can help your business.
          </Typography>
          <Box textAlign="center">
            <StyledButton
              variant="contained"
              color="secondary" // Set to secondary color (bright orange)
              onClick={() => navigate('/get-demo')}
              sx={{
                paddingX: 4,
                paddingY: 1.5,
                backgroundColor: '#FF5722', // Override to ensure the button is bright orange
                '&:hover': { backgroundColor: '#E64A19' }, // Slightly darker on hover
              }}
            >
              Book a Demo
            </StyledButton>
          </Box>
        </Container>
      </Section>

      {/* Footer */}
      <Footer />
    </Box>
  );
}

export default AboutUsPage;