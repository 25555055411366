// src/components/MOQTracker.js

import React from 'react';
import MOQComponent from './MOQComponent';

function MOQTracker() {
  return <MOQComponent />;
}

export default MOQTracker;
