// src/CaseStudies.js

import React from 'react';
import { Box, Typography } from '@mui/material';

const CaseStudies = () => {
  return (
    <Box
      sx={{
        minHeight: '80vh', // Adjust as needed
        padding: '40px 20px',
        backgroundColor: '#f9f9f9',
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Case Studies
      </Typography>
      <Typography variant="body1" align="center">
        {/* Placeholder content */}
        Coming Soon!
      </Typography>
    </Box>
  );
};

export default CaseStudies;
