import React, { useContext, useState, useEffect } from 'react';
import { Button, IconButton, Typography, Box, Tooltip } from '@mui/material';
import { AddShoppingCart, Add, Remove } from '@mui/icons-material';
import { CartContext } from './CartContext';
import { useMediaQuery } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';

function AddToCartButton({ product, multiplier }) {
  const { cartItems, updateItemQuantity } = useContext(CartContext);
  const [quantity, setQuantity] = useState(0);
  const [isInCart, setIsInCart] = useState(false);
  const [orderitem_id, setOrderitem_id] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const cartItem = cartItems.find((item) => item.product_id === product.id);
    if (cartItem) {
      setQuantity(cartItem.quantity);
      setIsInCart(true);
      setOrderitem_id(cartItem.id);
    } else {
      setQuantity(0);
      setIsInCart(false);
      setOrderitem_id(null);
    }
  }, [cartItems, product.id]);

  const handleAddToCart = (e) => {
    e.stopPropagation();
    console.log(`Adding product ${product.id} to cart.`);
    updateItemQuantity({ _product: product, orderitem_id: orderitem_id || null }, 1);
  };

  const handleIncrease = (e) => {
    e.stopPropagation();
    console.log(`Increasing quantity for product ${product.id} to ${quantity + 1}.`);
    updateItemQuantity({ _product: product, orderitem_id: orderitem_id }, quantity + 1);
  };

  const handleDecrease = (e) => {
    e.stopPropagation();
    console.log(`Decreasing quantity for product ${product.id} to ${quantity - 1}.`);
    updateItemQuantity({ _product: product, orderitem_id: orderitem_id }, quantity - 1);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isInCart ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.primary.main}`,
            paddingX: theme.spacing(0.5),
            backgroundColor: 'white', // Changed to white background
          }}
        >
          <IconButton
            onClick={handleDecrease}
            size="small"
            sx={{
              color: quantity === 1 ? theme.palette.error.main : theme.palette.primary.main,
              padding: theme.spacing(0.5),
            }}
            aria-label="Decrease quantity"
          >
            <Remove fontSize="small" />
          </IconButton>
          <Typography
            variant="body2"
            sx={{
              marginX: theme.spacing(0.5),
              fontFamily: theme.typography.fontFamily,
              fontSize: '0.75rem',
              minWidth: '20px',
              textAlign: 'center',
            }}
          >
            {quantity}
          </Typography>
          <IconButton
            onClick={handleIncrease}
            size="small"
            sx={{
              color: theme.palette.primary.main,
              padding: theme.spacing(0.5),
            }}
            aria-label="Increase quantity"
          >
            <Add fontSize="small" />
          </IconButton>
        </Box>
      ) : isMobile ? (
        <Tooltip title="Add to Cart" arrow>
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleAddToCart}
            sx={{
              borderRadius: '50%',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                boxShadow: theme.shadows[4],
              },
              padding: theme.spacing(1),
              minWidth: '40px',
              height: '40px',
              width: '40px',
            }}
            aria-label="Add to Cart"
          >
            <AddShoppingCart fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddShoppingCart />}
          onClick={handleAddToCart}
          sx={{
            borderRadius: theme.shape.borderRadius,
            textTransform: 'none',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontFamily: theme.typography.fontFamily,
            fontSize: '0.875rem',
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: theme.shadows[4],
            },
            padding: theme.spacing(0.5, 1),
            minWidth: '100px',
          }}
          aria-label="Add to Cart"
        >
          <Typography
            variant="body2"
            sx={{ color: theme.palette.primary.contrastText }} // Ensures "Add" text is white
          >
            Add
          </Typography>
        </Button>
      )}
    </Box>
  );
}

export default AddToCartButton;
