// src/components/GetDemo.js

import React, { useEffect } from 'react';
import { Box, Container, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { styled } from '@mui/system';
import Footer from './Footer'; // Reuse the existing Footer
import StyledButton from './StyledButton'; // Ensure StyledButton is correctly imported

// Import unique icons for each bullet point
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'; // For Reduce Food Costs
import StorefrontIcon from '@mui/icons-material/Storefront'; // For Simplify Supplier Management
import InventoryIcon from '@mui/icons-material/Inventory'; // For Optimize Inventory
import SettingsIcon from '@mui/icons-material/Settings'; // For Enhance Operational Efficiency

// Styled component for the main section with reduced top padding
const DemoSection = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(5), // Reduced from theme.spacing(10)
  paddingBottom: theme.spacing(10),
  backgroundColor: '#F5F5F5',
  minHeight: '100vh',
}));

// Styled component for the content container
const ContentContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

// Styled component for the text section
const TextSection = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingRight: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    paddingRight: 0,
    marginBottom: theme.spacing(4),
  },
}));

// Styled component for the Calendly widget
const CalendlyWidget = styled(Box)(({ theme }) => ({
  flex: 1,
  minWidth: '320px',
  height: '700px',
  [theme.breakpoints.down('md')]: {
    height: '600px',
    width: '100%',
  },
}));

// Styled components for unique bright orange icons
const OrangeAttachMoneyIcon = styled(AttachMoneyIcon)(({ theme }) => ({
  color: '#FF5722', // Bright orange color
  minWidth: '40px',
}));

const OrangeStorefrontIcon = styled(StorefrontIcon)(({ theme }) => ({
  color: '#FF5722',
  minWidth: '40px',
}));

const OrangeInventoryIcon = styled(InventoryIcon)(({ theme }) => ({
  color: '#FF5722',
  minWidth: '40px',
}));

const OrangeSettingsIcon = styled(SettingsIcon)(({ theme }) => ({
  color: '#FF5722',
  minWidth: '40px',
}));

function GetDemo() {
  useEffect(() => {
    // Inject Calendly script after component mounts
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box>
      {/* Main Demo Section */}
      <DemoSection>
        <ContentContainer maxWidth="lg">
          {/* Text Section */}
          <TextSection>
            <Typography variant="h4" gutterBottom>
              Get a Free FareFood Demo
            </Typography>
            <Typography variant="body1" gutterBottom>
              Schedule a demo to discover how FareFood can help reduce your food costs and simplify supplier management.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Start saving today with FareFood's cost-effective solutions.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Early adopters benefit from exclusive features and personalized support.
            </Typography>

            {/* Value Adds */}
            <Box mt={4}>
              <Typography variant="h6" gutterBottom>
                Streamline Your Restaurant's Procurement
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <OrangeAttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Reduce Food Costs"
                    secondary="Save significantly by comparing prices across multiple suppliers in real-time."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeStorefrontIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Simplify Supplier Management"
                    secondary="Order from all your suppliers through one intuitive platform."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeInventoryIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Optimize Inventory"
                    secondary="Monitor ingredient levels and set automatic reordering to prevent stockouts."
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <OrangeSettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Enhance Operational Efficiency"
                    secondary="Manage orders, deliveries, and returns effortlessly."
                  />
                </ListItem>
              </List>
            </Box>
          </TextSection>

          {/* Calendly Widget */}
          <CalendlyWidget
            className="calendly-inline-widget"
            data-url="https://calendly.com/farefood-michael?hide_gdpr_banner=1"
            style={{ borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}
          ></CalendlyWidget>
        </ContentContainer>
      </DemoSection>

      {/* Footer */}
      <Footer />
    </Box>
  );
}

export default GetDemo;
