// src/Checkout.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import TopNavBar from './TopNavBar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

function Checkout() {
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for handling checkout completion modal
  const [checkoutComplete, setCheckoutComplete] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutError, setCheckoutError] = useState(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  // Retrieve user data from local storage
  const userData = localStorage.getItem('user');
  let userRestaurantId = null;

  if (userData) {
    try {
      const parsedUser = JSON.parse(userData);
      userRestaurantId = parsedUser.restaurant_id;
    } catch (e) {
      console.error('Error parsing user data from local storage:', e);
      setError('Invalid user data. Please log in again.');
    }
  } else {
    setError('User not found. Please log in.');
  }

  useEffect(() => {
    if (!userRestaurantId) {
      // If restaurant_id is not available, do not proceed with the API call
      return;
    }

    const fetchOrder = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/order',
          {
            params: {
              restaurantidfilter: userRestaurantId,
              checkoutcompletefilter: false, // Hardcoded to false
              deliverystatusidfilter: 0,
              limit: 0,
              offset: 0,
              datefilter: '',
            },
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('Order API Response:', response);

        if (Array.isArray(response.data) && response.data.length > 0) {
          // If the response is an array, select the first order
          setOrder(response.data[0]);
          console.log('Order set from array:', response.data[0]);
        } else if (response.data && typeof response.data === 'object') {
          // If the response is a single object
          setOrder(response.data);
          console.log('Order set from object:', response.data);
        } else {
          setError('No active orders found.');
        }
      } catch (err) {
        console.error('Error fetching order:', err);
        if (err.response && err.response.data && err.response.data.error) {
          setError(err.response.data.error);
        } else if (err.message) {
          setError(err.message);
        } else {
          setError('Failed to fetch order. Please try again later.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [userRestaurantId]);

  const handleConfirmCheckout = async () => {
    if (!order) {
      setCheckoutError('No order available to complete.');
      return;
    }

    setCheckoutLoading(true);
    setCheckoutError(null);

    // Calculate the final amount safely
    const finalAmount =
      (order._sum_of_order_items_of_order?.order_item_total_price ||
        order.order_amount ||
        0) +
      (order._sum_of_order_items_of_order?.order_item_sales_tax_added || 0);

    const payload = {
      restaurantidfilter: userRestaurantId,
      total_amount: finalAmount,
      item_count:
        order._item_count_of_order?.order_item_quantity ||
        order.item_count ||
        0,
    };

    console.log('Complete Order Payload:', payload);

    try {
      const response = await axios.put(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/Complete_Order',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Complete Order API Response:', response);

      if (
        response.data &&
        typeof response.data === 'object' &&
        response.status === 200
      ) {
        // Do NOT setOrder(response.data) to avoid missing nested properties
        // Instead, show the confirmation modal
        setCheckoutComplete(true);
        console.log('Checkout completed successfully:', response.data);
      } else {
        setCheckoutError('Order completion failed. Please try again.');
      }
    } catch (err) {
      console.error('Error completing order:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setCheckoutError(err.response.data.error);
      } else if (err.message) {
        setCheckoutError(err.message);
      } else {
        setCheckoutError('Failed to complete order. Please try again later.');
      }
    } finally {
      setCheckoutLoading(false);
    }
  };

  // Calculate the final amount by summing subtotal and tax safely
  const finalAmount = order
    ? (order._sum_of_order_items_of_order?.order_item_total_price ||
        order.order_amount ||
        0) +
      (order._sum_of_order_items_of_order?.order_item_sales_tax_added || 0)
    : 0;

  return (
    <div>
      {/* Top Navigation Bar */}
      <TopNavBar title="Checkout" />

      {/* Main Content Wrapper */}
      <Box
        sx={{
          paddingTop: '4px',
          px: isDesktop ? '16px' : '8px',
          paddingLeft: isDesktop ? `calc(240px + 16px)` : '8px',
          paddingRight: isDesktop ? '16px' : '8px',
          transition: 'padding-left 0.3s, padding-right 0.3s',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          boxSizing: 'border-box',
        }}
      >
        {/* Inner Content Container */}
        <Box
          sx={{
            width: isDesktop ? '50%' : '100%',
            maxWidth: '800px',
            margin: '0 auto',
            textAlign: 'left',
          }}
        >
          {loading ? (
            <Box sx={{ textAlign: 'center', marginTop: '16px' }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Box sx={{ marginTop: '20px' }}>
              <Typography
                variant="h6"
                color="error"
                sx={{ fontFamily: 'Gotham Medium' }}
              >
                {error}
              </Typography>
            </Box>
          ) : order ? (
            <Box
              sx={{
                padding: '16px',
                border: '1px solid #e0e0e0',
                borderRadius: '8px',
              }}
            >
              {/* Order Summary */}
              <Typography
                variant="h5"
                sx={{ fontFamily: 'Gotham Medium', marginBottom: '16px' }}
              >
                Order Summary
              </Typography>

              {/* Subtotal */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontFamily: 'Gotham Medium' }}
                >
                  Subtotal:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: 'Gotham Medium' }}
                >
                  $
                  {order._sum_of_order_items_of_order?.order_item_total_price
                    ? order._sum_of_order_items_of_order.order_item_total_price.toFixed(
                        2
                      )
                    : order.order_amount
                    ? order.order_amount.toFixed(2)
                    : '0.00'}
                </Typography>
              </Box>

              {/* Tax */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontFamily: 'Gotham Medium' }}
                >
                  Tax:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: 'Gotham Medium' }}
                >
                  $
                  {order._sum_of_order_items_of_order?.order_item_sales_tax_added
                    ? order._sum_of_order_items_of_order.order_item_sales_tax_added.toFixed(
                        2
                      )
                    : '0.00'}
                </Typography>
              </Box>

              {/* Final Amount */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Gotham Medium',
                    fontWeight: 'bold',
                  }}
                >
                  Total Amount:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Gotham Medium',
                    fontWeight: 'bold',
                  }}
                >
                  ${finalAmount.toFixed(2)}
                </Typography>
              </Box>

              <Divider sx={{ marginY: '16px' }} />

              {/* Shipping Address */}
              <Typography
                variant="h6"
                sx={{
                  fontFamily: 'Gotham Medium',
                  marginBottom: '8px',
                }}
              >
                Shipping Address
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: 'Gotham Book',
                  marginBottom: '16px',
                }}
              >
                {order._shipping_address?.name || 'N/A'}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontFamily: 'Gotham Book' }}
              >
                {order._shipping_address?.location || 'N/A'}
              </Typography>

              {/* View MOQ Tracker Button */}
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('/moq-tracker')}
                sx={{
                  marginTop: '16px',
                  fontFamily: 'Gotham Medium',
                  color: '#0e9c9b',
                  borderColor: '#0e9c9b',
                  borderRadius: '25px',
                  padding: '8px 24px',
                  '&:hover': {
                    backgroundColor: '#e0f7f6',
                  },
                }}
              >
                View MOQ Tracker
              </Button>

              {/* Disclaimers */}
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginTop: '16px',
                  fontFamily: 'Gotham Medium',
                  fontStyle: 'italic',
                }}
              >
                By continuing to checkout, you agree to pay any fees incurred by
                missing the MOQ for a given supplier.
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  marginTop: '8px',
                  fontFamily: 'Gotham Medium',
                  fontStyle: 'italic',
                }}
              >
                Products may be out of stock when the order is placed. We will
                notify you with substitutes when we place the order.
              </Typography>

              {/* Confirm Checkout Button */}
              <Box sx={{ textAlign: 'right', marginTop: '24px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirmCheckout}
                  disabled={checkoutLoading}
                  sx={{
                    fontFamily: 'Gotham Medium',
                    backgroundColor: '#0e9c9b',
                    borderRadius: '25px',
                    padding: '12px 24px',
                    '&:hover': {
                      backgroundColor: '#0D8A89',
                      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
                    },
                  }}
                >
                  {checkoutLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Confirm Checkout'
                  )}
                </Button>
              </Box>

              {/* Display Checkout Error if any */}
              {checkoutError && (
                <Box sx={{ marginTop: '16px' }}>
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ fontFamily: 'Gotham Medium' }}
                  >
                    {checkoutError}
                  </Typography>
                </Box>
              )}
            </Box>
          ) : (
            <Typography
              variant="h6"
              align="center"
              sx={{
                fontFamily: 'Gotham Medium',
                marginTop: '32px',
              }}
            >
              No active orders found.
            </Typography>
          )}
        </Box>
      </Box>

      {/* Checkout Completion Modal */}
      <Dialog
        open={checkoutComplete}
        onClose={() => setCheckoutComplete(false)}
      >
        <DialogTitle>Checkout Complete!</DialogTitle>
        <DialogContent>
          <Typography>
            Your order has been successfully checked out. You can view your
            orders or return to the home page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCheckoutComplete(false);
              navigate('/orders');
            }}
            color="primary"
            variant="outlined"
            sx={{
              fontFamily: 'Gotham Medium',
              borderColor: '#0e9c9b',
              color: '#0e9c9b',
              borderRadius: '25px',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#e0f7f6',
              },
            }}
          >
            Go to Orders
          </Button>
          <Button
            onClick={() => {
              setCheckoutComplete(false);
              navigate('/home');
            }}
            color="primary"
            variant="contained"
            sx={{
              fontFamily: 'Gotham Medium',
              backgroundColor: '#0e9c9b',
              borderRadius: '25px',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#0D8A89',
                boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.15)',
              },
            }}
          >
            Go Home
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Checkout;
