import React from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SalesNavBar from './SalesNavBar';
import Footer from './Footer';

// Styled components for consistent styling
const Section = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
}));

const StepTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Gotham Medium Bold, Arial, sans-serif',
  fontSize: '2rem',
  color: '#0E9C9B',
  marginBottom: theme.spacing(2),
}));

const StepDescription = styled(Typography)(({ theme }) => ({
  fontFamily: 'Gotham Light, Arial, sans-serif',
  fontSize: '1.2rem',
  color: '#555555',
  marginBottom: theme.spacing(4),
}));

const StepItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const CTAButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
  paddingX: 4,
  paddingY: 1.5,
  borderRadius: '50px',
  textTransform: 'none',
  fontFamily: 'Gotham Book, Arial, sans-serif',
  fontWeight: 'bold',
  backgroundColor: '#0E9C9B',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#0C8B8A',
  },
}));

const HowItWorks = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <Box>
      {/* Header */}
      <SalesNavBar />

      {/* Main Content */}
      <Container maxWidth="lg">
        {/* Page Title */}
        <Section>
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontFamily: 'Gotham Medium Bold, Arial, sans-serif',
              textAlign: 'center',
              color: '#131616',
              marginBottom: 4,
            }}
          >
            Ordering Made Easy as Pie
          </Typography>
        </Section>

        {/* Steps List */}
        <Section>
          <Grid container spacing={4}>
            {/* Step 1 */}
            <Grid item xs={12} md={6}>
              <StepItem>
                <StepTitle>Create Your Account</StepTitle>
                <StepDescription>
                  Sign up and tell us about your restaurant.
                </StepDescription>
              </StepItem>
            </Grid>

            {/* Step 2 */}
            <Grid item xs={12} md={6}>
              <StepItem>
                <StepTitle>Browse Suppliers</StepTitle>
                <StepDescription>
                  Access a wide range of suppliers, all vetted and trusted.
                </StepDescription>
              </StepItem>
            </Grid>

            {/* Step 3 */}
            <Grid item xs={12} md={6}>
              <StepItem>
                <StepTitle>Place Orders</StepTitle>
                <StepDescription>
                  Build your order from multiple suppliers in one cart.
                </StepDescription>
              </StepItem>
            </Grid>

            {/* Step 4 */}
            <Grid item xs={12} md={6}>
              <StepItem>
                <StepTitle>Save and Repeat</StepTitle>
                <StepDescription>
                  Place your order. Save some serious dough. Rinse and repeat.
                </StepDescription>
              </StepItem>
            </Grid>
          </Grid>
        </Section>

        {/* Call to Action */}
        <Section>
          <Box sx={{ textAlign: 'center' }}>
            <CTAButton onClick={() => navigate('/get-started')}>Get Started</CTAButton>
          </Box>
        </Section>
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default HowItWorks;