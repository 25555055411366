// More.js
import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Divider, IconButton, Box, Button, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TopNavBar from './TopNavBar';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';

function More() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const menuItems = [
    { text: 'Returns', icon: <AssignmentReturnIcon />, route: '/returns', fontWeight: 'bold' },
  ];

  const handleSignOut = () => {
    // Implement sign-out logic, e.g., clear auth tokens
    navigate('/');
  };

  return (
    <div>
      <TopNavBar title="More" />
      {/* Main Content Wrapper */}
      <Box
        sx={{
          paddingTop: '0px', // Set top padding to 0px
          paddingLeft: isDesktop ? '240px' : '0', // Adjust padding for SideNavBar
          transition: 'padding-left 0.3s', // Smooth transition for padding
        }}
      >
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={item.text}>
              <ListItem button onClick={() => navigate(item.route)}>
                <ListItemIcon sx={{ color: '#0e9c9b' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    fontFamily: 'Gotham Medium', // Bold font for 'Returns'
                    fontSize: '16px',
                    color: '#3A3F3F', // Dark gray color for text
                  }}
                />
                <IconButton edge="end">
                  <ArrowForwardIosIcon sx={{ color: '#bbbbbb' }} />
                </IconButton>
              </ListItem>
              {index < menuItems.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>

        <Box sx={{ padding: '16px', textAlign: 'center' }}>
          <Typography
            sx={{
              fontFamily: 'Gotham Light', // Lighter font for the text
              fontSize: '16px',
              color: '#3A3F3F',
              marginBottom: '16px',
            }}
          >
            If you have any questions, need help, want to request a feature, etc.,
            email support@fare.food.
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ExitToAppIcon />}
            onClick={handleSignOut}
            sx={{
              borderColor: '#0e9c9b',
              color: '#0e9c9b',
              fontFamily: 'Gotham Medium Bold', // Button font is still bold
              '&:hover': {
                borderColor: '#0e9c9b',
                backgroundColor: '#f5f5f5',
              },
            }}
          >
            Sign Out
          </Button>
        </Box>
      </Box>
    </div>
  );
}

export default More;
