// src/CartContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext'; // Import AuthContext

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const { user, isAuthenticated } = useContext(AuthContext); // Access user and isAuthenticated
  const navigate = useNavigate();

  // Fetch Cart Items
  const fetchCartItems = async () => {
    if (!isAuthenticated || !user) {
      console.error('User is not authenticated.');
      setCartItems([]);
      return;
    }

    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');

      if (!authToken || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      const headers = { Authorization: `Bearer ${authToken}` };
      const params = { restaurant_id: user.restaurant_id };

      const response = await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurantcartitems',
        { headers, params }
      );

      console.log('Fetched Cart Items:', response.data);

      setCartItems(response.data);
    } catch (error) {
      console.error('Error fetching cart items:', error.response || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchCartItems();
    } else {
      setCartItems([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user]);

  useEffect(() => {
    let subtotalAmount = 0;
    cartItems.forEach((item) => {
      subtotalAmount += item.total_price;
    });
    setSubtotal(subtotalAmount);
  }, [cartItems]);

  // Handle Delete Item
  const handleDeleteItem = async (orderitem_id) => {
    try {
      const authToken = localStorage.getItem('authToken');

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      const headers = {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      };

      // Log the orderitem_id being deleted
      console.log(`Attempting to delete item with orderitem_id: ${orderitem_id}`);

      const response = await axios.delete(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/delete_order_item_from_cart',
        {
          headers,
          data: {
            orderitem_id: orderitem_id,
          },
        }
      );

      console.log('Delete API Response:', response.data);

      // Check if deletion was successful based on response
      if (response.status === 200 || response.status === 204) {
        console.log('Item deleted successfully.');
      } else {
        console.warn('Unexpected response status:', response.status);
      }

      // Update MOQ tracker if necessary
      await axios.get(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/update_MOQ_tracker',
        {
          headers,
          params: {
            restaurantidfilter: user.restaurant_id,
          },
        }
      );

      await fetchCartItems();
    } catch (error) {
      console.error('Error deleting cart item:', error.response || error.message);
      // Provide more detailed feedback to the user
      if (error.response) {
        // Server responded with a status other than 2xx
        alert(`Failed to delete item: ${error.response.data.message || 'Server Error'}`);
      } else if (error.request) {
        // Request was made but no response received
        alert('No response from server. Please try again later.');
      } else {
        // Something else happened
        alert('An unexpected error occurred while deleting the item.');
      }
    }
  };

  // Update Item Quantity
  const updateItemQuantity = async (item, newQuantity) => {
    if (isNaN(newQuantity) || newQuantity < 0) {
      return;
    }

    try {
      if (newQuantity === 0) {
        await handleDeleteItem(item.orderitem_id);
      } else {
        const authToken = localStorage.getItem('authToken');

        if (!authToken || !user || !user.id) {
          console.error('Authentication token or user data not found.');
          navigate('/');
          return;
        }

        const headers = {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        };

        const data = {
          user_id: user.id,
          quantity: newQuantity,
          product_id: item._product.id,
        };

        console.log(`Updating item ${item.orderitem_id} to quantity ${newQuantity}`);

        const response = await axios.post(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_to_cart_react',
          data,
          { headers }
        );

        console.log('Add to Cart API Response:', response.data);

        await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/update_MOQ_tracker',
          {
            headers,
            params: {
              restaurantidfilter: user.restaurant_id,
            },
          }
        );

        await fetchCartItems();
      }
    } catch (error) {
      console.error('Error updating quantity:', error.response || error.message);
      alert('An error occurred while updating the quantity.');
    }
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        subtotal,
        loading,
        fetchCartItems,
        handleDeleteItem,
        updateItemQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
