// src/components/BlogPage.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Chip,
  Container,
} from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Footer from './Footer';
import SalesNavBar from './SalesNavBar';
import { Helmet } from 'react-helmet';

// Import the Poppins font family
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';

const BlogPage = () => {
  const { handle } = useParams(); // Extract handle from URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/bloghandle',
          {
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              Handle: handle, // Using Handle as query parameter
            },
          }
        );

        // Check if the response data is an object with the expected properties
        if (response.data && response.data.Handle) {
          setBlog(response.data); // Set blog to the response object
        } else {
          setError('Blog post not found.');
        }
      } catch (err) {
        console.error('Error fetching blog:', err);
        setError('Failed to load the blog post. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [handle]);

  return (
    <Box>
      {/* Helmet for SEO */}
      <Helmet>
        <title>{blog ? `${blog.Title} | FareFood` : 'Loading...'}</title>
        <meta
          name="description"
          content={
            blog
              ? DOMPurify.sanitize(blog.Summary_HTML)
              : 'Loading blog post...'
          }
        />
        {/* Open Graph Meta Tags */}
        <meta
          property="og:title"
          content={blog ? `${blog.Title} | FareFood` : 'FareFood'}
        />
        <meta
          property="og:description"
          content={
            blog
              ? DOMPurify.sanitize(blog.Summary_HTML)
              : 'Loading blog post...'
          }
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`https://www.farefood.com/blogs/food-for-thought/${handle}`}
        />
        {blog && blog.Image_Src && (
          <meta property="og:image" content={blog.Image_Src} />
        )}
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={blog ? `${blog.Title} | FareFood` : 'FareFood'}
        />
        <meta
          name="twitter:description"
          content={
            blog
              ? DOMPurify.sanitize(blog.Summary_HTML)
              : 'Loading blog post...'
          }
        />
        {blog && blog.Image_Src && (
          <meta name="twitter:image" content={blog.Image_Src} />
        )}
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BlogPosting',
            headline: blog ? blog.Title : 'Loading...',
            description: blog ? DOMPurify.sanitize(blog.Summary_HTML) : '',
            image: blog ? blog.Image_Src : '',
            author: {
              '@type': 'Organization',
              name: 'FareFood',
            },
            datePublished: blog ? blog.Created_At : '',
            dateModified: blog ? blog.Updated_At : '',
            url: `https://www.farefood.com/blogs/food-for-thought/${handle}`,
          })}
        </script>
      </Helmet>

      {/* Header */}
      <SalesNavBar />

      {/* Main Content */}
      <Box sx={{ backgroundColor: '#f9f9f9' }}>
        <Container
          //maxWidth="md"
          sx={{
            paddingTop: 12,
            paddingBottom: 8,
            maxWidth: '1200px',
            width: '100%',
            paddingLeft: { xs: '14px', sm: '28px', md: '42px' }, // Decreased padding by 30%
            paddingRight: { xs: '14px', sm: '28px', md: '42px' },
          }}
        >
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
              <CircularProgress />
            </Box>
          )}

          {error && (
            <Typography
              variant="body1"
              color="error"
              align="center"
              sx={{ marginTop: 4 }}
            >
              {error}
            </Typography>
          )}

          {!loading && !error && blog && (
            <>
              {/* Blog Image */}
              {blog.Image_Src && (
                <Box
                  component="img"
                  src={blog.Image_Src}
                  alt={blog.Image_Alt_Text || blog.Title}
                  sx={{
                    width: '100%',
                    maxHeight: 500,
                    objectFit: 'cover',
                    marginBottom: 4,
                    borderRadius: '8px',
                  }}
                />
              )}

              {/* Blog Title */}
              <Typography
                variant="h3"
                component="h1"
                gutterBottom
                sx={{
                  color: '#131616',
                  textAlign: 'center',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: 750,
                }}
              >
                {blog.Title}
              </Typography>

              {/* Blog Body */}
              <Box
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  '& img': {
                    width: '100%',
                    maxWidth: '700px',
                    height: 'auto',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 2,
                    borderRadius: '4px',
                  },
                  fontFamily: 'Poppins, sans-serif',
                  fontSize: '1rem',
                  lineHeight: '1.6',
                  color: '#333333',
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(blog.Body_HTML),
                }}
              />

              {/* Tags */}
              {blog.Tags && (
                <Box textAlign="center" sx={{ marginTop: 2 }}>
                  {blog.Tags.split(',').slice(0, 10).map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.trim()}
                      size="small"
                      sx={{ marginRight: 0.5, marginBottom: 0.5 }}
                    />
                  ))}
                </Box>
              )}
            </>
          )}
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default BlogPage;
