// src/components/SpendSummary.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  Typography,
  Button,
  useTheme,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material'; // Import alpha function

// Function to format number as currency
const formatCurrency = (amount) => {
  if (typeof amount !== 'number') return '-';
  return amount.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0, // Remove decimal places
  });
};

const SpendSummary = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  // State variables for Spend Data
  const [totalSpend30Days, setTotalSpend30Days] = useState(null);
  const [totalSpend60Days, setTotalSpend60Days] = useState(null);
  const [totalSpend90Days, setTotalSpend90Days] = useState(null);
  const [totalSpend120Days, setTotalSpend120Days] = useState(null);
  const [totalSpend150Days, setTotalSpend150Days] = useState(null);
  const [totalSpend180Days, setTotalSpend180Days] = useState(null);
  const [spendLoading, setSpendLoading] = useState(true);
  const [spendError, setSpendError] = useState(null);

  // Helper function to get Restaurant ID from localStorage
  const getRestaurantId = () => {
    const userJson = localStorage.getItem('user');
    if (!userJson) {
      throw new Error('User data not found in local storage.');
    }

    let user;
    try {
      user = JSON.parse(userJson);
    } catch (parseError) {
      throw new Error('Failed to parse user data from local storage.');
    }

    const restaurantId = user.restaurant_id;
    if (!restaurantId) {
      throw new Error('Restaurant ID not found in user data.');
    }

    return restaurantId;
  };

  // Fetch Spend Data (Last 30, 60, 90, 120, 150, 180 Days)
  useEffect(() => {
    const fetchSpendData = async () => {
      try {
        const restaurantId = getRestaurantId();
        console.log('Fetching Spend Data for restaurant_id:', restaurantId);

        // Define all the spend endpoints
        const spendEndpoints = [
          { days: 30, url: `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/last30daysordertotal?restaurantidfilter=${encodeURIComponent(restaurantId)}` },
          { days: 60, url: `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/last60daysordertotal?restaurantidfilter=${encodeURIComponent(restaurantId)}` },
          { days: 90, url: `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/last90daysordertotal?restaurantidfilter=${encodeURIComponent(restaurantId)}` },
          { days: 120, url: `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/last120daysordertotal?restaurantidfilter=${encodeURIComponent(restaurantId)}` },
          { days: 150, url: `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/last150daysordertotal?restaurantidfilter=${encodeURIComponent(restaurantId)}` },
          { days: 180, url: `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/last180daysordertotal?restaurantidfilter=${encodeURIComponent(restaurantId)}` },
        ];

        // Fetch all spend data in parallel
        const spendResponses = await Promise.all(
          spendEndpoints.map((endpoint) =>
            fetch(endpoint.url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })
          )
        );

        // Check if all responses are OK
        const allOk = spendResponses.every((response) => response.ok);
        if (!allOk) {
          const errorTexts = await Promise.all(spendResponses.map((res) => res.text()));
          const errorMessages = spendResponses.map((res, index) => `Day ${spendEndpoints[index].days}: ${res.status} - ${errorTexts[index]}`).join(', ');
          throw new Error(`API request failed: ${errorMessages}`);
        }

        // Parse all responses as JSON
        const spendDataArray = await Promise.all(spendResponses.map((res) => res.json()));

        console.log('Spend Data:', spendDataArray);

        // Assign data to respective state variables
        spendDataArray.forEach((data, index) => {
          const days = spendEndpoints[index].days;
          const amount = data.order_order_amount || 0;
          switch (days) {
            case 30:
              setTotalSpend30Days(amount);
              break;
            case 60:
              setTotalSpend60Days(amount);
              break;
            case 90:
              setTotalSpend90Days(amount);
              break;
            case 120:
              setTotalSpend120Days(amount);
              break;
            case 150:
              setTotalSpend150Days(amount);
              break;
            case 180:
              setTotalSpend180Days(amount);
              break;
            default:
              break;
          }
        });

        setSpendLoading(false);
      } catch (err) {
        console.error('Error fetching Spend Data:', err);
        setSpendError(err.message || 'An unexpected error occurred while fetching spend data.');
        setSpendLoading(false);
      }
    };

    fetchSpendData();
  }, []);

  // Prepare Spend Data for Chart
  const spendData = [
    { name: '30 Days', spend: totalSpend30Days || 0 },
    { name: '60 Days', spend: totalSpend60Days || 0 },
    { name: '90 Days', spend: totalSpend90Days || 0 },
    { name: '120 Days', spend: totalSpend120Days || 0 },
    { name: '150 Days', spend: totalSpend150Days || 0 },
    { name: '180 Days', spend: totalSpend180Days || 0 },
  ];

  // Check if all spend data are zero
  const allSpendZero = spendData.every((data) => data.spend === 0);

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: 0,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(2),
        minHeight: '300px',
        border: '1px solid #ccc',
      }}
    >
      {spendLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '250px',
          }}
        >
          <CircularProgress />
        </Box>
      ) : spendError ? (
        <Alert severity="error">
          {`There was an error fetching your spend data: ${spendError}. Please try again later.`}
        </Alert>
      ) : allSpendZero ? (
        // Customized Empty State for Spend Summary
        <Box
          sx={{
            textAlign: 'center',
            padding: theme.spacing(4),
            backgroundColor: alpha(theme.palette.info.light, 0.1), // Adjusted background color
            borderRadius: theme.shape.borderRadius,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Spend Summary Coming Soon
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Your spend summary will appear here once you've made some purchases. Start placing orders to track your spending effortlessly.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/shop')}
            sx={{
              marginTop: theme.spacing(2),
              textTransform: 'none',
            }}
          >
            Browse Products
          </Button>
        </Box>
      ) : (
        <CardContent>
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: theme.typography.h6.fontWeight,
              textTransform: 'capitalize',
              marginBottom: theme.spacing(2),
            }}
          >
            Spend Summary
          </Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={spendData}
              margin={{
                top: 20,
                right: 30,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                tick={{ fontFamily: theme.typography.fontFamily }}
              />
              <YAxis
                tickFormatter={(value) => `$${(value / 1000).toFixed(0)}k`}
                tick={{ fontFamily: theme.typography.fontFamily }}
              />
              <RechartsTooltip
                formatter={(value) => formatCurrency(value)}
                labelStyle={{ fontFamily: theme.typography.fontFamily }}
                contentStyle={{ fontFamily: theme.typography.fontFamily }}
              />
              <Bar dataKey="spend" fill={theme.palette.primary.main} name="Spend" />
            </BarChart>
          </ResponsiveContainer>
        </CardContent>
      )}
    </Card>
  );
};

export default SpendSummary;
