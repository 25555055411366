// src/components/Press.js

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Container,
} from '@mui/material';
import Footer from './Footer';
import SalesNavBar from './SalesNavBar';
import { Helmet } from 'react-helmet';

// Import the Poppins font family
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';

// Import the logos
import StPeteCatalystLogo from './assets/StPeteCatalystlog.png';
import VoyageTampaLogo from './assets/Voyage-Tampa-Staff_avatar_1566358308-240x240-1-120x120.jpg';
import PRLogo from './assets/logo-pr-base.png';
import HypepotamusLogo from './assets/hypepotamuslogo.png';
import TBBJLogo from './assets/TBBJ.png';

const pressArticles = [
  {
    date: 'November 4, 2024',
    outlet: 'St. Pete Catalyst',
    title: 'Local Entrepreneur Launches App to Cut Restaurant Costs',
    url: 'https://stpetecatalyst.com/local-entrepreneur-launches-app-to-cut-restaurant-costs/',
    image: StPeteCatalystLogo,
  },
  {
    date: 'October 29, 2024',
    outlet: 'Voyage Tampa',
    title: 'Hidden Gems: Meet Michael Otis of FareFood',
    url: 'https://voyagetampa.com/interview/hidden-gems-meet-michael-otis-of-farefood',
    image: VoyageTampaLogo,
  },
  {
    date: 'October 8, 2024',
    outlet: 'PR.com',
    title:
      'Local Entrepreneur Cooks Up Business to Fight Rising Restaurant Costs',
    url: 'https://www.pr.com/press-release/921617',
    image: PRLogo,
  },
  {
    date: 'October 6, 2024',
    outlet: 'Hypepotamus',
    title:
      'Following Acquisition, This Tampa Founder Is Ready to Help Restaurants Combat Rising Food Costs With New Startup',
    url: 'https://hypepotamus.com/startup-news/farefood-launches-in-tampa/',
    image: HypepotamusLogo,
  },
  {
    date: 'October 3, 2024',
    outlet: 'Tampa Bay Business Journal',
    title:
      'After Procoto Success, Michael Otis Sets Sights on Curbing Restaurant Costs with New App',
    url: 'https://www.bizjournals.com/tampabay/inno/stories/news/2024/10/03/farefood-otis-food-prices-restaurant-supply.html',
    image: TBBJLogo,
  },
];

const Press = () => {
  return (
    <Box>
      {/* Helmet for SEO */}
      <Helmet>
        <title>FareFood | Press</title>
        <meta
          name="description"
          content="Read the latest press coverage about FareFood and how we're revolutionizing the restaurant industry."
        />
        {/* ... other meta tags ... */}
      </Helmet>

      {/* Header */}
      <SalesNavBar />

      {/* Main Content */}
      <Box sx={{ backgroundColor: '#FFFFFF' }}>
        <Container maxWidth="lg" sx={{ paddingTop: 12, paddingBottom: 8 }}>
          {/* Press Header */}
          <Box sx={{ marginTop: 8 }}>
            <Typography
              variant="h3"
              component="h1"
              align="center"
              gutterBottom
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '2.019rem',
                fontWeight: 500,
                color: '#131616',
                marginBottom: 2,
              }}
            >
              Press
            </Typography>
            <Typography
              align="center"
              gutterBottom
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: '1.2rem',
                color: '#555555',
                marginBottom: 4,
                fontWeight: 400,
              }}
            >
              Read the latest press coverage about FareFood.
            </Typography>
          </Box>

          {/* Press Articles */}
          <Grid container spacing={4}>
            {pressArticles.map((article, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card sx={{ width: '100%' }}>
                  <CardActionArea
                    component="a"
                    href={article.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* Article Image */}
                    {article.image && (
                      <CardMedia
                        component="img"
                        sx={{
                          width: '100%',
                          height: { xs: 225, sm: 225, md: 225 },
                          objectFit: 'contain',
                          borderTopLeftRadius: '4px',
                          borderTopRightRadius: '4px',
                          backgroundColor: '#f0f0f0',
                        }}
                        image={article.image}
                        alt={article.title}
                        loading="lazy"
                      />
                    )}
                    <CardContent>
                      {/* News Outlet */}
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{
                          fontWeight: 'bold',
                          color: '#000000',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                      >
                        {article.outlet}
                      </Typography>
                      {/* Article Date */}
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                        sx={{ fontFamily: 'Poppins, sans-serif' }}
                      >
                        {article.date}
                      </Typography>
                      {/* Article Title */}
                      <Typography
                        variant="h5"
                        component="div"
                        gutterBottom
                        sx={{
                          color: '#0E9C9B',
                          fontFamily: 'Poppins, sans-serif',
                          fontWeight: 500,
                        }}
                      >
                        {article.title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default Press;
