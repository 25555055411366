// src/components/FilterPanel.js

import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Chip,
} from '@mui/material';
import { useTheme, styled, alpha } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 3, // Reduced border radius for smaller size
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  marginBottom: theme.spacing(1), // Reduced margin for smaller size
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: theme.spacing(0, 1.5), // Reduced padding for smaller size
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  fontSize: '0.875rem', // Reduced font size
  '& .MuiInputBase-input': {
    padding: theme.spacing(0.5, 0.5, 0.5, 0), // Reduced padding
    paddingLeft: `calc(1em + ${theme.spacing(3)})`, // Adjusted padding to accommodate smaller icon
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function FilterPanel({
  categories,
  selectedCategory,
  setSelectedCategory,
  subcategories,
  selectedSubcategory,
  setSelectedSubcategory,
  selectedVendor,
  setSelectedVendor,
  uniqueBrands,
  selectedBrands,
  setSelectedBrands,
  clearAllFilters,
}) {
  const theme = useTheme();
  const [vendors, setVendors] = useState([]);

  // States for search inputs
  const [brandSearch, setBrandSearch] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const userJson = localStorage.getItem('user');
        if (!userJson) {
          throw new Error('User data not found in local storage.');
        }

        const user = JSON.parse(userJson);
        const restaurantId = user.restaurant_id;
        if (!restaurantId) {
          throw new Error('Restaurant ID not found in user data.');
        }

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors?restaurant_id=${restaurantId}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch vendors');
        }
        const data = await response.json();
        const fetchedVendors = data.map((item) => item._vendor);
        setVendors(fetchedVendors);
      } catch (error) {
        console.error('Error fetching vendors:', error);
      }
    };

    fetchVendors();
  }, []);

  // Filtered options based on search
  const filteredBrands = uniqueBrands.filter((brand) =>
    brand.toLowerCase().includes(brandSearch.toLowerCase())
  );

  const filteredVendors = vendors.filter((vendor) =>
    vendor.Short_Name.toLowerCase().includes(vendorSearch.toLowerCase())
  );

  // Function to truncate text to a maximum length
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return `${text.slice(0, maxLength)}...`;
  };

  return (
    <Box>
      {/* Filter Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: theme.spacing(2),
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'light', // Light font weight
            color: theme.palette.grey[600], // Light gray color
            fontSize: '0.875rem', // Same font size as "Clear All"
          }}
        >
          Filter By
        </Typography>
        <Button
          variant="text"
          onClick={clearAllFilters}
          sx={{
            textTransform: 'none',
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular, // Regular font weight
            fontSize: '0.875rem', // Consistent font size
          }}
        >
          Clear All
        </Button>
      </Box>

      {/* Category Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="category-content"
          id="category-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px', // Reduced minHeight for smaller size
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular', // Regular font weight
              color: 'black',
              fontSize: '1rem', // Adjusted font size
            }}
          >
            Category
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {categories.map((category) => (
              <FormControlLabel
                key={category.id}
                control={
                  <Checkbox
                    checked={selectedCategory === category.id}
                    onChange={() => {
                      if (selectedCategory === category.id) {
                        setSelectedCategory(0); // Deselect if already selected
                      } else {
                        setSelectedCategory(category.id);
                      }
                    }}
                  />
                }
                label={truncateText(category.name, 20)} // Truncate category names if needed
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Subcategory Filter */}
      {selectedCategory !== 0 && (
        <Accordion
          sx={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            '&:before': { display: 'none' },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
            aria-controls="subcategory-content"
            id="subcategory-header"
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
              minHeight: '48px', // Reduced minHeight for smaller size
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: 'regular', // Regular font weight
                color: 'black',
                fontSize: '1rem', // Adjusted font size
              }}
            >
              Subcategory
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              {subcategories.map((subcategory) => (
                <FormControlLabel
                  key={subcategory.id}
                  control={
                    <Checkbox
                      checked={selectedSubcategory === subcategory.id}
                      onChange={() => {
                        if (selectedSubcategory === subcategory.id) {
                          setSelectedSubcategory(0); // Deselect if already selected
                        } else {
                          setSelectedSubcategory(subcategory.id);
                        }
                      }}
                    />
                  }
                  label={truncateText(subcategory.name, 20)} // Truncate subcategory names if needed
                  sx={{ fontFamily: theme.typography.fontFamily }}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Vendor Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="vendor-content"
          id="vendor-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px', // Reduced minHeight for smaller size
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular', // Regular font weight
              color: 'black',
              fontSize: '1rem', // Adjusted font size
            }}
          >
            Vendor
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Styled Search Bar */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ 'aria-label': 'search vendors' }}
              value={vendorSearch}
              onChange={(e) => setVendorSearch(e.target.value)}
            />
          </Search>
          <FormGroup>
            {filteredVendors.map((vendor) => (
              <FormControlLabel
                key={vendor.id}
                control={
                  <Checkbox
                    checked={selectedVendor.includes(vendor.id)}
                    onChange={() => {
                      if (selectedVendor.includes(vendor.id)) {
                        setSelectedVendor(selectedVendor.filter((id) => id !== vendor.id));
                      } else {
                        setSelectedVendor([...selectedVendor, vendor.id]);
                      }
                    }}
                  />
                }
                label={truncateText(vendor.Short_Name, 20)} // Truncate vendor names to 20 characters
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* Brand Filter */}
      <Accordion
        sx={{
          boxShadow: 'none',
          backgroundColor: 'transparent',
          '&:before': { display: 'none' },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
          aria-controls="brand-content"
          id="brand-header"
          sx={{
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: '48px', // Reduced minHeight for smaller size
            '& .MuiAccordionSummary-content': {
              margin: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontWeight: 'regular', // Regular font weight
              color: 'black',
              fontSize: '1rem', // Adjusted font size
            }}
          >
            Brand
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* Styled Search Bar */}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ 'aria-label': 'search brands' }}
              value={brandSearch}
              onChange={(e) => setBrandSearch(e.target.value)}
            />
          </Search>
          <FormGroup>
            {filteredBrands.map((brand) => (
              <FormControlLabel
                key={brand}
                control={
                  <Checkbox
                    checked={selectedBrands.includes(brand)}
                    onChange={() => {
                      if (selectedBrands.includes(brand)) {
                        setSelectedBrands(selectedBrands.filter((b) => b !== brand));
                      } else {
                        setSelectedBrands([...selectedBrands, brand]);
                      }
                    }}
                  />
                }
                label={truncateText(brand, 20)} // Truncate brand names to 20 characters
                sx={{ fontFamily: theme.typography.fontFamily }}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default FilterPanel;
