// src/Returns.js

import React, { useState, useEffect, useCallback } from 'react';
import TopNavBar from './TopNavBar';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  IconButton,
  Collapse,
  Box,
  Button,
  Divider,
  Grid,
  List as MUIList,
  ListItemButton,
  ListItemText,
  Drawer,
  InputBase,
  useMediaQuery,
  useTheme,
  Container,
  Tooltip,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search as SearchIcon,
  FilterList as FilterListIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';

// Styled Components for Search Bar
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius * 5,
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
  width: '100%',
  maxWidth: 500,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

function Returns() {
  const [returnsList, setReturnsList] = useState([]);
  const [filteredReturns, setFilteredReturns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('0'); // Default to 'All Returns'
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const apiEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets';

  // Define statusOptions for returns
  const statusOptions = [
    { label: 'All Returns', value: '0' },
    { label: 'Initiated', value: '1' },
    { label: 'In Progress', value: '2' },
    { label: 'Completed', value: '3' },
  ];

  // Debounce the search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Filter returns based on the debounced search term
  useEffect(() => {
    if (debouncedSearchTerm) {
      const filtered = returnsList.filter((ticket) =>
        ticket.subject.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
      );
      setFilteredReturns(filtered);
    } else {
      setFilteredReturns(returnsList);
    }
  }, [debouncedSearchTerm, returnsList]);

  // Fetch Returns
  const fetchReturns = useCallback(
    async (newOffset = 0) => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const user = JSON.parse(localStorage.getItem('user'));

        if (!authToken || !user) {
          console.error('Authentication token or user data not found in localStorage.');
          navigate('/');
          return;
        }

        const params = {
          useridfilter: user.id,
          tickettypefilter: 1, // Assuming '1' corresponds to Returns
          limit: 1000,
          offset: newOffset,
          statusfilter: parseInt(selectedStatus),
        };

        const headers = { Authorization: `Bearer ${authToken}` };
        const response = await axios.get(apiEndpoint, { params, headers });

        const fetchedReturns = response.data;

        if (Array.isArray(fetchedReturns) && fetchedReturns.length > 0) {
          if (newOffset === 0) {
            setReturnsList(fetchedReturns);
          } else {
            setReturnsList((prevReturns) => [...prevReturns, ...fetchedReturns]);
          }

          setHasMore(fetchedReturns.length === params.limit);
        } else {
          setHasMore(false);
          setReturnsList([]);
        }
      } catch (error) {
        console.error('Error fetching returns:', error);
        setReturnsList([]);
      } finally {
        setLoading(false);
      }
    },
    [navigate, apiEndpoint, selectedStatus]
  );

  useEffect(() => {
    fetchReturns(0);
  }, [fetchReturns]);

  const handleLoadMore = () => {
    const newOffset = offset + 10;
    setOffset(newOffset);
    fetchReturns(newOffset);
  };

  const toggleFilterDrawer = (open) => () => {
    setIsFilterDrawerOpen(open);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Sticky TopNavBar without title */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: theme.zIndex.appBar }}>
        <TopNavBar />
      </Box>

      {/* Main Content Area */}
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: theme.spacing(4),
          flexGrow: 1,
        }}
      >
        {/* Title */}
        <Typography
          variant="h5"
          sx={{
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(8),
            color: 'primary',
          }}
        >
          {statusOptions.find((status) => status.value === selectedStatus)?.label}
        </Typography>

        <Grid container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'row' }}>
          {/* Filter Panel for Desktop */}
          {isDesktop && (
            <Grid
              item
              sm={3}
              md={2}
              sx={{
                borderRight: `1px solid ${theme.palette.divider}`,
                height: 'calc(100vh - 100px)',
                padding: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                position: 'sticky',
                top: theme.spacing(4),
                overflowY: 'auto',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: 'black',
                  fontFamily: theme.typography.fontFamily,
                  marginBottom: theme.spacing(1),
                }}
              >
                Filter By Status
              </Typography>
              <Divider sx={{ marginBottom: theme.spacing(1) }} />
              <MUIList component="nav">
                {statusOptions.map((status) => (
                  <ListItemButton
                    key={status.value}
                    selected={selectedStatus === status.value}
                    onClick={() => {
                      setSelectedStatus(status.value);
                      setReturnsList([]);
                      setOffset(0);
                      setHasMore(true);
                    }}
                    sx={{
                      borderRadius: theme.shape.borderRadius,
                      marginBottom: theme.spacing(0.5),
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            color: 'black',
                          }}
                        >
                          {status.label}
                        </Typography>
                      }
                    />
                  </ListItemButton>
                ))}

                {/* Orders Link without Icon */}
                <ListItemButton
                  onClick={() => navigate('/orders')}
                  selected={location.pathname === '/orders'}
                  sx={{
                    borderRadius: theme.shape.borderRadius,
                    marginTop: theme.spacing(2),
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: theme.typography.fontFamily,
                          color: 'black',
                        }}
                      >
                        Orders
                      </Typography>
                    }
                  />
                </ListItemButton>
              </MUIList>
            </Grid>
          )}

          {/* Main Content */}
          <Grid
            item
            xs={12}
            sm={isDesktop ? 9 : 12}
            md={isDesktop ? 10 : 12}
            sx={{
              padding: theme.spacing(2),
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
            }}
          >
            {/* Header with Search Bar and Filter Icon */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: isMobile ? 'space-between' : 'space-between',
                alignItems: isMobile ? 'flex-start' : 'center',
                marginBottom: theme.spacing(2),
                gap: theme.spacing(2),
              }}
            >
              {/* Search Bar and Filter Icon */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: isMobile ? '100%' : 'auto',
                  gap: theme.spacing(1),
                }}
              >
                {/* Search Bar */}
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search Returns…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Search>

                {/* Filter Icon, only show on mobile */}
                {isMobile && (
                  <IconButton
                    aria-label="open filter"
                    onClick={toggleFilterDrawer(true)}
                    sx={{
                      color: theme.palette.primary.main,
                      backgroundColor: theme.palette.background.paper,
                      border: `1px solid ${theme.palette.primary.main}`,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                      },
                      borderRadius: '50%',
                      width: '40px',
                      height: '40px',
                    }}
                  >
                    <FilterListIcon />
                  </IconButton>
                )}
              </Box>
            </Box>

            {/* Loading Indicator */}
            {loading && offset === 0 ? (
              <Box sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}>
                <CircularProgress />
              </Box>
            ) : filteredReturns.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  width: '100%',
                  margin: '0 auto',
                  maxWidth: '100%',
                  overflowX: 'hidden',
                }}
              >
                <Table>
                  <TableHead sx={{ backgroundColor: theme.palette.background.paper }}>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: 'black',
                          fontFamily: theme.typography.fontFamily,
                          fontWeight: 'bold',
                        }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'black',
                          fontFamily: theme.typography.fontFamily,
                          fontWeight: 'bold',
                        }}
                      >
                        Subject
                      </TableCell>
                      <TableCell
                        sx={{
                          color: 'black',
                          fontFamily: theme.typography.fontFamily,
                          fontWeight: 'bold',
                        }}
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredReturns.map((ticket) => (
                      <ReturnRow key={ticket.id} ticket={ticket} isDesktop={isDesktop} theme={theme} />
                    ))}
                  </TableBody>
                </Table>
                {hasMore && (
                  <Box
                    sx={{
                      textAlign: 'center',
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(3),
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        fontFamily: theme.typography.fontFamily,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.dark,
                        },
                      }}
                      onClick={handleLoadMore}
                    >
                      Load More
                    </Button>
                  </Box>
                )}
              </TableContainer>
            ) : (
              <Typography
                variant="body1"
                align="center"
                sx={{ marginTop: theme.spacing(2), fontFamily: theme.typography.fontFamily }}
              >
                No returns found.
              </Typography>
            )}

            {/* Filter Drawer for Mobile */}
            <Drawer
              anchor="right"
              open={isFilterDrawerOpen}
              onClose={toggleFilterDrawer(false)}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <Box
                sx={{
                  width: 250,
                  padding: theme.spacing(2),
                }}
                role="presentation"
              >
                {/* Close Button */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton onClick={toggleFilterDrawer(false)} aria-label="close filter">
                    <CloseIcon />
                  </IconButton>
                </Box>

                {/* Filter Options */}
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    marginBottom: theme.spacing(1),
                    color: 'black',
                  }}
                >
                  Filter Returns
                </Typography>
                <Divider sx={{ marginBottom: theme.spacing(1) }} />
                <MUIList component="nav">
                  {statusOptions.map((status) => (
                    <ListItemButton
                      key={status.value}
                      selected={selectedStatus === status.value}
                      onClick={() => {
                        setSelectedStatus(status.value);
                        setReturnsList([]);
                        setOffset(0);
                        setHasMore(true);
                        setIsFilterDrawerOpen(false);
                      }}
                      sx={{
                        borderRadius: theme.shape.borderRadius,
                        marginBottom: theme.spacing(0.5),
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              color: 'black',
                            }}
                          >
                            {status.label}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}

                  {/* Orders Link without Icon */}
                  <ListItemButton
                    onClick={() => {
                      navigate('/orders');
                      setIsFilterDrawerOpen(false);
                    }}
                    selected={location.pathname === '/orders'}
                    sx={{
                      borderRadius: theme.shape.borderRadius,
                      marginTop: theme.spacing(2),
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            color: 'black',
                          }}
                        >
                          Orders
                        </Typography>
                      }
                    />
                  </ListItemButton>
                </MUIList>
              </Box>
            </Drawer>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

// Separate ReturnRow component for better readability and maintainability
function ReturnRow({ ticket, isDesktop, theme }) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const formattedDate = ticket.created_at
    ? moment(ticket.created_at).format('YYYY-MM-DD')
    : 'Date not available';

  const handleRowClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        hover
        onClick={handleRowClick}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            transform: 'scale(1.01)',
            transition: 'transform 0.2s ease-in-out',
          },
        }}
      >
        <TableCell sx={{ fontFamily: theme.typography.fontFamily }}>{formattedDate}</TableCell>
        <TableCell sx={{ fontFamily: theme.typography.fontFamily }}>{ticket.subject}</TableCell>
        <TableCell sx={{ fontFamily: theme.typography.fontFamily }}>
          {ticket.status || 'Initiated'}
        </TableCell>
      </TableRow>
      {/* Collapsible Row for Return Details */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              margin={1}
              sx={{
                overflowX: 'auto',
                padding: isDesktop ? theme.spacing(0, 1) : 0,
                width: '100%',
              }}
            >
              {/* Return Details */}
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                sx={{ fontFamily: theme.typography.fontFamily }}
              >
                Return Details
              </Typography>
              <Divider sx={{ marginBottom: '12px' }} />
              <Typography
                variant="body2"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  color: '#555555',
                }}
              >
                <strong>Subject:</strong> {ticket.subject}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  color: '#555555',
                }}
              >
                <strong>Description:</strong> {ticket.description}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  color: '#555555',
                }}
              >
                <strong>Date Created:</strong> {formattedDate}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  color: '#555555',
                }}
              >
                <strong>Status:</strong> {ticket.status || 'Initiated'}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Returns;
