// src/pages/Home.js

import React, { useState, useEffect, useContext } from 'react';
import TopNavBar from './TopNavBar';
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  Button,
  LinearProgress,
  Divider,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Masonry } from '@mui/lab'; // Import Masonry from MUI Lab
import { useMediaQuery, useTheme, alpha } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WarningIcon from '@mui/icons-material/Warning';
import axios from 'axios';

// Import Recharts components
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts';

// Import vendor logos
import GFSLogo from './assets/GordonFoodService_Logo_4c.webp';
import USFoodsLogo from './assets/US_Foods_logo.png';
import SyscoLogo from './assets/Sysco-Logo.svg';
import PFGLogo from './assets/PFGLogo.jpg';
import SpendSummary from './SpendSummary'; // Adjust the path based on your project structure

// Import ProductDetailsModal and AddToCartButton
import ProductDetailsModal from './ProductDetailsModal';
import AddToCartButton from './AddToCartButton';

// Import ProductCard
import ProductCard from './ProductCard'; // Adjust the path if necessary

// Import CartContext
import { CartContext } from './CartContext'; // Adjust the path according to your project structure

// Import the newly created LowStockComponent and MOQComponent
import LowStockComponent from './LowStockComponent'; // Adjust the path as needed
import MOQComponent from './MOQComponent'; // Adjust the path as needed

function Home() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Access CartContext
  const { subtotal } = useContext(CartContext);

  // State variables for Active Orders
  const [recentlySubmittedOrders, setRecentlySubmittedOrders] = useState([]);
  const [ordersInProgress, setOrdersInProgress] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersError, setOrdersError] = useState(null);

  // State variables for Low Stock Inventory
  const [lowStockItems, setLowStockItems] = useState([]);
  const [lowStockLoading, setLowStockLoading] = useState(true);
  const [lowStockError, setLowStockError] = useState(null);

  // State variables for MOQ Tracker
  const [restaurantData, setRestaurantData] = useState(null);
  const [moqLoading, setMoqLoading] = useState(false);
  const [moqError, setMoqError] = useState(null); // Added moqError state

  // State variables for Product Modal and Multiplier
  const [selectedItem, setSelectedItem] = useState(null);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [multiplier, setMultiplier] = useState(1);

  // New State Variables for Recent Items
  const [recentItems, setRecentItems] = useState([]);
  const [recentItemsLoading, setRecentItemsLoading] = useState(true);
  const [recentItemsError, setRecentItemsError] = useState(null);

  // Helper Functions
  const getRestaurantId = () => {
    const userJson = localStorage.getItem('user');
    if (!userJson) {
      throw new Error('User data not found in local storage.');
    }

    let user;
    try {
      user = JSON.parse(userJson);
    } catch (parseError) {
      throw new Error('Failed to parse user data from local storage.');
    }

    const restaurantId = user.restaurant_id;
    if (!restaurantId) {
      throw new Error('Restaurant ID not found in user data.');
    }

    return restaurantId;
  };

  const getUserId = () => {
    const userJson = localStorage.getItem('user');
    if (!userJson) {
      throw new Error('User data not found in local storage.');
    }

    let user;
    try {
      user = JSON.parse(userJson);
    } catch (parseError) {
      throw new Error('Failed to parse user data from local storage.');
    }

    const userId = user.id;
    if (!userId) {
      throw new Error('User ID not found in user data.');
    }

    return userId;
  };

  // Fetch Multiplier
  useEffect(() => {
    const fetchUserData = () => {
      const userData = localStorage.getItem('user');
      if (userData) {
        try {
          const parsedUser = JSON.parse(userData);
          const storedMultiplier = parseFloat(parsedUser.multiplier) || 1;
          setMultiplier(storedMultiplier);
          console.log('Multiplier retrieved from localStorage:', storedMultiplier);
        } catch (e) {
          console.error('Error parsing user data from local storage:', e);
          setMultiplier(1); // Default to 1 if error
        }
      } else {
        console.error('User data not found in localStorage.');
        setMultiplier(1); // Default to 1 if user data not found
      }
    };

    fetchUserData();
  }, []);

  // Define fetchInventory as a separate function
  const fetchInventory = async () => {
    setLowStockLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const user = JSON.parse(localStorage.getItem('user'));

      if (!authToken || !user || !user.restaurant_id) {
        console.error('Authentication token or user data not found.');
        navigate('/');
        return;
      }

      const params = {
        restaurantidfilter: user.restaurant_id,
        limit: 100, // Adjust as needed
        offset: 0,
      };

      const headers = { Authorization: `Bearer ${authToken}` };
      const apiEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/inventoryreact';

      const response = await axios.get(apiEndpoint, { params, headers });
      const fetchedInventory = response.data.items || [];

      // Filter to low stock items
      const lowStockData = fetchedInventory.filter(
        (item) => item.On_Hand <= item.PAR
      );

      // Optionally, sort the low stock items by how low the stock is (ascending)
      lowStockData.sort((a, b) => a.On_Hand - b.On_Hand);

      setLowStockItems(lowStockData);
    } catch (error) {
      console.error('Error fetching low stock inventory:', error);
      setLowStockError(
        error.message || 'An unexpected error occurred while fetching low stock inventory.'
      );
    } finally {
      setLowStockLoading(false);
    }
  };

  // Fetch Low Stock Inventory Items on component mount
  useEffect(() => {
    fetchInventory();
  }, [navigate]);

  // Fetch Active Orders
  useEffect(() => {
    const fetchActiveOrders = async () => {
      try {
        const restaurantId = getRestaurantId();
        console.log('Fetching Active Orders for restaurant_id:', restaurantId);

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/active_orders?restaurant_id=${encodeURIComponent(
            restaurantId
          )}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        console.log('API Response Status (Active Orders):', response.status);

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `API request for Active Orders failed with status ${response.status}: ${errorText}`
          );
        }

        const data = await response.json();
        console.log('API Response Data (Active Orders):', data);

        if (Array.isArray(data)) {
          // Categorize orders based on delivery_status_id
          const recentlySubmitted = data.filter((order) => order.delivery_status_id === 1);
          const inProgress = data.filter((order) => order.delivery_status_id === 3);
          setRecentlySubmittedOrders(recentlySubmitted);
          setOrdersInProgress(inProgress);
          setOrdersLoading(false);
        } else {
          throw new Error('Invalid data format received from Active Orders API.');
        }
      } catch (err) {
        console.error('Error fetching Active Orders:', err);
        setOrdersError(err.message || 'An unexpected error occurred while fetching orders.');
        setOrdersLoading(false);
      }
    };

    fetchActiveOrders();
  }, []);

  // Fetch MOQ Tracker Data
  useEffect(() => {
    const fetchMOQData = async () => {
      setMoqLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const restaurantId = getRestaurantId();

        if (!authToken || !restaurantId) {
          console.error('Authentication token or restaurant ID not found.');
          navigate('/');
          return;
        }

        const headers = { Authorization: `Bearer ${authToken}` };
        const params = { restaurant_id: restaurantId };

        const response = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/moqtracker',
          { headers, params }
        );

        console.log('MOQ Tracker Data:', response.data);

        if (response.data && response.data.length > 0) {
          setRestaurantData(response.data[0]);
        } else {
          console.error('No data received from MOQ Tracker API.');
          setRestaurantData(null); // Explicitly set to null
        }
      } catch (error) {
        console.error('Error fetching MOQ Tracker data:', error);
        setMoqError(
          error.message || 'An unexpected error occurred while fetching MOQ Tracker data.'
        );
      } finally {
        setMoqLoading(false);
      }
    };

    fetchMOQData();
  }, [navigate]);

  // Fetch Recent Items
  useEffect(() => {
    const fetchRecentItems = async () => {
      setRecentItemsLoading(true);
      try {
        const userId = getUserId();

        const params = {
          useridfilter: userId,
          limit: 25, // Assuming limit 25 as per the API info
          offset: 0,
          productnamefilter: "",
        };

        const apiEndpoint = 'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/purchase_history_items_react';

        const response = await axios.get(apiEndpoint, { params });

        if (response.status === 200 && response.data.items) {
          setRecentItems(response.data.items.slice(0, 10)); // Limit to first 10 items
        } else {
          throw new Error('Invalid response from Recent Items API.');
        }
      } catch (error) {
        console.error('Error fetching Recent Items:', error);
        setRecentItemsError(
          error.message || 'An unexpected error occurred while fetching recent items.'
        );
      } finally {
        setRecentItemsLoading(false);
      }
    };

    fetchRecentItems();
  }, []);

  // Function to format number as currency
  const formatCurrency = (amount) => {
    if (typeof amount !== 'number') return '-';
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0, // Remove decimal places
    });
  };

  // Function to format date and time
  const formatDateTime = (timestamp) => {
    // Check if timestamp is in milliseconds or seconds
    const date = new Date(
      timestamp > 1000000000000 ? timestamp : timestamp * 1000
    ); // Multiply by 1000 if timestamp is in seconds
    if (isNaN(date)) return '-';
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return date.toLocaleDateString(undefined, options);
  };

  // Calculate Order Amount
  const calculateOrderAmount = (order) => {
    const totalPrice =
      order._sum_of_order_items_of_order?.order_item_total_price || 0;
    const salesTax =
      order._sum_of_order_items_of_order?.order_item_sales_tax_added || 0;
    return totalPrice + salesTax;
  };

  // Get Item Count
  const getItemCount = (order) => {
    return order._item_count_of_order?.order_item_quantity || 0;
  };

  // Handlers
  const handleGoToInventory = () => {
    navigate('/inventory');
  };

  const handleViewAllOrders = () => {
    navigate('/orders');
  };

  const handleSeeMoreRecentlySubmitted = () => {
    navigate('/orders?status=recent');
  };

  const handleSeeMoreInProgress = () => {
    navigate('/orders?status=in-progress');
  };

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  // Handler for viewing all out-of-stock items
  const handleViewAllOutOfStock = () => {
    navigate('/inventory');
  };

  // Handler for inventory item click (opens the modal)
  const handleInventoryItemClick = (item) => {
    setSelectedItem(item);
    setOpenProductModal(true);
  };

  return (
    <div>
      {/* Sticky TopNavBar */}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar, // Ensures the navbar stays above other content
          backgroundColor: theme.palette.background.paper, // Prevents content bleed
          boxShadow: theme.shadows[4], // Optional: Adds shadow for better separation
        }}
      >
        <TopNavBar onSearch={null} title="Home" />
      </Box>

      {/* Main Content Wrapper */}
      <Box
        sx={{
          paddingTop: theme.spacing(2), // Adjusted padding to account for sticky navbar
          paddingBottom: theme.spacing(6),
          transition: 'padding-left 0.3s',
          minHeight: '100vh',
          backgroundColor: 'white',
          fontFamily: theme.typography.fontFamily, // Use theme's font family
        }}
      >
        {/* Container to set max width to 1200px */}
        <Container maxWidth="lg" sx={{ padding: theme.spacing(2) }}>
          {/* Welcome Section */}
          <Box
            sx={{
              marginBottom: theme.spacing(3),
              textAlign: 'left',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(4),
              }}
            >
              Operations Dashboard
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ maxWidth: '800px' }}
            >
              Track your spending, monitor inventory, and manage orders all in one place. Make data-driven decisions to optimize your restaurant operations.
            </Typography>
          </Box>

          {/* Masonry Layout with 3 columns on medium and large screens */}
          <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 3 }} spacing={2}>
            {/* Spend Summary Section */}
            <SpendSummary />

            {/* New Orders Section */}
            <Card
              sx={{
                backgroundColor: theme.palette.background.paper,
                boxShadow: 0,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
                minHeight: '300px',
                border: '1px solid #ccc', // Added border
              }}
            >
              {ordersLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '250px',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : ordersError ? (
                <Alert severity="error">
                  {`There was an error fetching your new orders: ${ordersError}. Please try again later.`}
                </Alert>
              ) : recentlySubmittedOrders.length === 0 ? (
                // Customized Empty State for New Orders
                <Box
                  sx={{
                    textAlign: 'center',
                    padding: theme.spacing(4),
                    backgroundColor: alpha(theme.palette.primary.light, 0.1),
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    No New Orders Yet
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Start by placing a new order to see your recent purchases here. Stay organized and keep track of all your orders effortlessly.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/shop')}
                    sx={{
                      marginTop: theme.spacing(2),
                      textTransform: 'none',
                    }}
                  >
                    Start a New Order
                  </Button>
                </Box>
              ) : (
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontWeight: theme.typography.h6.fontWeight,
                        textTransform: 'capitalize',
                      }}
                    >
                      New Orders
                    </Typography>
                  </Box>

                  {/* New Orders List */}
                  <Box>
                    <List disablePadding>
                      {recentlySubmittedOrders.slice(0, 5).map((order, index) => (
                        <React.Fragment key={order.id}>
                          <ListItem
                            disablePadding
                            sx={{
                              paddingY: 0.5, // Tighten vertical padding
                              // Conditionally adjust bottom padding for the last item
                              ...(index === Math.min(recentlySubmittedOrders.length, 5) - 1 && {
                                paddingBottom: 0,
                              }),
                            }}
                          >
                            <ListItemButton onClick={() => handleOrderClick(order.id)}>
                              <ListItemText
                                primary={
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontWeight: theme.typography.subtitle1.fontWeight,
                                      }}
                                    >
                                      {`Order #${order.id}`}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {formatDateTime(order.date_time_placed)}
                                    </Typography>
                                  </Box>
                                }
                                secondary={
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                    sx={{
                                      display: 'block',
                                      fontFamily: theme.typography.fontFamily,
                                    }}
                                  >
                                    {`Order of ${getItemCount(order)} items for ${formatCurrency(calculateOrderAmount(order))}`}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                          {/* Add Divider except after the last item */}
                          {index < Math.min(recentlySubmittedOrders.length, 5) - 1 && (
                            <Divider sx={{ borderColor: theme.palette.divider }} />
                          )}
                        </React.Fragment>
                      ))}
                    </List>
                    {/* See More Button */}
                    {recentlySubmittedOrders.length > 5 && (
                      <Box sx={{ textAlign: 'center', marginTop: theme.spacing(1) }}>
                        <Button
                          variant="outlined" // Changed to outlined
                          color="primary"
                          onClick={handleSeeMoreRecentlySubmitted}
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            textTransform: 'none',
                          }}
                        >
                          See More
                        </Button>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              )}
            </Card>

            {/* In Progress Orders Section */}
            <Card
              sx={{
                backgroundColor: theme.palette.background.paper,
                boxShadow: 0,
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(2),
                minHeight: '300px',
                border: '1px solid #ccc', // Added border
              }}
            >
              {ordersLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '250px',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : ordersError ? (
                <Alert severity="error">
                  {`There was an error fetching your in-progress orders: ${ordersError}. Please try again later.`}
                </Alert>
              ) : ordersInProgress.length === 0 ? (
                // Customized Empty State for In Progress Orders
                <Box
                  sx={{
                    textAlign: 'center',
                    padding: theme.spacing(4),
                    backgroundColor: alpha(theme.palette.info.light, 0.1),
                    borderRadius: theme.shape.borderRadius,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    No Orders in Progress
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    You're all caught up! Start a new order to keep your operations running smoothly.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleViewAllOrders}
                    sx={{
                      marginTop: theme.spacing(2),
                      textTransform: 'none',
                    }}
                  >
                    View In Progress Orders
                  </Button>
                </Box>
              ) : (
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontWeight: theme.typography.h6.fontWeight,
                        textTransform: 'capitalize',
                      }}
                    >
                      In Progress Orders
                    </Typography>
                  </Box>

                  {/* In Progress Orders List */}
                  <Box>
                    <List disablePadding>
                      {ordersInProgress.slice(0, 5).map((order, index) => (
                        <React.Fragment key={order.id}>
                          <ListItem
                            disablePadding
                            sx={{
                              paddingY: 0.5, // Tighten vertical padding
                              // Conditionally adjust bottom padding for the last item
                              ...(index === Math.min(ordersInProgress.length, 5) - 1 && {
                                paddingBottom: 0,
                              }),
                            }}
                          >
                            <ListItemButton onClick={() => handleOrderClick(order.id)}>
                              <ListItemText
                                primary={
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      sx={{
                                        fontWeight: theme.typography.subtitle1.fontWeight,
                                      }}
                                    >
                                      {`Order #${order.id}`}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      {formatDateTime(order.date_time_placed)}
                                    </Typography>
                                  </Box>
                                }
                                secondary={
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                    sx={{
                                      display: 'block',
                                      fontFamily: theme.typography.fontFamily,
                                    }}
                                  >
                                    {`Order of ${getItemCount(order)} items for ${formatCurrency(calculateOrderAmount(order))}`}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                          {/* Add Divider except after the last item */}
                          {index < Math.min(ordersInProgress.length, 5) - 1 && (
                            <Divider sx={{ borderColor: theme.palette.divider }} />
                          )}
                        </React.Fragment>
                      ))}
                    </List>
                    {/* See More Button */}
                    {ordersInProgress.length > 5 && (
                      <Box sx={{ textAlign: 'center', marginTop: theme.spacing(1) }}>
                        <Button
                          variant="outlined" // Changed to outlined
                          color="primary"
                          onClick={handleSeeMoreInProgress}
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            textTransform: 'none',
                          }}
                        >
                          See More
                        </Button>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              )}
            </Card>

            {/* Low Stock Inventory Section - Refactored as LowStockComponent */}
            <LowStockComponent
              lowStockItems={lowStockItems}
              lowStockLoading={lowStockLoading}
              lowStockError={lowStockError}
              handleGoToInventory={handleGoToInventory}
              onItemClick={handleInventoryItemClick}
              multiplier={multiplier} // Pass the multiplier here
            />

            {/* MOQ Tracker Section - Refactored as MOQComponent */}
            <MOQComponent
              restaurantData={restaurantData}
              moqLoading={moqLoading}
              moqError={moqError}
              subtotal={subtotal}
              handleGoToInventory={handleGoToInventory}
            />

          </Masonry>

          {/* Recent Items Section */}
          <Card
            sx={{
              backgroundColor: theme.palette.background.paper,
              boxShadow: 0,
              borderRadius: theme.shape.borderRadius,
              padding: theme.spacing(2),
              minHeight: '300px',
              border: '1px solid #ccc',
              marginTop: theme.spacing(2), // Add some spacing from the Masonry grid
            }}
          >
            {recentItemsLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '250px',
                }}
              >
                <CircularProgress />
              </Box>
            ) : recentItemsError ? (
              <Alert severity="error">
                {`There was an error fetching your recent items: ${recentItemsError}. Please try again later.`}
              </Alert>
            ) : recentItems.length === 0 ? (
              // Customized Empty State for Recent Items
              <Box
                sx={{
                  textAlign: 'center',
                  padding: theme.spacing(4),
                  backgroundColor: alpha(theme.palette.info.light, 0.1),
                  borderRadius: theme.shape.borderRadius,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  No Recent Items Yet
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Your recent purchases will appear here. Start by placing an order to see your items and keep track of your favorite products effortlessly.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/shop')}
                  sx={{
                    marginTop: theme.spacing(2),
                    textTransform: 'none',
                  }}
                >
                  Browse Products
                </Button>
              </Box>
            ) : (
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      fontWeight: theme.typography.h6.fontWeight,
                      textTransform: 'capitalize',
                    }}
                  >
                    Recent Items
                  </Typography>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate('/lists')}
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      borderRadius: theme.shape.borderRadius * 3,
                      textTransform: 'none',
                    }}
                  >
                    View All
                  </Button>
                </Box>

                {/* Recent Items Grid */}
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                      xs: 'repeat(2, 1fr)', // 2 columns on extra small screens
                      sm: 'repeat(3, 1fr)', // 3 columns on small screens
                      md: 'repeat(5, 1fr)', // 5 columns on medium and larger screens
                    },
                    gap: theme.spacing(2),
                  }}
                >
                  {recentItems.slice(0, 10).map((item) => (
                    <ProductCard
                      key={item.id}
                      product={item._product}
                      item={item}
                      multiplier={multiplier}
                      // Add any additional props if necessary
                    />
                  ))}
                </Box>
              </CardContent>
            )}
          </Card>
        </Container>
      </Box>

      {/* Product Details Modal */}
      {openProductModal && selectedItem && (
        <ProductDetailsModal
          open={openProductModal}
          handleClose={() => setOpenProductModal(false)}
          inventoryItem={selectedItem}
          multiplier={multiplier}
          fetchInventory={fetchInventory} // Pass fetchInventory here
        />
      )}
    </div>
  );
}

export default Home;
