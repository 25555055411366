// SideNavBar.js
import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  Box,
  Divider,
  IconButton,
  Button, // Imported Button
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ShopIcon from '@mui/icons-material/Store';
import OrdersIcon from '@mui/icons-material/ListAlt';
import InventoryIcon from '@mui/icons-material/Inventory';
import HistoryIcon from '@mui/icons-material/History';
import ListIcon from '@mui/icons-material/List'; // Imported ListIcon
import ReturnIcon from '@mui/icons-material/AssignmentReturn'; // Imported ReturnIcon
// Removed MoreIcon as per previous instructions
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LogoImage from './assets/Logo.avif'; // Import your logo

function SideNavBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  // Determine if the screen is larger than "sm" (e.g., desktop size)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNavigation = (path) => {
    navigate(`/${path}`);
    if (!isDesktop) {
      setDrawerOpen(false); // Close the drawer only on mobile
    }
  };

  // Updated drawerItems array with reordered links
  const drawerItems = [
    { label: 'Home', value: 'home', icon: <HomeIcon /> }, // Retained Home link at the top
    { label: 'Inventory', value: 'inventory', icon: <InventoryIcon /> },
    { label: 'Lists', value: 'lists', icon: <ListIcon /> },
    { label: 'Shop', value: 'shop', icon: <ShopIcon /> },
    { label: 'Purchase History', value: 'purchase-history', icon: <HistoryIcon /> },
    { label: 'Orders', value: 'orders', icon: <OrdersIcon /> },
    { label: 'Returns', value: 'returns', icon: <ReturnIcon /> },
  ];

  return (
    <>
      {/* Only show menu button on smaller screens */}
      {!isDesktop && (
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ color: '#0E9C9B' }}
          aria-label="open navigation menu" // Added aria-label for accessibility
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Drawer */}
      <Drawer
        variant={isDesktop ? 'persistent' : 'temporary'} // Persistent on desktop, temporary on mobile
        anchor="left"
        open={isDesktop || drawerOpen} // Always open on desktop
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          width: isDesktop ? 240 : 200, // Fixed width on desktop and mobile
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isDesktop ? 240 : 200, // Fixed width on desktop and mobile
            boxSizing: 'border-box',
            backgroundColor: '#ffffff', // Background color of the sidebar
            display: 'flex',
            flexDirection: 'column', // To allow vertical layout
            justifyContent: 'space-between', // Space between navigation and button
          },
        }}
      >
        <Box>
          {/* Logo at the top with more padding */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '24px 16px',
            }}
          >
            <IconButton onClick={() => handleNavigation('home')} sx={{ p: 0 }}>
              {/* IconButton to make the logo clickable */}
              <img
                src={LogoImage}
                alt="Logo"
                style={{ height: '40px', cursor: 'pointer' }} // Add pointer cursor to the logo
              />
            </IconButton>
          </Box>

          {/* List of navigation items */}
          <List>
            {drawerItems.map((item, index) => (
              <React.Fragment key={item.value}>
                <ListItem
                  button
                  selected={location.pathname.startsWith(`/${item.value}`)}
                  onClick={() => handleNavigation(item.value)}
                  sx={{
                    cursor: 'pointer', // Ensure the cursor shows as a pointer
                    '&.Mui-selected': {
                      backgroundColor: '#ffffff', // Set background to white when selected (optional)
                      color: '#0E9C9B', // Active item text color set to teal
                      fontFamily: 'Gotham Medium', // Apply Gotham Medium font to active state
                      '& .MuiListItemIcon-root': {
                        color: '#0E9C9B', // Active item icon color set to teal
                      },
                    },
                    '& .MuiListItemText-primary': {
                      fontFamily: 'Gotham Medium', // Apply Gotham Medium font to text
                    },
                    '&:hover': {
                      backgroundColor: '#e0f7f6', // Hover color
                      transform: 'scale(1.05)', // Slightly scale up the item on hover
                      transition: 'transform 0.2s ease-in-out', // Smooth transition for scaling
                    },
                    color: '#555555', // Lighter shade for inactive item text
                    fontFamily: 'Gotham Medium', // Apply Gotham Medium font to inactive state
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: location.pathname.startsWith(`/${item.value}`)
                        ? '#0E9C9B' // Active icon color set to teal
                        : '#555555', // Inactive icon color
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
                {index < drawerItems.length - 1 && <Divider sx={{ bgcolor: '#e0e0e0' }} />} {/* Divider between links */}
              </React.Fragment>
            ))}
          </List>
        </Box>

        {/* Email Support Button at the bottom */}
        <Box sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            href="mailto:support@fare.food"
            sx={{
              textTransform: 'none', // Keep the text as is
              fontFamily: 'Gotham Medium', // Apply Gotham Medium font to text
              borderColor: '#0E9C9B', // Border color matching the theme
              color: '#0E9C9B', // Text color matching the theme
              '&:hover': {
                borderColor: '#0E9C9B',
                backgroundColor: '#e0f7f6', // Hover background color
              },
            }}
            aria-label="Email support for help or feature requests" // Added aria-label for accessibility
          >
            Email support@fare.food for help, to make a feature request, etc.
          </Button>
        </Box>
      </Drawer>
    </>
  );
}

export default SideNavBar;
