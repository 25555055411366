// src/theme.js

// Import Poppins font weights
import '@fontsource/poppins/400.css'; // Regular weight
import '@fontsource/poppins/500.css'; // Medium weight
import '@fontsource/poppins/700.css'; // Bold weight

import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#0E9C9B', // Existing primary color
    },
    secondary: {
      main: '#FF5722', // Bright orange accent color
    },
    background: {
      default: '#F5F5F5',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#131616',
      secondary: '#555555',
    },
  },
  typography: {
    fontSize: 12, // Further reduced base font size from 14px to 12px
    fontFamily: 'Poppins, Arial, sans-serif', // Updated to use Poppins
    h1: {
      fontWeight: 500, // Medium weight
      fontSize: '2rem', // Previously 2.5rem
    },
    h2: {
      fontWeight: 500, // Medium weight
      fontSize: '1.75rem', // Previously 2rem
    },
    h3: {
      fontWeight: 500, // Medium weight
      fontSize: '1.5rem', // Previously 1.75rem
    },
    h4: {
      fontWeight: 500, // Medium weight
      fontSize: '1.25rem', // Previously 1.5rem
    },
    h5: {
      fontWeight: 500, // Medium weight
      fontSize: '1.125rem', // Previously 1.25rem
    },
    h6: {
      fontWeight: 700, // Bold weight
      fontSize: '1rem', // Remains the same or adjust if needed
    },
    body1: {
      fontWeight: 400, // Regular weight
      fontSize: '0.875rem', // Previously 1rem
    },
    body2: {
      fontWeight: 400, // Regular weight
      fontSize: '0.75rem', // Previously 0.875rem
    },
    button: {
      textTransform: 'none', // Disable uppercase transformation on buttons globally
      fontWeight: 400, // Regular weight
      fontSize: '0.75rem', // Previously 0.875rem
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.625rem', // New: Adding a caption variant
    },
    overline: {
      fontWeight: 400,
      fontSize: '0.625rem', // New: Adding an overline variant
      letterSpacing: '0.1em',
    },
    // Add or adjust other variants as needed
  },
  // Optional: Override component styles to ensure consistency
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          body1: 'p',
          body2: 'p',
          button: 'span',
          caption: 'span',
          overline: 'span',
          // Add other mappings if necessary
        },
      },
    },
  },
});

// Optionally enable responsive font sizes
theme = responsiveFontSizes(theme);

export default theme;
