// src/contexts/AuthContext.js

import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Create the context
export const AuthContext = createContext();

// Create the provider component
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Loading state to manage auth status

  // Function to load user data from localStorage
  const loadUserFromLocalStorage = () => {
    setLoading(true);
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const authToken = localStorage.getItem('authToken');

    if (storedUser && storedUser.restaurant_id && storedUser.restaurant_id !== 0 && authToken) {
      setUser(storedUser);
      setIsAuthenticated(true);
      setError('');
    } else {
      setUser(null);
      setIsAuthenticated(false);
      localStorage.removeItem('user');
      localStorage.removeItem('authToken');
      if (storedUser) {
        setError('Your account is not linked to a restaurant. Please contact support.');
        navigate('/login');
      }
    }
    setLoading(false);
  };

  // Function to handle login
  const login = async (email, password) => {
    setLoading(true);
    setError(''); // Reset error before login attempt
    try {
      const response = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/auth/login',
        { email, password }
      );

      if (response.data.authToken) {
        const authToken = response.data.authToken;
        localStorage.setItem('authToken', authToken);

        // Fetch user data
        const userResponse = await axios.get(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/auth/mereact',
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const userData = userResponse.data;

        // Check for valid restaurant_id
        if (!userData.restaurant_id || userData.restaurant_id === 0) {
          setError('This user is not linked to a restaurant. Please contact support.');
          logout(); // Automatically logout the user
          return;
        }

        localStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
        setIsAuthenticated(true);
        setError('');
        navigate('/home');
      } else {
        setError('Login failed. Please try again.');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred during login.');
      }
    }
    setLoading(false);
  };

  // Function to handle logout
  const logout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
    setUser(null);
    setIsAuthenticated(false);
    setError('');
    navigate('/login');
  };

  useEffect(() => {
    // Load user on initial mount
    loadUserFromLocalStorage();

    // Listen to storage events (e.g., changes from other tabs)
    const handleStorageChange = (event) => {
      if (event.key === 'user' || event.key === 'authToken') {
        loadUserFromLocalStorage();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, logout, error, setError, loading, login }}>
      {children}
    </AuthContext.Provider>
  );
};
