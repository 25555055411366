// src/components/Signup.js

import React, { useState, useContext } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Box,
  Container,
  CssBaseline,
  IconButton,
  InputAdornment,
  Alert,
  CircularProgress,
  Link,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import Logo from './assets/small-logo.avif';
import './styles/fonts.css';
import axios from 'axios';
import { AuthContext } from './contexts/AuthContext';

function Signup() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    restaurantName: '',
    restaurantAddress: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const [formError, setFormError] = useState('');
  const [apiError, setApiError] = useState('');
  const [loading, setLoading] = useState(false);

  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormError('');
    setApiError('');
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validatePassword = (password) => {
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!uppercaseRegex.test(password)) {
      return 'Password must contain at least one uppercase letter.';
    }
    if (!numberRegex.test(password)) {
      return 'Password must contain at least one number.';
    }
    if (!symbolRegex.test(password)) {
      return 'Password must contain at least one symbol.';
    }
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError('');
    setApiError('');

    // Validate passwords
    if (formData.password !== formData.confirmPassword) {
      setFormError('Passwords do not match.');
      return;
    }

    const passwordValidationError = validatePassword(formData.password);
    if (passwordValidationError) {
      setFormError(passwordValidationError);
      return;
    }

    // Validate address
    if (!formData.restaurantAddress.trim()) {
      setFormError('Restaurant address is required.');
      return;
    }

    // Prepare payload for the signup API
    const signupPayload = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      restaurantname: formData.restaurantName, // Exact parameter name
      restaurant_location: formData.restaurantAddress, // Exact parameter name
    };

    // Prepare payload for the Slack notification API (optional)
    const notificationText = `New Account Alert!!! 🎉🕺🏼🥳

**Restaurant Name:** ${formData.restaurantName}
**Name:** ${formData.name}
**Email:** ${formData.email}
**Location:** ${formData.restaurantAddress}`;

    const notificationPayload = {
      text: notificationText,
    };

    // Debugging: Log formData and payloads
    console.log('Form Data:', formData);
    console.log('Signup Payload:', signupPayload);
    console.log('Notification Payload:', notificationPayload);

    setLoading(true);

    try {
      // 1. Signup the user
      const signupResponse = await axios.post(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/auth/signup', // Ensure this is your signup endpoint
        signupPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      console.log('Signup Response:', signupResponse);

      if (signupResponse.status === 200 || signupResponse.status === 201) {
        // 2. Send notification to Slack (optional)
        try {
          const notificationResponse = await axios.post(
            'https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers',
            notificationPayload,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          console.log('Notification Response:', notificationResponse);

          if (
            notificationResponse.status === 200 ||
            notificationResponse.status === 201
          ) {
            console.log('Notification sent successfully.');
          } else {
            console.warn('Failed to send notification.');
          }
        } catch (notificationError) {
          console.error('Notification API error:', notificationError);
          // Optionally, you can choose to not fail the signup if the notification fails
        }

        // 2.5. Send Welcome Email
        try {
          const emailPayload = {
            to_email: formData.email,
            Full_Name: formData.name,
            Restaurant_Name: formData.restaurantName,
          };
          const emailResponse = await axios.post(
            'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/send_welcome_email',
            emailPayload,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          console.log('Welcome Email Response:', emailResponse);

          if (emailResponse.status === 200 || emailResponse.status === 201) {
            console.log('Welcome email sent successfully.');
          } else {
            console.warn('Failed to send welcome email.');
          }
        } catch (emailError) {
          console.error('Welcome Email API error:', emailError);
          // Optionally, you can choose to not fail the signup if the welcome email fails
        }

        // 3. Login the user
        await login(formData.email, formData.password);
        navigate('/home');
      } else {
        setApiError('Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Signup API error:', error);
      if (error.response && error.response.data && error.response.data.message) {
        setApiError(error.response.data.message);
      } else {
        setApiError(
          'An unexpected error occurred during signup. Please try again.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {/* Helmet for Setting Page Title and Meta Tags */}
      <Helmet>
        <title>FareFood | Sign Up</title>
        <meta
          name="description"
          content="Join FareFood today to streamline your restaurant's food ordering process. Create an account to save time, reduce costs, and enhance your operations with our powerful tools."
        />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="FareFood | Sign Up" />
        <meta
          property="og:description"
          content="Create a FareFood account to optimize your restaurant's food orders. Save time and money with our intuitive platform tailored for your business needs."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.farefood.com/signup" />
        <meta
          property="og:image"
          content="https://www.farefood.com/assets/signup-og-image.jpg"
        />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Sign Up" />
        <meta
          name="twitter:description"
          content="Sign up for FareFood to streamline your restaurant's food ordering process. Save time and reduce costs with our comprehensive tools designed for your business."
        />
        <meta
          name="twitter:image"
          content="https://www.farefood.com/assets/signup-twitter-image.jpg"
        />
      </Helmet>

      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: 'white',
          paddingTop: theme.spacing(8),
        }}
      >
        {/* Main Content */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(4),
          }}
        >
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {/* Logo */}
              <Box
                component="img"
                src={Logo}
                alt="FareFood Logo"
                sx={{ width: 45, marginBottom: theme.spacing(2) }}
              />

              {/* Header */}
              <Typography
                component="h1"
                variant="h4"
                sx={{
                  mb: theme.spacing(3),
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontFamily: theme.typography.h4.fontFamily,
                  fontWeight: '700',
                  fontSize: '1.5rem',
                }}
              >
                Create an Account
              </Typography>

              {/* Display Error Messages */}
              {formError && (
                <Alert severity="error" sx={{ width: '100%', mb: theme.spacing(2) }}>
                  {formError}
                </Alert>
              )}
              {apiError && (
                <Alert severity="error" sx={{ width: '100%', mb: theme.spacing(2) }}>
                  {apiError}
                </Alert>
              )}

              {/* Signup Form */}
              <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                {/* Full Name Field */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label="Full Name"
                  name="name" // Matches formData key
                  autoComplete="name"
                  autoFocus
                  value={formData.name}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                />

                {/* Email Address Field */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email" // Matches formData key
                  type="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                />

                {/* Password Field */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password" // Matches formData key
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                />

                {/* Confirm Password Field */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="confirmPassword" // Matches formData key
                  label="Confirm Password"
                  type={showPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={showPassword ? 'Hide password' : 'Show password'}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                />

                {/* Restaurant Name Field */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="restaurantName"
                  label="Restaurant Name"
                  name="restaurantName" // Matches formData key
                  autoComplete="organization"
                  value={formData.restaurantName}
                  onChange={handleChange}
                  variant="outlined"
                  InputProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                />

                {/* Restaurant Address Field (Standard Input) */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="restaurantAddress"
                  label="Restaurant Address"
                  name="restaurantAddress" // Matches formData key
                  autoComplete="street-address"
                  value={formData.restaurantAddress}
                  onChange={handleChange}
                  variant="outlined"
                  placeholder="Enter your restaurant's address"
                  InputProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontFamily: theme.typography.fontFamily,
                      fontSize: '1rem',
                      '&.Mui-focused': {
                        fontSize: '1.1rem',
                      },
                    },
                  }}
                />

                {/* Submit Button */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  sx={{
                    mt: theme.spacing(3),
                    mb: theme.spacing(2),
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': { backgroundColor: theme.palette.primary.dark },
                    padding: theme.spacing(1.5),
                    borderRadius: theme.shape.borderRadius,
                    transition: 'transform 0.2s ease-in-out',
                    fontFamily: theme.typography.button.fontFamily,
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Sign Up'
                  )}
                </Button>
              </Box>

              {/* Login Prompt */}
              <Typography
                variant="body2"
                sx={{
                  mt: theme.spacing(2),
                  textAlign: 'center',
                  color: theme.palette.text.secondary,
                  fontFamily: theme.typography.fontFamily,
                }}
              >
                Already have an account?{' '}
                <Link
                  component={RouterLink}
                  to="/login"
                  sx={{
                    color: theme.palette.primary.main,
                    textDecoration: 'none',
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: theme.typography.fontWeightMedium,
                  }}
                >
                  Log In
                </Link>
              </Typography>
            </Box>
          </Container>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            paddingY: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.disabled,
            fontSize: theme.typography.body2.fontSize,
            fontFamily: theme.typography.fontFamily,
          }}
        >
          © 2024 FareFood, Inc. All Rights Reserved.
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Signup;
