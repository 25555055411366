// src/components/Settings.js

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Container,
  Card,
  Divider,
  Alert,
  Button,
  TextField,
  CircularProgress,
  Autocomplete,
  IconButton,
  Grid,
  Avatar,
  Snackbar,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  CheckCircle as CheckCircleIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
} from '@mui/icons-material';
import TopNavBar from './TopNavBar';

// Styled component for the settings card
const SettingsCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
}));

function Settings() {
  // Retrieve user data from localStorage
  const userData = JSON.parse(localStorage.getItem('user')) || {};
  const { email, location, id: restaurantId, name: userName } = userData;

  // State variables for restaurant data
  const [restaurantData, setRestaurantData] = useState(null);
  const [restaurantName, setRestaurantName] = useState('Unknown Restaurant');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State variables for Resale Tax Certificate
  const [resaleTaxCertNumber, setResaleTaxCertNumber] = useState('');
  const [isSubmittingTaxCert, setIsSubmittingTaxCert] = useState(false);
  const [showResaleTaxCertForm, setShowResaleTaxCertForm] = useState(false);

  // Vendor Request State
  const [vendors, setVendors] = useState([]);
  const [vendorSelections, setVendorSelections] = useState([
    { id: Date.now(), value: null },
  ]);
  const [otherVendors, setOtherVendors] = useState('');
  const [isVendorSubmitting, setIsVendorSubmitting] = useState(false);
  const [vendorSubmitSuccess, setVendorSubmitSuccess] = useState(false);
  const [vendorSubmitError, setVendorSubmitError] = useState(null);

  // Existing Vendors State
  const [existingVendors, setExistingVendors] = useState([]);
  const [loadingExistingVendors, setLoadingExistingVendors] = useState(true);
  const [errorExistingVendors, setErrorExistingVendors] = useState(null);

  const theme = useTheme();

  // Snackbar State
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Fetch the list of vendors
  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await fetch(
          'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/vendor',
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch vendors.');
        }

        const data = await response.json();
        setVendors(data);
      } catch (error) {
        console.error('Error fetching vendors:', error);
        setError('Failed to load vendors.');
      }
    };

    fetchVendors();
  }, []);

  // Fetch restaurant data using restaurantId
  useEffect(() => {
    const fetchRestaurantData = async () => {
      try {
        if (!restaurantId) throw new Error('Restaurant ID not found');

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant/${restaurantId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch restaurant data.');
        }

        const data = await response.json();
        setRestaurantData(data);
        setRestaurantName(data.name || 'Unknown Restaurant');
        setResaleTaxCertNumber(data.resale_tax_certificate || '');
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
        setError('Failed to load restaurant data.');
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurantData();
  }, [restaurantId]);

  // Fetch existing vendors the restaurant is set up with
  useEffect(() => {
    const fetchExistingVendors = async () => {
      setLoadingExistingVendors(true);
      try {
        if (!restaurantId) throw new Error('Restaurant ID not found');

        const response = await fetch(
          `https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/restaurant_vendors?restaurant_id=${restaurantId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch existing vendors.');
        }

        const data = await response.json();
        setExistingVendors(data);
      } catch (error) {
        console.error('Error fetching existing vendors:', error);
        setErrorExistingVendors('Failed to load existing vendors.');
      } finally {
        setLoadingExistingVendors(false);
      }
    };

    if (restaurantId) {
      fetchExistingVendors();
    }
  }, [restaurantId]);

  // Handle showing the resale tax certificate form
  const handleAddResaleTaxCert = () => {
    setShowResaleTaxCertForm(true);
  };

  // Handle submitting the resale tax certificate number
  const handleSubmitResaleTaxCert = async () => {
    if (!resaleTaxCertNumber.trim()) {
      setError('Resale Tax Certificate Number cannot be empty.');
      return;
    }

    setIsSubmittingTaxCert(true);
    setError(null);
    try {
      if (!restaurantId) throw new Error('Restaurant ID not found');

      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/add_resale_tax_cert',
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            restaurant_id: restaurantId,
            resale_tax_certificate: resaleTaxCertNumber.trim(),
          }),
        }
      );

      if (response.ok) {
        setShowResaleTaxCertForm(false);
        setRestaurantData({
          ...restaurantData,
          resale_tax_certificate: resaleTaxCertNumber.trim(),
          tax_cert_on_file: false,
        });

        // Send Slack Notification
        await sendSlackNotificationForTaxCert(
          restaurantName,
          resaleTaxCertNumber.trim()
        );

        // Optionally, show a success message or update UI
      } else {
        const data = await response.json();
        throw new Error(
          data.message || 'Failed to update resale tax certificate number.'
        );
      }
    } catch (error) {
      console.error('Error updating resale tax certificate:', error);
      setError(
        error.message || 'Failed to update resale tax certificate number.'
      );
    } finally {
      setIsSubmittingTaxCert(false);
    }
  };

  // Function to send Slack notification for Tax Cert
  const sendSlackNotificationForTaxCert = async (
    restaurantName,
    resaleTaxCertNumber
  ) => {
    try {
      const notificationMessage = `${restaurantName} has updated their resale tax certificate number to ${resaleTaxCertNumber}.`;

      const slackPayload = {
        text: notificationMessage,
      };

      const slackResponse = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(slackPayload),
        }
      );

      if (slackResponse.ok) {
        console.log('Slack notification sent successfully.');
      } else {
        console.warn('Failed to send Slack notification.');
      }
    } catch (slackError) {
      console.error('Error sending Slack notification:', slackError);
    }
  };

  // Handle vendor selection changes
  const handleVendorChange = (id, newValue) => {
    setVendorSelections((prevSelections) =>
      prevSelections.map((selection) =>
        selection.id === id ? { ...selection, value: newValue } : selection
      )
    );
  };

  // Handle adding a new vendor dropdown
  const handleAddVendorRow = () => {
    setVendorSelections((prevSelections) => [
      ...prevSelections,
      { id: Date.now(), value: null },
    ]);
  };

  // Handle removing a vendor dropdown
  const handleRemoveVendorRow = (id) => {
    setVendorSelections((prevSelections) =>
      prevSelections.filter((selection) => selection.id !== id)
    );
  };

  // Handle submitting the vendor request form
  const handleSubmitVendorRequest = async () => {
    // Extract selected vendor IDs
    const selectedVendorIds = vendorSelections
      .map((selection) => selection.value?.id)
      .filter((id) => id !== undefined);

    if (selectedVendorIds.length === 0 && !otherVendors.trim()) {
      setVendorSubmitError(
        'Please select at least one vendor or enter other vendors.'
      );
      return;
    }

    setIsVendorSubmitting(true);
    setVendorSubmitError(null);
    try {
      const userJson = localStorage.getItem('user');
      if (!userJson) {
        throw new Error('User data not found in local storage.');
      }
      const user = JSON.parse(userJson);
      const userId = user.id;
      if (!userId) {
        throw new Error('User ID not found.');
      }

      // Prepare the help ticket data
      const subject = 'Supplier Account Setup Request';
      const description = `Requested Vendors: ${selectedVendorIds
        .map((id) => {
          const vendor = vendors.find((v) => v.id === id);
          return vendor ? vendor.Long_Name : `Vendor ID ${id}`;
        })
        .join(', ')}.${
        otherVendors ? ` Other Vendors: ${otherVendors}` : ''
      }`;

      const helpTicketData = {
        subject,
        description,
        user_id: userId,
        ticket_type_id: 2, // Always set to 2 for supplier account setup
        ticket_status_id: 0,
        admin_notes: '',
        last_updated: null,
      };

      // Submit the help ticket
      const response = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:Q0PWVdTm/help_tickets',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(helpTicketData),
        }
      );

      if (response.ok) {
        // Send Slack notification
        await sendSlackNotificationForVendors(restaurantName, description);

        // Show success message
        setVendorSubmitSuccess(true);
        // Clear the form
        setVendorSelections([{ id: Date.now(), value: null }]);
        setOtherVendors('');
      } else {
        const data = await response.json();
        throw new Error(data.message || 'Failed to submit vendor request.');
      }
    } catch (error) {
      console.error('Error submitting vendor request:', error);
      setVendorSubmitError(
        error.message || 'Failed to submit vendor request.'
      );
    } finally {
      setIsVendorSubmitting(false);
    }
  };

  // Function to send Slack notification for Vendor Request
  const sendSlackNotificationForVendors = async (restaurantName, description) => {
    try {
      const notificationMessage = `${restaurantName} has requested supplier accounts.\n${description}`;

      const slackPayload = {
        text: notificationMessage,
      };

      const slackResponse = await fetch(
        'https://xawe-auye-zrgm.n7d.xano.io/api:XF3EVVma/slack_customers',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(slackPayload),
        }
      );

      if (slackResponse.ok) {
        console.log('Slack notification sent successfully.');
      } else {
        console.warn('Failed to send Slack notification.');
      }
    } catch (slackError) {
      console.error('Error sending Slack notification:', slackError);
    }
  };

  // Handle closing the Snackbar
  const handleCloseSnackbar = () => {
    setVendorSubmitSuccess(false);
  };

  if (loading) {
    return (
      <>
        <TopNavBar sticky />
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            mt: 4,
            paddingBottom: theme.spacing(10), // Added padding at the bottom
          }}
        >
          <CircularProgress />
        </Container>
      </>
    );
  }

  if (error) {
    return (
      <>
        <TopNavBar sticky />
        <Container
          maxWidth="sm"
          sx={{
            textAlign: 'center',
            mt: 4,
            paddingBottom: theme.spacing(10), // Added padding at the bottom
          }}
        >
          <Typography color="error">Error: {error}</Typography>
        </Container>
      </>
    );
  }

  const { tax_cert_on_file } = restaurantData;

  return (
    <>
      {/* Include the Top Navigation Bar */}
      <TopNavBar sticky />

      {/* Main Content */}
      <Box
        sx={{
          paddingTop: theme.spacing(2.5),
          paddingBottom: theme.spacing(10), // Increased padding at the bottom
          px: theme.spacing(2),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          minHeight: '100vh',
          boxSizing: 'border-box',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Container maxWidth="md">
          {/* Settings Card */}
          <SettingsCard>
            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(4) }}>
              Settings
            </Typography>

            <Divider sx={{ marginBottom: theme.spacing(2) }} />

            {/* User Information */}
            <Box sx={{ marginBottom: theme.spacing(3) }}>
              <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                <strong>Email:</strong> {email || 'Not Provided'}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                <strong>Restaurant Name:</strong> {restaurantName}
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                <strong>Location:</strong> {location || 'Not Provided'}
              </Typography>
            </Box>

            <Divider sx={{ marginBottom: theme.spacing(3) }} />

            {/* Resale Tax Certificate Section */}
            <Box sx={{ marginBottom: theme.spacing(2) }}>
              {!resaleTaxCertNumber ? (
                <>
                  <Alert severity="warning" sx={{ marginBottom: theme.spacing(2) }}>
                    <Typography variant="body1">
                      <strong>You're Almost There!</strong> To unlock exclusive benefits, please add your
                      restaurant's resale tax certificate number.
                    </Typography>
                  </Alert>
                  <Button
                    variant="contained"
                    onClick={handleAddResaleTaxCert}
                    sx={{ mt: 2 }}
                  >
                    Add Resale Tax Certificate Number
                  </Button>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <Typography variant="body1">
                      <strong>Resale Tax Certificate Number:</strong> {resaleTaxCertNumber}
                    </Typography>
                    <Button
                      variant="outlined"
                      onClick={handleAddResaleTaxCert}
                      sx={{ ml: 2 }}
                    >
                      Update
                    </Button>
                  </Box>

                  {!tax_cert_on_file ? (
                    <Alert severity="info">
                      <Typography variant="body1">
                        We have received your resale tax certificate number and are actively verifying it.
                      </Typography>
                    </Alert>
                  ) : (
                    <Alert
                      severity="success"
                      icon={<CheckCircleIcon fontSize="inherit" />}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Typography variant="body1">
                        <strong>Resale tax certificate verified!</strong>
                      </Typography>
                    </Alert>
                  )}
                </>
              )}
            </Box>

            {/* Resale Tax Certificate Form */}
            {showResaleTaxCertForm && (
              <>
                <Divider sx={{ marginBottom: theme.spacing(3) }} />
                <Box component="form" sx={{ mt: 2 }}>
                  <TextField
                    label="Resale Tax Certificate Number"
                    variant="outlined"
                    fullWidth
                    value={resaleTaxCertNumber}
                    onChange={(e) => setResaleTaxCertNumber(e.target.value)}
                    sx={{ marginBottom: theme.spacing(2) }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitResaleTaxCert}
                      disabled={isSubmittingTaxCert}
                    >
                      {isSubmittingTaxCert ? 'Submitting...' : 'Submit'}
                    </Button>
                  </Box>
                </Box>
              </>
            )}

            {/* Divider before Existing Vendors Section */}
            <Divider sx={{ marginY: theme.spacing(4) }} />

            {/* Existing Vendors Section */}
            <Box sx={{ marginBottom: theme.spacing(4) }}>
              <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                Existing Suppliers
              </Typography>
              {loadingExistingVendors ? (
                <Box sx={{ textAlign: 'center', margin: theme.spacing(2) }}>
                  <CircularProgress />
                </Box>
              ) : errorExistingVendors ? (
                <Alert severity="error">{errorExistingVendors}</Alert>
              ) : existingVendors.length === 0 ? (
                <Typography variant="body1">
                  You are not currently set up with any suppliers.
                </Typography>
              ) : (
                <Grid container spacing={2}>
                  {existingVendors.map((vendorItem) => (
                    <Grid item xs={12} sm={6} md={4} key={vendorItem.id}>
                      <Card
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: theme.spacing(2),
                          backgroundColor: theme.palette.background.paper,
                        }}
                      >
                        {vendorItem._vendor?.Logo_URL ? (
                          <Avatar
                            src={vendorItem._vendor.Logo_URL}
                            alt={vendorItem._vendor.Short_Name}
                            sx={{ width: 56, height: 56, marginRight: theme.spacing(2) }}
                          />
                        ) : (
                          <Avatar
                            sx={{
                              width: 56,
                              height: 56,
                              marginRight: theme.spacing(2),
                              backgroundColor: theme.palette.grey[300],
                            }}
                          >
                            {vendorItem._vendor?.Short_Name
                              ? vendorItem._vendor.Short_Name.charAt(0)
                              : 'V'}
                          </Avatar>
                        )}
                        <Typography variant="body1">
                          {vendorItem._vendor?.Long_Name || 'Unknown Vendor'}
                        </Typography>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>

            <Divider sx={{ marginBottom: theme.spacing(4) }} />

            {/* Vendor Request Section */}
            <Box sx={{ marginBottom: theme.spacing(2) }}>
              <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                Get Set Up with Suppliers
              </Typography>
              <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                To begin viewing products, let us know which suppliers you'd like to get accounts set up with.
                Not all vendors may service your area, but we'll do our best to get you set up with them.
              </Typography>

              {/* Vendor Selection Form */}
              <Box component="form" sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                  {vendorSelections.map((selection, index) => (
                    <Grid item xs={12} key={selection.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Autocomplete
                          options={vendors}
                          getOptionLabel={(option) => option.Long_Name || 'Unknown Vendor'}
                          value={selection.value}
                          onChange={(event, newValue) => handleVendorChange(selection.id, newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Vendor ${index + 1}`}
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          sx={{ flexGrow: 1 }}
                        />
                        <IconButton
                          color="primary"
                          onClick={() => handleAddVendorRow()}
                          sx={{ ml: 1 }}
                        >
                          <AddIcon />
                        </IconButton>
                        {vendorSelections.length > 1 && (
                          <IconButton
                            color="secondary"
                            onClick={() => handleRemoveVendorRow(selection.id)}
                            sx={{ ml: 1 }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>

                {/* Other Vendors Text Field */}
                <TextField
                  label="Other Vendors"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={otherVendors}
                  onChange={(e) => setOtherVendors(e.target.value)}
                  sx={{ marginTop: theme.spacing(2) }}
                  placeholder="If you don't see a vendor you use, please let us know here."
                />

                {/* Display Error if any */}
                {vendorSubmitError && (
                  <Alert severity="error" sx={{ marginTop: theme.spacing(2) }}>
                    {vendorSubmitError}
                  </Alert>
                )}

                {/* Submit Button */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: theme.spacing(2),
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitVendorRequest}
                    disabled={isVendorSubmitting}
                  >
                    {isVendorSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </Box>
              </Box>
            </Box>
          </SettingsCard>
        </Container>
      </Box>

      {/* Snackbar for Success Message */}
      <Snackbar
        open={vendorSubmitSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="We have received your request and will get back to you shortly."
      />
    </>
  );
}

export default Settings;
