// src/components/PrivacyPolicy.js

import React from 'react';
import { Box, Container, Typography, CssBaseline, Link } from '@mui/material';
import { styled } from '@mui/system';
import { Helmet } from 'react-helmet';
import Logo from './assets/small-logo.avif'; // Ensure this path is correct
import './styles/fonts.css'; // Ensure this path is correct
import Footer from './Footer';

// Styled Components
const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: '#FFFFFF',
}));

const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  textAlign: 'center',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const LogoImage = styled('img')(({ theme }) => ({
  width: 60,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(8),
}));

const Content = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  flex: 1,
}));



function PrivacyPolicy() {
  return (
    <Root>
      {/* Helmet for Setting Page Title and Meta Tags */}
      <Helmet>
        <title>FareFood | Privacy Policy</title>
        <meta
          name="description"
          content="Read FareFood's Privacy Policy to understand how we collect, use, safeguard, and disclose your information when you use our services."
        />
        {/* Open Graph Meta Tags for Social Sharing */}
        <meta property="og:title" content="FareFood | Privacy Policy" />
        <meta
          property="og:description"
          content="Understand how FareFood collects, uses, and protects your personal information through our comprehensive Privacy Policy."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.fare.food/privacy-policy" />
        <meta property="og:image" content="https://www.fare.food/assets/privacy-og-image.jpg" />
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="FareFood | Privacy Policy" />
        <meta
          name="twitter:description"
          content="Explore FareFood's Privacy Policy to learn about our data collection, usage, and protection practices."
        />
        <meta name="twitter:image" content="https://www.fare.food/assets/privacy-twitter-image.jpg" />
      </Helmet>

      <CssBaseline />

      {/* Header */}
      <Header>
        <LogoImage src={Logo} alt="FareFood Logo" />
        <Typography variant="h4" component="h1" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 700 }}>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" sx={{ fontFamily: 'Poppins, sans-serif', color: '#555555' }}>
          Effective date: 07/29/2024
        </Typography>
      </Header>

      {/* Main Content */}
      <Content maxWidth="md">
        {/* Insert the Privacy Policy content here */}
        {/* Introduction */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  1. Introduction
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Welcome to FareFood, Inc. FareFood, Inc. (“us”, “we”, or “our”) operates https://www.fare.food and the FareFood mobile application (hereinafter referred to as “Service”).
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Our Privacy Policy governs your visit to https://www.fare.food and the FareFood mobile application, and explains how we collect, safeguard, and disclose information that results from your use of our Service.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We use your data to provide and improve our Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Our Terms and Conditions (“Terms”) govern all use of our Service and, together with the Privacy Policy, constitute your agreement with us (“agreement”).
</Typography>

{/* Definitions */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  2. Definitions
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  <strong>SERVICE</strong> means the https://www.fare.food website and FareFood mobile application operated by FareFood, Inc.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  <strong>PERSONAL DATA</strong> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  <strong>USAGE DATA</strong> is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  <strong>COOKIES</strong> are small files stored on your device (computer or mobile device).
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  <strong>DATA CONTROLLER</strong> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  <strong>DATA SUBJECT</strong> is any living individual who is the subject of Personal Data.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  <strong>THE USER</strong> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
</Typography>

{/* Information Collection */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  3. Information Collection and Use
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We collect several different types of information for various purposes to provide and improve our Service to you.
</Typography>

{/* Personal Data */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  3.1 Personal Data
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:
</Typography>
<ul>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Email address
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      First name and last name
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Phone number
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Address, State, Province, ZIP/Postal code, City
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Cookies and Usage Data
    </Typography>
  </li>
</ul>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing us at <Link href="mailto:support@fare.food">support@fare.food</Link>.
</Typography>

{/* Usage Data */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  3.2 Usage Data
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a mobile device (“Usage Data”).
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  This Usage Data may include information such as your computer's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  When you access Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
</Typography>

{/* Location Data */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  3.3 Location Data
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  You can enable or disable location services when you use our Service at any time by way of your device settings.
</Typography>

{/* Tracking Cookies Data */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  3.4 Tracking Cookies Data
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
</Typography>

{/* Examples of Cookies */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  3.4.1 Examples of Cookies We Use
</Typography>
<ul>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Session Cookies: These cookies are used to manage user sessions and keep track of user interactions within the Service.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Preference Cookies: These cookies remember user preferences and settings to enhance user experience.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Security Cookies: These cookies help in maintaining the security of the Service by detecting fraudulent activities.
    </Typography>
  </li>
</ul>

{/* Other Data */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  3.5 Other Data
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  While using our Service, we may also collect the following information: sex, age, date of birth, place of birth, passport details, citizenship, registration at place of residence and actual address, telephone number (work, mobile), details of documents on education, qualification, professional training, employment agreements, non-disclosure agreements, information on bonuses and compensation, information on marital status, family members, social security (or other taxpayer identification) number, office location and other data.
</Typography>

{/* Data Retention */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  4. Data Retention
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
</Typography>

{/* Data Transfer */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  5. Data Transfer
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process it there.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  FareFood, Inc. will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
</Typography>

{/* Data Disclosure */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  6. Data Disclosure
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We may disclose personal information that we collect, or you provide:
</Typography>

{/* Circumstances of Disclosure */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  6.1 Circumstances of Disclosure
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred.
</Typography>

{/* Data Security */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  7. Data Security
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
</Typography>

{/* User Rights */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  8. Your Rights
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at <Link href="mailto:support@fare.food">support@fare.food</Link>.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  In certain circumstances, you have the following data protection rights:
</Typography>
<ul>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Right to access – You have the right to request copies of your personal data.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Right to rectification – You have the right to request that we correct any information you believe is inaccurate.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Right to erasure – You have the right to request that we erase your personal data, under certain conditions.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      Right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.
    </Typography>
  </li>
</ul>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not be able to provide Service without some necessary data.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
</Typography>

{/* California Privacy Rights */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  9. California Privacy Rights
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  If you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:
</Typography>
<ul>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      By email: <Link href="mailto:support@fare.food">support@fare.food</Link>
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      By visiting this page on our website: <Link href="https://www.fare.food/contactus" target="_blank" rel="noopener noreferrer">https://www.fare.food/contactus</Link>
    </Typography>
  </li>
</ul>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Please note, if you ask us to delete or stop selling your data, it may impact your experience with us, and you may not be able to participate in certain programs or membership services which require the usage of your personal information to function. But in no circumstances, we will discriminate against you for exercising your rights.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Your data protection rights, described above, are covered by the CCPA, short for the California Consumer Privacy Act. To find out more, visit the official California Legislative Information website. The CCPA took effect on 01/01/2020.
</Typography>

{/* Third-Party Service Providers */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  10. Third-Party Service Providers
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We may employ third-party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
</Typography>

{/* Examples of Service Providers */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  10.1 Examples of Service Providers
</Typography>
<ul>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>Google Analytics:</strong> A web analytics service offered by Google that tracks and reports website traffic. For more information, visit <Link href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">Google Privacy Terms</Link>.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>Firebase:</strong> An analytics service provided by Google Inc. For more information, visit <Link href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noopener noreferrer">Google Privacy Terms</Link>.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>Mixpanel:</strong> An analytics service provided by Mixpanel Inc. To opt-out, visit <Link href="https://mixpanel.com/optout/" target="_blank" rel="noopener noreferrer">Mixpanel Opt-Out</Link>.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>GitHub:</strong> A development platform to host and review code. For more information, visit <Link href="https://help.github.com/en/articles/github-privacy-statement" target="_blank" rel="noopener noreferrer">GitHub Privacy Policy</Link>.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>Stripe:</strong> A payment processing service. For more information, visit <Link href="https://stripe.com/us/privacy" target="_blank" rel="noopener noreferrer">Stripe Privacy Policy</Link>.
    </Typography>
  </li>
</ul>

{/* Payment Processing */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  11. Payment Processing
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We may provide paid products and/or services within our Service. In that case, we use third-party services for payment processing (e.g., payment processors).
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
</Typography>

{/* List of Payment Processors */}
<Typography variant="h6" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 500 }}>
  11.1 Payment Processors We Work With
</Typography>
<ul>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>PayPal or Braintree:</strong> Their Privacy Policy can be viewed at <Link href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank" rel="noopener noreferrer">PayPal Privacy Policy</Link>.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>Apple Store In-App Payments:</strong> Their Privacy Policy can be viewed at <Link href="https://www.apple.com/legal/privacy/en-ww/" target="_blank" rel="noopener noreferrer">Apple Privacy Policy</Link> and <Link href="https://support.apple.com/en-us/HT203027" target="_blank" rel="noopener noreferrer">Apple Support</Link>.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>Google Play In-App Payments:</strong> Their Privacy Policy can be viewed at <Link href="https://policies.google.com/privacy?hl=en&gl=us" target="_blank" rel="noopener noreferrer">Google Privacy Policy</Link> and <Link href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en" target="_blank" rel="noopener noreferrer">Google Payments Privacy Notice</Link>.
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      <strong>Stripe:</strong> Their Privacy Policy can be viewed at <Link href="https://stripe.com/us/privacy" target="_blank" rel="noopener noreferrer">Stripe Privacy Policy</Link>.
    </Typography>
  </li>
</ul>

{/* Links to Other Sites */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  12. Links to Other Sites
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Our Service may contain links to other sites that are not operated by us. If you click a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
</Typography>

{/* Children's Privacy */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  13. Children's Privacy
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  Our Services are not intended for use by children under the age of 18 (“Child” or “Children”).
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.
</Typography>

{/* Changes to Privacy Policy */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  14. Changes to This Privacy Policy
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “Effective date” at the top of this Privacy Policy.
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
</Typography>

{/* Contact Us */}
<Typography variant="h5" gutterBottom sx={{ fontFamily: 'Poppins, sans-serif', fontWeight: 600 }}>
  15. Contact Us
</Typography>
<Typography variant="body1" paragraph sx={{ fontFamily: 'Poppins, sans-serif' }}>
  If you have any questions about this Privacy Policy, please contact us:
</Typography>
<ul>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      By email: <Link href="mailto:support@fare.food">support@fare.food</Link>
    </Typography>
  </li>
  <li>
    <Typography variant="body1" sx={{ fontFamily: 'Poppins, sans-serif' }}>
      By visiting this page on our website: <Link href="https://www.fare.food/contactus" target="_blank" rel="noopener noreferrer">https://www.fare.food/contactus</Link>
    </Typography>
  </li>
</ul>

        
      </Content>

      {/* Footer */}
      <Footer />
    </Root>
  );
}

export default PrivacyPolicy;
